import { useState } from "react";
import { Button, Input, Spinner, Card } from "@salesforce/design-system-react";
import { useNavigate, Link } from "react-router-dom";
import { Auth } from "aws-amplify";

import "../Pages.css";
import { GENERAL_ERROR } from "../../constants/ToastMessages";
import RegionSelection from "../../components/region-selection";
import useToastContext from "../../context/useToastContext";
import { applyInstance } from "../../services/api";
import useAuthContext from "../../context/useAuthContext";

const ForgotPassword = () => {
    const { addToast } = useToastContext();
    const [isLoading, setIsLoading] = useState(false);
    const { login, setLogin } = useAuthContext();

    const navigate = useNavigate();

    const handleInputChange = (e, item) => {
        setLogin((prev) => ({ ...prev, [item]: e.target.value }));
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();

            if (!login || !login.username || !login.instance) {
                addToast("warning", "Missing Details", "Please fill out all fields");
                return;
            }

            setIsLoading(true);
            applyInstance(login.instance);
            const results = await Auth.forgotPassword(login?.username);
            if (!results) {
                addToast("error", "Error", "Unable to reset password at this time. Please try again later.");
            }
            console.log(results); // TODO: figure out what error conditions there are
            addToast("success", "Password Reset", "Please check your email for further instructions.");
            navigate("/login?mode=resetPassword");
        } catch (err) {
            console.error(err);
            addToast("error", "Error", err.message || GENERAL_ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-signup-container">
            <form onSubmit={handleSubmit}>
                <Card heading={<p className="login-signup-heading">Point Sigma</p>}>
                    <p className="form-title">Enter your username below and we will send a message to reset your password</p>
                    <RegionSelection login={login} setLogin={setLogin} />
                    <div className="user-inputs">
                        <Input type="text" label="Username" value={login?.username || ""} onChange={(e) => handleInputChange(e, "username")} autoComplete="username" />
                    </div>
                    <div className="login-signup-button">
                        <Button
                            type="submit"
                            label={<>Reset my password{isLoading ? <Spinner size="small" /> : null}</>}
                            variant="brand"
                            disabled={isLoading || !login?.username || !login?.instance}
                            className="login-submit-button"
                        />
                    </div>
                    <nav className="login-signup-link">
                        Back to <Link to="/login">Sign in</Link>
                    </nav>
                </Card>
            </form>
        </div>
    );
};

export default ForgotPassword;
