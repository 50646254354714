// TODO: rename this to FolderSelect, as it doesn't necessarily have to do with patterns, and certainly doesn't save any patterns
import { useState, useEffect, useRef } from "react";
import { Button, Card, Input } from "@salesforce/design-system-react";
import Spinner from "@salesforce/design-system-react/components/spinner";

import { toastErrorMessage } from "../../../helpers/index.js";
import Record from "../../../helpers/recordLayer";
import PsNavigationTree from "../../ps-navigation-tree/PsNavigationTree";
import useToastContext from "../../../context/useToastContext";

const PatternSave = ({ folderId, onClose, onSave, childToParent, parentToChildEvent }) => {
    const [loading, setLoading] = useState(false);
    const [folderName, setFolderName] = useState("");
    const [folderDescription, setFolderDescription] = useState("");
    const [selectedItem, setSelectedItem] = useState("folders");
    const [selectedFolder, setSelectedFolder] = useState(null);

    // const cmpWorking = useRef({});
    const cmpNavigationTree = useRef(null);
    const folderListBox = useRef(null);
    const folderListTitle = useRef(null);

    // global toast
    const { addToast } = useToastContext();

    //this will run on the first page load, because the queryFilter is intially empty and then Search.js processes the searchParams and updates the queryFilter
    useEffect(() => {
        let defaultSelected = Record.nameFromDetails("folders", "root", "folder");
        let selected = folderId ? Record.nameFromDetails("folders", "folder", folderId) : defaultSelected;
        setSelectedItem(selected);
    }, [folderId]);

    useEffect(() => {
        if (!parentToChildEvent) {
            return;
        }
        handleEvent(parentToChildEvent);
    }, [parentToChildEvent]);

    const createSaveFolder = () => {
        if (!folderName) {
            addToast("error", "Name cannot be empty", "Name is created when creating a new folder.");
            return;
        }

        if (folderName.toLowerCase() === "liked") {
            addToast("warning", "Name cannot be 'Liked'", "You cannot create a folder named 'Liked'. Please choose a different name for the folder.");
            return;
        }

        let parentFolder = selectedFolder;
        let parentFolderId = parentFolder?.id;
        let rootFolderId = parentFolder?.rootFolder?.id || parentFolder?.rootFolderId || parentFolderId;
        var record = { name: folderName, description: folderDescription, rootFolderId, parentFolderId };
        // var parentId = parentFolderId || "folder"; // when putting folders in nested structure
        var parentId = "folder";

        var onSuccess = function (response) {
            let recordId = response[0]?.id;
            record.id = recordId;
            setFolderName("");
            setFolderDescription("");
            dispatchEvent({ type: "record", action: "create", parentId, module: "relate", obj: "folder", id: recordId, record });
            // NB; the selected Folder will be updated as result of the "change" event sent by the Navigation Tree
            let selected = Record.nameFromDetails("folders", "folder", recordId);
            setSelectedItem(selected);
            addToast("success", "Folder Saved", "Folder successfully saved");
            setLoading(false);
        };

        var onError = function (response) {
            addToast("error", "Error", toastErrorMessage(response));
            setLoading(false);
        };

        setLoading(true);
        Record.createRecord("relate", "folder", JSON.stringify([record]), onSuccess, onError);
    };

    const handleNavigationEvent = (event) => {
        // scroll only
        var source = event.source;
        if (["change", "closeSearch"].includes(source)) {
            const scroll = event.scroll;
            const scroller = folderListBox.current;
            const folderListTitleDiv = folderListTitle.current; // document.getElementById("searchdiv");
            // update scroll position after rendering, so that rendered sizes are available
            if (folderListTitleDiv && scroller && scroll != null) {
                var timer = setTimeout(() => {
                    var top = folderListTitleDiv.offsetTop + folderListTitleDiv.offsetHeight;
                    scroller.scrollTop = scroll * (scroller.scrollHeight - top);
                }, 0);
            }
        }

        // set currently selected Folder record
        if (source === "change") {
            setSelectedFolder(event.obj === "folder" ? event.record : null);
        }
    };

    const dispatchEvent = (event) => {
        childToParent(event);
    };

    const bubbleEvent = (event) => {
        let stopPropagation = false;

        if (event.type === "navigation") {
            stopPropagation = true;
            handleNavigationEvent(event);
        }

        if (!stopPropagation) {
            childToParent(event);
        }
    };

    const handleEvent = (event) => {
        if (event.type === "reload") {
            //handleReload();
        }
    };

    const handleSave = () => {
        onSave(selectedFolder);
        onClose();
    };

    return (
        <div>
            <section role="dialog" tabIndex="-1" aria-modal aria-labelledby="modal-heading-01" className="slds-modal slds-fade-in-open">
                <div className="slds-modal__container">
                    <button className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" onClick={() => onClose()}>
                        <svg className="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span className="slds-assistive-text">Cancel and close</span>
                    </button>
                    <div className="slds-modal__header">
                        <h1 id="modal-heading-01" className="slds-modal__title slds-hyphenate">
                            Select a Folder
                        </h1>
                    </div>
                    <div className="slds-modal__content slds-p-around_medium" id="modal-content-id-1">
                        <Card heading="">
                            <div style={{ maxHeight: "540px" }}>
                                <div className="slds-box slds-box_x-small slds-m-top_small">
                                    <div style={{ padding: "6px" }} ref={folderListTitle}>
                                        <div className="slds-text-title">
                                            <b>Select an existing folder or create a new folder.</b>
                                        </div>
                                    </div>

                                    <div style={{ padding: "6px" }}>
                                        <div className="slds-box slds-box_x-small">
                                            <div ref={folderListBox} className="slds-scrollable slds-is-relative" style={{ height: "250px" }}>
                                                {loading && <Spinner assistiveText={{ label: "Loading" }} />}
                                                <PsNavigationTree
                                                    multiSelect={false}
                                                    sections={["folders"]}
                                                    selected={selectedItem}
                                                    setLoading={setLoading}
                                                    ref={cmpNavigationTree}
                                                    childToParent={bubbleEvent}
                                                    parentToChildEvent={parentToChildEvent}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ padding: "0 6px 6px 6px" }}>
                                        <Input type="text" label="Folder Name" autocomplete="off" value={folderName} onChange={(e) => setFolderName(e.target.value)} />

                                        <div style={{ paddingTop: "6px" }}>
                                            <Input type="text" label="Folder Description" autocomplete="off" value={folderDescription} onChange={(e) => setFolderDescription(e.target.value)} />
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "13px" }}>
                                            <Button
                                                label={
                                                    <div>
                                                        Create Folder
                                                        {loading && <Spinner size="x-small" variant="brand" />}
                                                    </div>
                                                }
                                                title="Create Folder"
                                                onClick={() => createSaveFolder()}
                                                variant="brand"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="slds-modal__footer">
                        <button className="slds-button slds-button_neutral" aria-label="Cancel and close" onClick={() => onClose()}>
                            Cancel
                        </button>
                        <button className="slds-button slds-button_brand" onClick={() => handleSave()} disabled={!selectedFolder?.id}>
                            Save
                        </button>
                    </div>
                </div>
            </section>
            <div className="slds-backdrop slds-backdrop_open" role="presentation"></div>
        </div>
    );
};

export default PatternSave;
