import { useState } from "react";
import { Button } from "@salesforce/design-system-react";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import Record from "../../helpers/recordLayer";
import { toastErrorMessage } from "../../helpers/index.js";
import RecordConstants from "../../constants/RecordConstants";
import useToastContext from "../../context/useToastContext";
import { RECORD_COLUMNS } from "./constants";

const PsObjectList = ({ parentId, queryFilter, childToParent, parentToChildEvent }) => {
    const [mode, setMode] = useState("init");
    const [view, setView] = useState("table");
    const [orderBy, setOrderBy] = useState("name");
    const [orderDirection, setOrderDirection] = useState("asc");
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const { addToast } = useToastContext();

    const parseResponse = (response) => {
        return response.map(({ id, name, status, totalRecords, sourceExists, sourceIdentifier, lastRunOn, restrictions }) => ({
            id,
            name,
            status,
            totalRecords,
            sourceExists,
            sourceIdentifier,
            lastRunOn,
            restrictions: parseRestrictionLabels(restrictions),
        }));
    };

    // comma-separated list of Restriction Type names for active retrictions
    const parseRestrictionLabels = (restrictions) => {
        var restrictionType;
        restrictions = restrictions || [];
        return restrictions
            .reduce((o, i) => {
                if (i.status !== RecordConstants.RESTRICTION_STATUS.INACTIVE.value) {
                    restrictionType = i.restrictionType || {};
                    o.push(restrictionType.name);
                }
                return o;
            }, [])
            .join(", ");
    };

    const actionSyncConnector = () => {
        let previousMode = mode;
        setLoading(true);

        const onSucces = function (response) {
            addToast("info", "Connector Started", "Loading data inventory");
            setLoading(false);
            setMode(previousMode);
        };

        const onError = function (response) {
            addToast("error", "Error", toastErrorMessage(response));
            setLoading(false);
            setMode(previousMode);
        };

        Record.doAction("pump", "connector", "sync", { id: parentId }, onSucces, onError);
    };

    const emptyCallToAction = () => {
        const label = "Load Inventory";
        const title = "Load the data inventory from the source file or system";
        return <Button label={label} title={title} onClick={actionSyncConnector} disabled={loading} variant="brand" />;
    };

    return (
        <PsRecordGrid
            module="pump"
            object="object"
            queryFilter={queryFilter}
            showTitle
            emptyLine="Load the data inventory from the source file or system"
            emptyCallToAction={emptyCallToAction}
            recordLabel="Object"
            recordLabelPlural="Objects"
            parseResponse={parseResponse}
            parentId={parentId}
            mode={mode}
            onModeChange={(value) => setMode(value)}
            view={view}
            onViewChange={setView}
            orderBy={orderBy}
            onOrderByChange={(value) => setOrderBy(value)}
            orderDirection={orderDirection}
            onOrderDirectionChange={(value) => setOrderDirection(value)}
            loading={loading}
            onLoadingChange={(value) => setLoading(value)}
            showLoadMore
            hasMore={hasMore}
            onHasMoreChange={setHasMore}
            recordColumns={RECORD_COLUMNS}
            maxRecords={100}
            childToParent={childToParent}
            parentToChildEvent={parentToChildEvent}
        />
    );
};

export default PsObjectList;
