import { useState } from "react";

import Record from "../../helpers/recordLayer";
import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import { RECORD_COLUMNS } from "./constants";
import PsPatternChart from "../ps-pattern-chart/PsPatternChart.js";
import "./PsSearchGrid.css";

const PsSearchGrid = ({ parentId, queryFilter, childToParent, parentToChildEvent, emptyLine, loading, onLoadingChange }) => {
    const [mode, setMode] = useState("init");
    const [view, setView] = useState("grid");
    const [orderBy, setOrderBy] = useState("relevance");
    const [orderDirection, setOrderDirection] = useState("desc");
    const [hasMore, setHasMore] = useState(false);
    const [showInit, setShowInit] = useState(true);
    //const [loading, setLoading] = useState(false);

    const updateMode = (value) => {
        // update setShowInit only /after/ mode is set to prevent 'no results' from showing before results are loaded
        if (["empty"].includes(value)) {
            setShowInit(!queryFilter?.query && !queryFilter?.constraints);
        }
        setMode(value);
    };

    const handleRecordRowAction = (action, row) => {
        switch (action) {
            case "details":
                const { id, ...pattern } = row; // id is set by recordTable
                childToParent({ type: "dataCompEvent", action: "viewDetails", pattern });
                break;
            case "viewSource":
                childToParent({ type: "navigation", parentId: "root", module: "core", obj: "source", id: row.container?.source?.id, source: "grid" });
                break;
            case "viewContainer":
                childToParent({ type: "navigation", parentId: row.container?.source.id, module: "store", obj: "container", id: row.container?.id, source: "grid" });
                break;
            default:
                break;
        }
    };

    const parseResponse = (response) => {
        return response.map(
            ({
                id,
                compositionId,
                name,
                type,
                description,
                treeHash,
                relevance,
                relevanceIntrinsic,
                createdOn,
                lastRunOn,
                key,
                container,
                overridden,
                parameters,
                options,
                calculated,
                inputs,
                version,
            }) => ({
                id,
                compositionId,
                name,
                namePlain: Record.removeMarkup(name),
                nameMarkup: Record.markupToHtml(name),
                type,
                treeHash,
                relevance,
                relevanceIntrinsic,
                createdOn,
                lastRunOn,
                container,
                key, // TODO: remove when no longer needed to save a Pattern
                containerName: container?.name,
                sourceName: container?.source?.name,
                title: container?.source?.name + ": " + container?.name,
                overridden,
                description,
                descriptionPlain: Record.removeMarkup(description),
                descriptionMarkup: Record.markupToHtml(description),
                parameters,
                options,
                calculated,
                inputs,
                version,
            })
        );
    };

    return (
        <PsRecordGrid
            module="relate"
            object="search"
            queryFilter={queryFilter}
            layout={[]}
            gridComponent={PsPatternChart}
            gridItemClass="grid-item-search"
            emptyLine={showInit ? emptyLine : "No results found for the specified criteria"}
            viewOptions={[
                { label: "Table", value: "table" },
                { label: "Grid", value: "grid" },
            ]}
            changeView
            recordLabel="Pattern"
            recordLabelPlural="Results"
            parseResponse={parseResponse}
            parentId={parentId}
            mode={mode}
            onModeChange={(value) => updateMode(value)}
            view={view}
            onViewChange={setView}
            orderBy={orderBy}
            onOrderByChange={(value) => setOrderBy(value)}
            orderDirection={orderDirection}
            onOrderDirectionChange={(value) => setOrderDirection(value)}
            loading={loading}
            onLoadingChange={(value) => onLoadingChange(value)}
            hasMore={hasMore}
            onHasMoreChange={setHasMore}
            recordColumns={RECORD_COLUMNS}
            maxRecords={12}
            onRecordRowAction={handleRecordRowAction}
            childToParent={childToParent}
            parentToChildEvent={parentToChildEvent}
            usePutAPI
            setEmpty={() => !queryFilter?.query && !queryFilter?.constraints}
        />
    );
};

export default PsSearchGrid;
