import { useState } from "react";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import Record from "../../helpers/recordLayer";
import { RECORD_COLUMNS } from "./constants";
import PsConnectorTile from "../ps-connector-tile/PsConnectorTile.js";
import "./PsConnectorList.css";

const PsConnectorList = ({ parentId, queryFilter, childToParent, parentToChildEvent, emptyLine, tagLine, view: initView }) => {
    const [mode, setMode] = useState("init");
    const [view, setView] = useState(initView || "table");
    const [orderBy, setOrderBy] = useState("name");
    const [orderDirection, setOrderDirection] = useState("asc");
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleRecordRowAction = (action, row) => {
        switch (action) {
            case "details":
                childToParent({ type: "navigation", parentId, module: "pump", obj: "connector", id: row.id, source: "grid" });
                break;
            case "viewSource":
                childToParent({ type: "navigation", parentId: "sources", module: "pump", connector: "source", id: row.source.id, source: "grid" });
                break;
            default:
        }
    };

    const parseResponse = (response) => {
        var results = response.map(({ id, name, status, schedule, source, nextScheduledRun, runEnd, runStatus, runMessage, connectorType }) => ({
            id,
            name,
            status,
            schedule: Record.cronToHuman(schedule),
            source,
            sourceName: source.name,
            nextScheduledRun,
            runEnd,
            runStatus,
            runMessage,
            connectorType,
            connectorTypeName: connectorType.name,
        }));
        return results;
    };

    return (
        <PsRecordGrid
            module="pump"
            object="connector"
            queryFilter={queryFilter}
            showTitle
            tagLine={tagLine || "Connectors load your data into the Point Sigma analytics engine."}
            emptyLine={emptyLine || "Connect this Source using a new Connector"}
            recordLabel="Connector"
            recordLabelPlural="Connectors"
            parseResponse={parseResponse}
            parentId={parentId}
            viewOptions={[
                { label: "Table", value: "table" },
                { label: "Grid", value: "grid" },
            ]}
            changeView
            gridComponent={PsConnectorTile}
            gridItemClass="grid-item-connector"
            layout={[]}
            mode={mode}
            onModeChange={(value) => setMode(value)}
            view={view}
            onViewChange={setView}
            orderBy={orderBy}
            onOrderByChange={(value) => setOrderBy(value)}
            orderDirection={orderDirection}
            onOrderDirectionChange={(value) => setOrderDirection(value)}
            loading={loading}
            onLoadingChange={(value) => setLoading(value)}
            showLoadMore
            hasMore={hasMore}
            onHasMoreChange={setHasMore}
            recordColumns={RECORD_COLUMNS}
            maxRecords={100}
            showCreate
            onRecordRowAction={handleRecordRowAction}
            childToParent={childToParent}
            parentToChildEvent={parentToChildEvent}
        />
    );
};

export default PsConnectorList;
