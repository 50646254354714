import { useEffect, useRef, useState } from "react";
import { Input } from "@salesforce/design-system-react";

import Record from "../../../helpers/recordLayer";
import { coloredText, revertOverride } from "../../../utils";
import Field from "../../../ui/wrappers/Field";
import { checkRequiredField } from "../../../utils/index2";

const EditablePatternName = ({ pattern, onPatternChange }) => {
    const [mode, setMode] = useState("view");
    const [record, setRecord] = useState({});
    const inputRef = useRef({});

    useEffect(() => {
        setRecord({ ...pattern, originalName: pattern?.name, name: pattern?.namePlain });
    }, [pattern]);

    const handleCancel = (reset) => {
        if (reset) {
            setRecord({ ...pattern, originalName: pattern?.name, name: pattern?.namePlain });
        }

        setMode("view");
    };

    const handleSave = () => {
        const orgNamePlain = Record.removeMarkup(record?.originalName);
        const newNamePlain = Record.removeMarkup(record?.name);
        const newName = newNamePlain === orgNamePlain ? record?.originalName : record?.name;
        onPatternChange({
            ...pattern,
            name: newName,
            namePlain: Record.removeMarkup(newName),
            nameMarkup: Record.markupToHtml(newName),
            original: record?.original,
            hasOverride: record?.hasOverride,
            overridden: record?.overridden,
        });

        setMode("view");
    };

    const handleRevert = () => {
        // only revert record override settings; the reverted record.name may still have formatting in it
        let reverted = revertOverride(record, "name");
        setRecord({
            ...record,
            originalName: reverted.name,
            name: Record.removeMarkup(reverted.name),
            original: reverted?.original,
            hasOverride: reverted?.hasOverride,
            overridden: reverted?.overridden,
        });
    };

    return pattern?.inputs ? (
        <Field
            setRecord={setRecord}
            mode={mode}
            value={
                <div className={`slds-truncate ${pattern?.name ? "" : "slds-text-color_weak"} slds-m-right_xx-small`} onClick={() => setMode("edit")} title={pattern?.namePlain}>
                    {pattern?.nameMarkup ? coloredText(pattern?.nameMarkup) : "Enter a name..."}
                </div>
            }
            fieldName="name"
            isEditable
            canOverride
            hasOverride={record?.hasOverride?.name}
            checkValidity={checkRequiredField}
            popover={{ isVisible: true, isInline: true }}
            onEdit={() => setMode("edit")}
            onSave={handleSave}
            onRevert={handleRevert}
            onCancel={handleCancel}
            saveOnEnter
            body={
                <Input
                    value={record?.name || ""}
                    placeholder="Enter a name"
                    inputRef={(input) => {
                        if (input) {
                            inputRef.current = input;
                            input.focus();
                        }
                    }}
                    maxLength="255"
                />
            }
        />
    ) : null;
};

export default EditablePatternName;
