import { useState } from "react";
import { Card } from "@salesforce/design-system-react";
import { useNavigate } from "react-router-dom";

import "./../Pages.css";
import SignupForm from "./components/SignupForm";
import ConfirmationForm from "./components/ConfirmationForm";
import useToastContext from "../../context/useToastContext";
import useAuthContext from "../../context/useAuthContext";

const Signup = () => {
    const { handleLogin } = useAuthContext();
    const navigate = useNavigate();
    const { addToast } = useToastContext();
    const [mode, setMode] = useState("signup");

    const completeLogin = (userId, instance) => {
        if (!userId) {
            addToast("error", "Error", "Unable to log in, please check your login details and try again.");
            return;
        }
        handleLogin(userId, instance);
        navigate("/", { replace: true });
    };

    const completeSignup = () => {
        addToast("success", "Signup Started", "Please check your email for your verification code.");
        setMode("confirm");
    };

    return (
        <div className="login-signup-container">
            <Card heading={<p className="login-signup-heading">Point Sigma</p>}>
                {mode === "signup" && <SignupForm completeSignup={completeSignup} />}
                {mode === "confirm" && <ConfirmationForm completeLogin={completeLogin} />}
            </Card>
        </div>
    );
};

export default Signup;
