import { Button, Card, Spinner } from "@salesforce/design-system-react";

import IllustrationDesert from "../../ui/IllustrationDesert";
import Record from "../../helpers/recordLayer";
import { toastErrorMessage } from "../../utils";
import HeaderActions from "./components/HeaderActions";
//import useToastContext from "../../context/useToastContext";

const PsRecord = {
    //addToast: useToastContext.addToast, // there is no way to use addToast here

    handleCancel: function (cmp) {
        cmp.handleCancel(cmp);
        cmp.set("missingRequiredFields", []);
    },

    handleSubmit: function (cmp) {
        cmp.handleSubmit(cmp);
    },

    parseResponse: function (cmp, response) {
        try {
            let data = cmp.parseResponse ? cmp.parseResponse(response) : response;

            // Replace --None-- values with empty strings
            data = Object.fromEntries(Object.entries(data[0]).map(([key, value]) => [key, value === "--None--" ? "" : value]));

            return [data];
        } catch (error) {
            console.error(error.stack);
        }
    },

    updateUI: function (cmp) {
        if (cmp.updateUI) {
            cmp.updateUI();
        }
    },

    postSubmit: function (cmp, record) {
        if (!cmp.postSubmit) {
            return;
        }
        cmp.postSubmit(record);
    },

    parseInputPlainText: function (cmp, record) {
        return cmp.parseInputPlainText ? cmp.parseInputPlainText(record) : record;
    },

    parseInputToEncrypt: function (cmp, record) {
        try {
            let data = cmp.parseInputToEncrypt ? cmp.parseInputToEncrypt(record) : null;

            if (!data) {
                return null;
            }

            // Replace --None-- values with empty strings
            data = Object.fromEntries(Object.entries(data).map(([key, value]) => [key, value === "--None--" ? "" : value]));

            return data;
        } catch (error) {
            console.error(error.stack);
        }
    },

    getDefaultRecord: function (cmp) {
        return cmp.getDefaultRecord();
    },

    setParent: function (cmp, record) {
        return cmp.setParent ? cmp.setParent(record) : cmp.get("parentId");
    },

    setMode: function (cmp, mode) {
        cmp.set("loading", false);

        // modal window opens in 'edit' rather then 'view' mode
        if (cmp.get("isModal") && mode === "view") {
            mode = "edit";
        }

        if (mode === "view") {
            Record.setElementAttribute("ViewModeButton", "disabled", false);
            this.setElementClass(cmp, "FormDiv", "slds-form-element_readonly", true);
        } else if (mode === "new") {
            this.setElementClass(cmp, "FormDiv", "slds-form-element_readonly", false);
        } else if (mode === "edit") {
            this.setElementClass(cmp, "FormDiv", "slds-form-element_readonly", false);
            Record.setElementAttribute("EditModeButton", "disabled", false);
        } else if (mode === "error") {
            Record.setElementAttribute("ViewModeButton", "disabled", false);
        }
        cmp.set("mode", mode);
    },

    setLoading: function (cmp) {
        cmp.set("loading", true);
    },

    getRecord: function (cmp, callback = null) {
        try {
            // load existing record, or open new record
            var recordValue = cmp.get("recordValue");
            var recordId = cmp.get("recordId");
            var recordModule = cmp.get("recordModule");
            var recordObject = cmp.get("recordObject");
            var self = this;
            if (recordValue) {
                var record = JSON.parse(JSON.stringify(recordValue)); // deep copy to prevent changes propagating to the original record
                cmp.set("record", record);
                this.updateUI(cmp);
                this.setMode(cmp, "view");
                if (callback) {
                    callback.call(self, cmp, true);
                }
            } else if (recordId) {
                this.setLoading(cmp);

                var onSuccess = function (response) {
                    var mapped = self.parseResponse(cmp, response);
                    mapped = self.parseOverrides(cmp, mapped);
                    var record = mapped[0];
                    var updated = cmp.get("record")?.id === record?.id;
                    var action = updated ? "update" : "read";
                    cmp.set("record", record);
                    self.updateUI(cmp);
                    self.setParent(cmp, record);
                    self.notifyChanged(cmp, action, recordId, record);
                    self.setMode(cmp, "view");
                    if (callback) {
                        callback.call(self, cmp, true);
                    }
                };

                var onError = function (response) {
                    //this.addToast("error", "Error", toastErrorMessage(response));
                    cmp.set("record", {});
                    self.setMode(cmp, "error");
                    if (callback) {
                        callback.call(self, cmp, false);
                    }
                };

                Record.getRecord(recordModule, recordObject, recordId, {}, "", "GET", onSuccess, onError);
            } else {
                cmp.set("record", this.getDefaultRecord(cmp));
                this.updateUI(cmp);
                this.setMode(cmp, "new");
                if (callback) {
                    callback.call(self, cmp, true);
                }
            }
        } catch (err) {
            console.error(err);
        }
    },

    cancelRecord: function (cmp) {
        var mode = cmp.get("mode");
        if (mode === "edit") {
            // if in 'edit' mode, reload the original values
            this.setLoading(cmp);
            this.getRecord(cmp); // this will set the status to 'loading', and then to 'view' (or 'error') when done
        } else {
            // otherwise navigate back to parent record
            // this.setMode(cmp, 'error'); // only needed if not navigating
            var parentNav = cmp.get("parentId") || "";

            this.notifyNavigation(cmp, null, null, null, parentNav);
        }
    },

    //This function returns whether the form is invalid, so true means invalid
    checkForm: function (cmp) {
        //Use component specific checkForm method if available
        if (cmp.hasOwnProperty("checkForm")) {
            return cmp.checkForm();
        }

        const record = cmp.get("record");
        const checkFields = cmp.get("checkFields");
        const customValidity = cmp.get("customValidity");
        let invalid = false;
        if (checkFields && checkFields.length > 0) {
            checkFields.forEach((checkField) => {
                if (invalid) return;
                if (checkField === "name") {
                    invalid = !record.name;
                } else if (customValidity) {
                    invalid = customValidity[checkField];
                }
            });
        }
        return invalid;
    },

    submitRecord: function (cmp, callback = null) {
        var previousMode = cmp.get("mode");
        var parentNav = cmp.get("parentId") || "";
        var recordModule = cmp.get("recordModule");
        var recordObject = cmp.get("recordObject");
        var recordId = cmp.get("recordId");
        var record = cmp.get("record") || {};
        var self = this;

        const customValidity = cmp.get("customValidity");
        if (PsRecord.checkForm(cmp) || (customValidity && Object.keys(customValidity).length > 0 && Object.values(customValidity).some((value) => value !== ""))) {
            // this.addToast("error", "Input Error", "Please update the invalid fields and try again.");
            alert("Error: Please update the invalid fields and try again.");
            return;
        }

        this.setLoading(cmp);

        var onError = function (response) {
            // this.addToast("error", "Error", toastErrorMessage(response));
            const message = response?.data?.message || null;
            const reference = response?.data?.reference;
            const suggestion = response?.data?.suggestion;

            alert("Error: " + (message || "") + "\n" + (reference || "") + "\n" + (suggestion || ""));
            self.setMode(cmp, previousMode);
            if (callback) {
                callback.call(self, cmp, false);
            }
        };

        var onSucces = function (response) {
            if (recordId) {
                // reload to make sure we have the latest data from the API; this also fires a record 'update' event that updates any values in the navigation tree
                self.getRecord(cmp, callback);
                // update the mode, and separately fire an 'update' event
                //self.setMode(cmp, 'view');
                //self.notifyChanged(cmp, 'update', recordId, record);
                //if (callback) { callback.call(self, cmp, true); }
            } else {
                // run callback before navigating to the newly created record page
                if (callback) {
                    callback.call(self, cmp, true);
                }

                // inform other components of the change, and then navigate to the newly created record
                recordId = response[0].id;
                record.id = recordId;
                self.notifyChanged(cmp, "create", recordId, record);
                self.notifyNavigation(cmp, parentNav, recordModule, recordObject, recordId);
                self.setMode(cmp, "view");
            }
        };

        record = this.updateOverridden(cmp, record);

        // extract fields that can be created / updated
        var dataPlainText = this.parseInputPlainText(cmp, record);
        var dataToEncrypt = this.parseInputToEncrypt(cmp, record);

        if (dataToEncrypt) {
            var encryptOnError = function (response) {
                console.log("encryptOnError");
            };

            var encryptOnSuccess = function (response) {
                var connector = dataPlainText;
                connector.credentials = response; // TODO: PsRecord should not have hard-coded references to credentials on connectors

                Record.submitRecord(recordModule, recordObject, dataPlainText, onSucces, onError);
                self.postSubmit(cmp, record);
            };

            // Create Record is not correct description, but it does the callout we need
            Record.createRecord("core", "crypto", JSON.stringify(dataToEncrypt), encryptOnSuccess, encryptOnError);
        } else {
            Record.submitRecord(recordModule, recordObject, dataPlainText, onSucces, onError);
            this.postSubmit(cmp, record);
        }
    },

    deleteRecord: function (cmp, callback = null) {
        try {
            var previousMode = cmp.get("mode");
            var parentNav = cmp.get("parentId") || "";
            var recordModule = cmp.get("recordModule");
            var recordObject = cmp.get("recordObject");
            var recordId = cmp.get("recordId");
            var self = this;

            if (recordId) {
                // IMPROVEMENT: check whether the record is allowed to delete
                this.setLoading(cmp);
                var onError = function (response) {
                    //this.addToast("error", "Error", toastErrorMessage(response));
                    self.setMode(cmp, previousMode);
                    if (callback) {
                        callback.call(self, cmp, false);
                    }
                };

                var onSuccess = function (response) {
                    //this.addToast("success", "Record Deleted", "Record deleted");
                    if (callback) {
                        callback.call(self, cmp, true);
                    }

                    self.notifyChanged(cmp, "delete", recordId, {});
                    self.notifyNavigation(cmp, null, null, null, parentNav);
                };
                Record.deleteRecord(recordModule, recordObject, recordId, onSuccess, onError);
            } else {
                //this.addToast("error", "Delete Error", "Record does not exist");
            }
        } catch (err) {
            console.error(err);
        }
    },

    notifyNavigation: function (cmp, parentId, module, object, id = null) {
        var navigationEvent = { type: "navigation", parentId, module, obj: object, id, source: "record" };
        cmp.dispatchEvent(navigationEvent);
    },

    // fire event for changed record(s)
    notifyChanged: function (cmp, action, id, record) {
        var module = cmp.get("recordModule");
        var object = cmp.get("recordObject");
        var parentNav = cmp.get("parentId") || "";
        cmp.dispatchEvent({ type: "record", action, parentId: parentNav, module, obj: object, id, record });
    },

    setElementClass: function (cmp, auraId, className, set) {
        var elements = document.querySelectorAll("[id=" + auraId + "]");
        elements.forEach((element) => {
            if (set) {
                //add class
                element.classList.add(className);
            } else {
                //remove class
                element.classList.remove(className);
            }
        });
    },

    getOverrideFields: function (cmp) {
        return cmp.getOverrideFields ? cmp.getOverrideFields() : null;
    },

    parseOverrides: function (cmp, response) {
        var fields = this.getOverrideFields(cmp);
        if (fields) {
            response.forEach((record) => {
                record.overridden = record.overridden || {};
                record.original = fields.reduce((result, item) => {
                    result[item] = item in record.overridden ? record.overridden[item] : record[item];
                    return result;
                }, {});
                record.hasOverride = fields.reduce((result, item) => {
                    result[item] = item in record.overridden;
                    return result;
                }, {});
            });
        }

        return response;
    },

    setOverride: function (cmp, field) {
        var record = cmp.get("record") || {};

        if (record.hasOverride) {
            record.hasOverride[field] = true;
        }

        cmp.set("record", record);
    },

    revertOverride: function (cmp, field) {
        var record = cmp.get("record") || {};
        if (record.original) {
            record[field] = record.original[field];
        }
        if (record.hasOverride) {
            record.hasOverride[field] = false;
        }
        cmp.set("record", record);
    },

    updateOverridden: function (cmp, record) {
        // TODO: access error when changing system-record fields
        var fields = PsRecord.getOverrideFields(cmp);

        if (fields && fields.length) {
            var hasOverride = record.hasOverride || {};
            var overridden = record.overridden || {};

            fields.forEach((field) => {
                if (hasOverride[field] === false) {
                    delete overridden[field];
                } else if (!record.id) {
                    // If theres no id it is a new record and should have overridden fields
                    overridden[field] = record[field];
                }
            });
            record.overridden = overridden;
        }

        return record;
    },

    // --- New functions ---

    handleToggleChange: function (cmp, field) {
        let record = cmp.get("record");
        record = { ...record, [field]: !record[field] };
        cmp.set("record", record);

        PsRecord.setOverride(cmp, field);
    },

    handleNameChange: function (cmp, value) {
        let record = cmp.get("record");
        record = { ...record, name: value };
        cmp.set("record", record);
        PsRecord.setOverride(cmp, "name");

        // empty the missing required fields array if the field is filled
        if (value) {
            cmp.set("missingRequiredFields", []);
        }
    },

    cancelDeleteRecord: function (cmp) {
        cmp.set("showDeleteConfirmDialog", false);
    },

    confirmDeleteRecord: function (cmp, callback) {
        cmp.set("showDeleteConfirmDialog", false);
        PsRecord.deleteRecord(cmp, callback); // NB: this navigates to parent record after successfull delete
    },

    render: (cmp, cmpState) => {
        const isModal = cmp.get("isModal");

        return (
            <Card
                id="recordGrid"
                classNameName="PsRecordGrid slds-scrollable"
                heading={<span className="card-main-title-lh32 slds-card__header-title">{cmpState.recordLabel}</span>}
                headerActions={
                    <HeaderActions
                        mode={cmpState.mode}
                        loading={cmpState.loading}
                        recordLabel={cmpState.recordLabel}
                        handleEdit={cmp.handleEdit}
                        handleDelete={cmp.handleDelete}
                        cardActions={cmp.cardActions}
                        cmp={cmp}
                    />
                }
                footer={
                    !isModal && ((cmpState.showDelete && cmpState.mode === "edit") || cmpState.mode === "edit" || cmpState.mode === "new") ? (
                        <div>
                            {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                                <>
                                    <Button
                                        label="Cancel"
                                        title={cmpState.mode === "new" ? "Cancel creating" : "Cancel editing"}
                                        onClick={() => PsRecord.handleCancel(cmp)}
                                        disabled={cmpState.loading}
                                    />
                                    <Button label="Save" title={"Save this " + cmpState.recordLabel} onClick={() => PsRecord.handleSubmit(cmp)} disabled={cmpState.loading} variant="brand" />
                                </>
                            )}
                        </div>
                    ) : null
                }
            >
                {/* <!-- error --> */}
                {cmpState.mode === "error" && (
                    <div className="slds-is-relative">
                        <div className="slds-p-around_medium slds-illustration slds-illustration_large" aria-hidden="true">
                            <IllustrationDesert />
                            <div className="slds-text-color_weak">
                                <h3 className="slds-text-heading_medium">{cmpState.recordLabel} not found</h3>
                            </div>
                        </div>
                        {cmpState.loading && <Spinner assistiveText={{ label: "Loading" }} />}
                    </div>
                )}
                {/* <!-- record form --> */}
                {cmpState.mode === "init" || cmpState.mode === "view" || cmpState.mode === "new" || cmpState.mode === "edit" ? (
                    <>
                        {cmp.body()}
                        {cmpState.loading && <Spinner assistiveText={{ label: "Loading" }} />}
                    </>
                ) : null}
            </Card>
        );
    },
};

export default PsRecord;
