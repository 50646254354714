import { useEffect, useState, useRef } from "react";
import reactDom from "react-dom";

import Card from "@salesforce/design-system-react/components/card";
import Tabs from "@salesforce/design-system-react/components/tabs";
import TabsPanel from "@salesforce/design-system-react/components/tabs/panel";
import Spinner from "@salesforce/design-system-react/components/spinner";

// import "./PsPatternDetailedView.css";
import Record from "../../helpers/recordLayer.js";
import PsPatternChart from "../ps-pattern-chart/PsPatternChart";
import PsPatternGrid from "../ps-pattern-grid/PsPatternGrid";
import PsCompositionGrid from "../ps-composition-grid/PsCompositionGrid";
import EditablePatternName from "../ps-pattern-chart/components/EditablePatternName.js";
import PlotOptions from "../ps-pattern-chart/components/PlotOptions.js";
import Filters from "../ps-pattern-chart/components/Filters.js";
import Details from "../ps-pattern-chart/components/Details.js";
import Description from "../ps-pattern-chart/components/Description.js";
import HeaderActionsIcons from "../ps-pattern-chart/components/HeaderActionsIcons";
import InputSlotModal from "../ps-pattern-chart/components/input-slot-modal/InputSlotModal.js";

function PsPatternDetailedView(props) {
    const [loading, setLoading] = useState(false);
    const [inputsModalOpen, setInputsModalOpen] = useState(false);
    const [selectedInput, setSelectedInput] = useState(null);
    const [newPattern, setNewPattern] = useState(true);

    const [pattern, setPatern] = useState(null); // the pattern that is sent to PatternChart for pattern
    const [original, setOriginal] = useState(null); // the pattern that is returned from the plotting API (if any); user can revert to this pattern after an error
    const [processed, setProcessed] = useState(null); // the latest edited version of the pattern; only sent for plotting when applyNow is enable, or Apply Changes is clicked
    const [options, setOptions] = useState(null);
    const [data, setData] = useState(null);
    const [showAlternativeAxes, setShowAlternativeAxes] = useState(false);
    const [queryFilter, setQueryFilter] = useState(null);
    const [selectedTab, setselectedTab] = useState("Explore More");

    const detailsRef = useRef(null);

    useEffect(() => {
        if (!props.parentToChildEvent) {
            return;
        }
        handleEvent(props.parentToChildEvent);
    }, [props.parentToChildEvent]);

    useEffect(() => {
        var scroller = detailsRef.current;
        if (scroller) {
            scroller.scrollTop = 0;
        }
        setNewPattern(true);
        setPatern(props.pattern);
    }, [props.pattern]);

    const handlePatternChanged = (pattern, plot) => {
        setProcessed(pattern);
        if (plot && props.applyNow) {
            setNewPattern(false);
            setPatern(pattern);
        }
    };

    const handlePlotResult = (pattern = null, options = null, data = null) => {
        if (!pattern && !newPattern) {
            return;
        }
        setOriginal(JSON.parse(JSON.stringify(pattern)));
        parsePattern(pattern);
        setProcessed(pattern);
        setOptions(options);
        setData(data);
    };

    const handleEvent = (event) => {
        if (event.type === "reload") {
            handleReload();
        } else if (event.type === "dataCompEvent") {
            if (event.action === "apply") {
                handleApply();
            } else if (event.action === "undo") {
                handleReload();
            }
        }
    };

    const handleClick = (id) => {};

    const handleSelectInput = (input) => {
        setSelectedInput(input);
        setInputsModalOpen(true);
    };

    const dispatchEventEvent = (event) => {
        props.childToParent(event);
    };

    const bubbleEvent = (event) => {
        let stopPropagation = false;
        if (!stopPropagation) {
            props.childToParent(event);
        }
    };

    const handleReload = () => {
        setNewPattern(true);
        setPatern(original);
    };

    const handleApply = () => {
        setNewPattern(false);
        setPatern(processed);
    };

    const parsePattern = (pattern) => {
        const patternId = pattern?.id;
        const nodes = pattern ? Record.flatten(pattern, "inputs") : [];
        const keyIds = nodes.filter((node) => node.key && node?.key?.dataType?.name !== "Count").map((item) => item.key.id);

        const containerId = pattern?.container?.id;
        const newQueryFilter = patternId ? { relatedToId: patternId } : keyIds && keyIds.length ? { keyId: keyIds } : containerId ? { containerId } : null;

        // only update if changed to prevent unnecessary reloading
        if (!queryFilter || JSON.stringify(queryFilter) !== JSON.stringify(newQueryFilter)) {
            setQueryFilter(newQueryFilter);
        }
        setShowAlternativeAxes(!!patternId);
    };

    return (
        <>
            <div className="slds-scrollable" ref={detailsRef} style={{ width: "100%" }}>
                <Card
                    className="slds-card_boundary slds-m-bottom_medium"
                    heading={processed?.title || ""}
                    headerActions={<HeaderActionsIcons pattern={processed} showEdit={false} onLoading={setLoading} childToParent={bubbleEvent} parentToChildEvent={props.parentToChildEvent} />}
                >
                    <div className="slds-grid slds-wrap slds-relative">
                        {loading && <Spinner assistiveText={{ label: "Loading" }} />}
                        <div style={{ flexBasis: "480px", flexGrow: 1, flexShrink: 0, overflow: "hidden" }}>
                            <EditablePatternName pattern={processed} onPatternChange={handlePatternChanged} />
                            <div style={{ height: "420px" }}>
                                <PsPatternChart
                                    record={pattern}
                                    onPlotResult={handlePlotResult}
                                    onSelectInput={handleSelectInput}
                                    childToParent={bubbleEvent}
                                    parentToChildEvent={props.parentToChildEvent}
                                    onClick={handleClick}
                                    onLoading={setLoading}
                                />
                            </div>
                        </div>
                        <div className="slds-m-top_large" style={{ flexBasis: "240px", flexGrow: 1, flexShrink: 0 }}>
                            <div className="slds-grid slds-wrap">
                                <Description pattern={processed} data={data} onPatternChange={handlePatternChanged} />
                                <PlotOptions pattern={processed} options={options} onPatternChange={handlePatternChanged} />
                                <Filters pattern={processed} onPatternChange={handlePatternChanged} handleEvent={dispatchEvent} childToParent={props.childToParent} />
                                <Details pattern={processed} />
                            </div>
                        </div>
                    </div>
                </Card>

                <div className="slds-box">
                    <Tabs id="tabs">
                        <TabsPanel label={<div onClick={() => setselectedTab("Explore More")}>Explore More</div>}>
                            {selectedTab === "Explore More" && (
                                <PsCompositionGrid
                                    view="grid"
                                    queryFilter={queryFilter}
                                    showTitle={false}
                                    tagLine="Explore more related Patterns"
                                    maxRecords="6"
                                    showFooter={false}
                                    childToParent={bubbleEvent}
                                    parentToChildEvent={props.parentToChildEvent}
                                    parentCmp={props.parentCmp}
                                    setEmpty={() => !queryFilter || !Object.keys(queryFilter).length}
                                />
                            )}
                        </TabsPanel>
                        {showAlternativeAxes && (
                            <TabsPanel label={<div onClick={() => setselectedTab("Alternative Axes")}>Alternative Axes</div>}>
                                {selectedTab === "Alternative Axes" && (
                                    <PsPatternGrid
                                        view="grid"
                                        queryFilter={queryFilter}
                                        tagLine="Similar Patterns with different Fields on the axes"
                                        maxRecords="12"
                                        showFooter={false}
                                        childToParent={bubbleEvent}
                                        parentToChildEvent={props.parentToChildEvent}
                                        parentCmp={props.parentCmp}
                                    />
                                )}
                            </TabsPanel>
                        )}
                    </Tabs>
                </div>
            </div>
            {inputsModalOpen
                ? reactDom.createPortal(
                      <InputSlotModal onPatternChange={handlePatternChanged} selectedInput={selectedInput} pattern={processed} onClose={() => setInputsModalOpen(false)} />,
                      document.body
                  )
                : null}
        </>
    );
}

export default PsPatternDetailedView;
