import { Button, Combobox, Input, Tooltip } from "@salesforce/design-system-react";
import React, { useRef, useState, useEffect } from "react";

import RecordConstants from "../../constants/RecordConstants";
import PsRecord2 from "../ps-record/PsRecord2";
import { Mode, PsRecordPropsType, MapType, FilterOption, KeyType, CreateMapRequestType, UpdateMapRequestType } from "../../types";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import Field from "../../ui/wrappers/Field";
import Toggle from "../../ui/Toggle";
import PsNavigationInput from "../ps-navigation-input/PsNavigationInput";
import Record from "../../helpers/recordLayer";
import { getRecordAPI, putRecordAPI } from "../../services/api";
import { getOptionsMap, mapJson, selectedValueLabel } from "./components/utils";
import DualListBox from "../../ui/DualListBox";
import {
    selectLoadedOption,
    getTitle,
    getBreadCrumb,
    checkRequiredField,
    checkMinDateAmountValidity,
    checkMaxDateAmountValidity,
    checkRequiredCombobox,
    checkMinDateUnitValidity,
    checkMaxDateUnitValidity,
} from "../../utils/index2";
import FormItemWrapper from "../../ui/wrappers/FormItemWrapper";
import useToastContext from "../../context/useToastContext";
import BucketPopover from "./components/BucketPopover";
import { Bucket, OptionItem, SelectedOption, ValuesText } from "./components/types";
import { formattedDate } from "../../utils";
import useAuthContext from "../../context/useAuthContext";

const recordObject = "map";
const dateTimeUnits = RecordConstants.DATETIME_UNITS || [];

const PsMap2: React.FC<PsRecordPropsType> = ({ recordId, parentId, childToParent, options, parentToChildEvent }) => {
    const { map } = options || {};
    const { newScopes } = map || {};
    const scopes = newScopes ? [{ id: newScopes[0], value: newScopes[0], label: newScopes[0], selected: true }] : [];

    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<MapType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [fieldErrors, setFieldErrors] = useState({} as { [key: string]: string });
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [useInput, setUseInput] = useState<string>("");
    const [valuesOptions, setValuesOptions] = useState<OptionItem[]>([]);
    const [keyFilter, setKeyFilter] = useState(null);
    const [selectedKey, setSelectedKey] = useState<MapType | KeyType>(null);
    const [keyItem, setKeyItem] = useState(null);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedValue, setSelectedValue] = useState<string>("");
    const [freeTextValues, setFreeTextValues] = useState<string>("");
    const [minNumber, setMinNumber] = useState<string | number>();
    const [maxNumber, setMaxNumber] = useState<string | number>();
    const [minDate, setMinDate] = useState<string>("");
    const [maxDate, setMaxDate] = useState<string>("");
    const [minDateAmount, setMinDateAmount] = useState<string>("");
    const [minDateUnit, setMinDateUnit] = useState<string>("");
    const [maxDateAmount, setMaxDateAmount] = useState<string>("");
    const [maxDateUnit, setMaxDateUnit] = useState<string>("");
    const [minDateUnitLabel, setMinDateUnitLabel] = useState<string>("");
    const [maxDateUnitLabel, setMaxDateUnitLabel] = useState<string>("");
    const [minDateTimeUnitsSelection, setMinDateTimeUnitsSelection] = useState([]);
    const [maxDateTimeUnitsSelection, setMaxDateTimeUnitsSelection] = useState([]);
    const [scopeSelection, setScopeSelection] = useState<FilterOption[]>(scopes);
    const [scopeOptions, setScopeOptions] = useState<FilterOption[]>(scopes);
    const [scope, setScope] = useState<string>(newScopes[0]);
    const [cmpState, setCmpState] = useState({
        activeField: "",
        isExpanded: false,
    });
    const [generativeAIMapEnabled, setGenerativeAIMapEnabled] = useState(false);
    const [leftListBoxOptions, setLeftListBoxOptions] = useState([]);
    const [valuesText, setValuesText] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);
    const [selectedFirstBoxItems, setSelectedFirstBoxItems] = useState([]);
    const [selectedSecondBoxItems, setSelectedSecondBoxItems] = useState([]);
    const [activeDualListBox, setActiveDualListBox] = useState("");
    const [selectedBucket, setSelectedBucket] = useState<Bucket>({} as Bucket);
    const [showNewBucketPopover, setShowNewBucketPopover] = useState(false);

    const defaultRecord = {
        name: selectedKey?.name || "",
        scope: newScopes[0],
        active: true,
        unmappedAsMissing: false,
    } as MapType;

    const { account, hasGenerativeAIAccess } = useAuthContext();
    const { addToast } = useToastContext();

    const dualListBoxRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setKeyFilterValue();
    }, [parentId]);

    useEffect(() => {
        const { Metadata } = RecordConstants.ACCOUNT_SETTINGS_GENERATIVE_AI;
        setGenerativeAIMapEnabled(hasGenerativeAIAccess(Metadata));
    }, [account]);

    function setKeyFilterValue() {
        const containerId = parentId;
        let updatedKeyFilter = null;
        if (containerId) {
            updatedKeyFilter = { keys: { key: { containerId } } };
        }
        setKeyFilter(updatedKeyFilter);
    }

    const parseUpdateRequest = (record: MapType): UpdateMapRequestType => {
        let updatedRecord = mapJson(
            // TODO: mapJson should not be a generic function, but part of PsMap
            record,
            useInput,
            freeTextValues,
            minDate,
            maxDate,
            minDateAmount,
            maxDateAmount,
            minNumber,
            maxNumber,
            minDateUnit,
            maxDateUnit,
            scope,
            selectedKey,
            selectedValues,
            valuesOptions
        );

        updatedRecord = JSON.parse(JSON.stringify(updatedRecord)); // deepcopy to prevent changing original record

        return (({ id, name, settings, unmappedAsMissing, active }) => ({ id, name, settings, unmappedAsMissing, active }))(updatedRecord);
    };

    const parseCreateRequest = (record: MapType): CreateMapRequestType => {
        let updatedRecord = mapJson(
            record,
            useInput,
            freeTextValues,
            minDate,
            maxDate,
            minDateAmount,
            maxDateAmount,
            minNumber,
            maxNumber,
            minDateUnit,
            maxDateUnit,
            scope,
            selectedKey,
            selectedValues,
            valuesOptions
        );

        updatedRecord = JSON.parse(JSON.stringify(updatedRecord)); // deepcopy to prevent changing original record

        return (({ type, name, scope, active, containerId, keyId, settings, unmappedAsMissing }) => ({ type, name, scope, active, containerId, keyId, settings, unmappedAsMissing }))(updatedRecord);
    };

    const handleSelectScope = (event: React.MouseEvent<HTMLDivElement>, data: any) => {
        if (data.selection.length === 0) return;
        setRecord((prev) => ({ ...prev, status: data.selection[0].value }));
        setScopeSelection(data.selection);
    };

    function handleMinDateAmount(value: string) {
        setMinDateAmount(value);
        const { minDateAmountMessage, minDateUnitMessage } = checkMinDateAmountValidity(value, minDateUnit);
        setFieldErrors((prev) => ({ ...prev, minDateAmount: minDateAmountMessage, minDateUnit: minDateUnitMessage }));
    }

    function handleMaxDateAmount(value: string) {
        setMaxDateAmount(value);
        const { maxDateAmountMessage, maxDateUnitMessage } = checkMaxDateAmountValidity(value, maxDateUnit);
        setFieldErrors((prev) => ({ ...prev, maxDateAmount: maxDateAmountMessage, maxDateUnit: maxDateUnitMessage }));
    }

    function handleMinDateUnitChange(event: React.MouseEvent<HTMLDivElement>, data: any) {
        if (!data.selection.length) return;

        const { value, label } = data.selection[0];
        setMinDateUnitLabel(label);
        setMinDateTimeUnitsSelection(data.selection);
        setMinDateUnit(value);
        const { minDateAmountMessage, minDateUnitMessage } = checkMinDateUnitValidity(value, minDateAmount);
        setFieldErrors((prev) => ({ ...prev, minDateAmount: minDateAmountMessage, minDateUnit: minDateUnitMessage }));
    }

    function handleMaxDateUnitChange(event: React.MouseEvent<HTMLDivElement>, data: any) {
        if (!data.selection.length) return;

        const { value, label } = data.selection[0];
        setMaxDateUnitLabel(label);
        setMaxDateTimeUnitsSelection(data.selection);
        setMaxDateUnit(value);
        const { maxDateAmountMessage, maxDateUnitMessage } = checkMaxDateUnitValidity(value, maxDateAmount);
        setFieldErrors((prev) => ({ ...prev, maxDateAmount: maxDateAmountMessage, maxDateUnit: maxDateUnitMessage }));
    }

    const setScopeValues = (newScopeOptions: FilterOption[], newScopeValue: string) => {
        const { options, selectedValue } = selectLoadedOption(newScopeOptions, newScopeValue);
        setScopeOptions(options);
        setScope(selectedValue);
    };

    function unloadKeyDetails() {
        setRecord((prev) => ({ ...prev, type: null }));
        setUseInput(null);
        setValuesOptions([{ id: "Not Available", value: "Not Available", label: "Not Available", selected: true }]);
        setLeftListBoxOptions([{ value: "", label: "Not Available" }]);
        setValuesText(null);
        setSelectedValues(null);
        setFreeTextValues("");
        setMinDateUnit(null);
        setMaxDateUnit(null);
        setMinDateUnitLabel(null);
        setMaxDateUnitLabel(null);
        setLoading(false);
    }

    function getValuesText(valuesOptions: OptionItem[] = [], selectedValues: string[] = []) {
        const mappings = getOptionsMap(true, valuesOptions, selectedValues);

        let valuesText: ValuesText = []; // Using the defined type here
        mappings.forEach((mapping) => {
            const bucketName = Object.keys(mapping)[0];
            let bucketItems = Object.values(mapping)[0] || [];
            valuesText.push({ bucket: bucketName, values: bucketItems.join(", ") });
        });

        return valuesText;
    }

    function loadKeyDetails(selectedKey: MapType | KeyType, reset: boolean, selectedValues: string[] = [], valuesOptions: OptionItem[] = []) {
        // load existing record, or open new record
        const key = selectedKey;

        if (key) {
            setLoading(true);
            let type = "" as keyof typeof RecordConstants.FILTER_TYPES;
            let record = {} as KeyType;

            const onSuccess = function (response: any[]) {
                record = response[0];
                type = record.dataType?.format as keyof typeof RecordConstants.FILTER_TYPES;

                record = { ...record, type };
                record.title = getTitle(getBreadCrumb("key", record));

                setRecord((prev) => ({ ...prev, type }));
                setSelectedKey({ ...record });

                if (reset) {
                    setRecord((prev) => ({ ...prev, name: record.name }));
                    setFieldErrors((prev) => ({ ...prev, name: checkRequiredField(record.name) }));
                    setSelectedValues(null);
                    setValuesOptions(null);
                    setLeftListBoxOptions(null);
                }

                const useInput = type === "Text" ? "MultiSelect" : "Not Supported"; // IMPROVEMENT: support other types
                setUseInput(useInput);

                if (type === "Text") {
                    // options from the Key
                    let values = record.values || [];
                    let keyOptions = values.map(({ label }) => ({
                        label: label,
                        value: label,
                    }));

                    keyOptions.push({
                        label: "--None--",
                        value: "POINT SIGMA EMPTY VALUE",
                    });
                    const leftListBoxOptions =
                        keyOptions && keyOptions.length > 0 ? keyOptions.filter((keyOption) => !valuesOptions?.find((valueOption) => valueOption.value === keyOption.value)) : [];
                    setLeftListBoxOptions(leftListBoxOptions);

                    // merge with options from the Map
                    let options = keyOptions.concat(valuesOptions || []);

                    // dedupe options from Key and Map on bucket
                    let optionsMap: Record<string, OptionItem> = options.reduce(
                        (obj, item) => {
                            obj[item.value] = item;
                            return obj;
                        },
                        {} as Record<string, OptionItem>
                    );

                    options = Object.values(optionsMap);
                    setValuesOptions(options);
                    setValuesText(getValuesText(options, selectedValues));
                }

                setLoading(false);
            };

            const onError = function () {
                unloadKeyDetails();
            };

            getRecordAPI({ module: "store", object: "key", recordId: key.id }, onSuccess, onError);
        } else {
            unloadKeyDetails();
        }
    }

    function updateUI(record: MapType) {
        let key = record.key;
        let values = [] as string[];
        let options = [] as OptionItem[];
        if (key) {
            key.title = getTitle(getBreadCrumb("key", key));
            setSelectedKey(key);
            setKeyItem(Record.nameFromDetails("keys", "key", key.id));
        } else {
            unloadKeyDetails();
        }
        const useInput = record.type === "Text" ? "MultiSelect" : "Not Supported"; // IMPROVEMENT: support other types
        setUseInput(useInput);
        let mappings = record.settings?.map || [];
        if (useInput === "MultiSelect") {
            mappings.forEach((mapping) => {
                const bucketName = Object.keys(mapping)[0];
                const bucketItems = Object.values(mapping)[0] || [];
                const bucketValue = "POINT SIGMA BUCKET VALUE " + Math.random().toString();
                options.push({
                    label: "*** " + bucketName + " ***",
                    value: bucketValue,
                    bucket: bucketName,
                });

                values.push(bucketValue);
                bucketItems.forEach((item) => {
                    if (item === null) {
                        options.push({
                            label: "--None--",
                            value: "POINT SIGMA EMPTY VALUE",
                        });
                        values.push("POINT SIGMA EMPTY VALUE");
                    } else {
                        options.push({ label: item, value: item });
                        values.push(item);
                    }
                });
            });

            setSelectedOptions(options);
            setValuesOptions(options);
            setSelectedValues(values);
        }
        // new / existing record
        const isSaved = Boolean(record.id || record.floatingId);
        setIsSaved(isSaved);

        // scope
        let updatedScopes = scopes;

        if (!isSaved) {
            updatedScopes = updatedScopes.filter(function (v) {
                return newScopes.includes(v.value);
            });
        }
        setScopeValues(updatedScopes, record.scope);
        loadKeyDetails(key, false, values, options);
    }

    const handleKeySelect = (record: MapType) => {
        const selectedKey = { ...record };
        setSelectedKey({ ...selectedKey });
        loadKeyDetails(selectedKey, true, selectedValues, selectedOptions);
        setFieldErrors((prev) => ({ ...prev, key: checkRequiredField(selectedKey.name) }));
    };

    const onEdit = () => {
        setFieldErrors({});
        setMode("edit");
    };

    const handleSelectedValuesChange = (optionValue: string, process: string) => {
        const selectedDualListBoxItems = activeDualListBox === "firstBox" ? selectedFirstBoxItems : selectedSecondBoxItems; // selectedItems values
        let selectedOption = valuesOptions.find((valuesOption) => valuesOption.value === optionValue);

        // If a new bucket is added but not saved yet
        if (!selectedOption) {
            selectedOption = leftListBoxOptions.find((valuesOption) => valuesOption.value === optionValue);
        }

        setSelectedValue(null);
        setSelectedFirstBoxItems([]);
        setSelectedSecondBoxItems([]);

        if (process === "optionsToSelected") {
            let updatedSelectedOptions = [] as SelectedOption[];
            const updatedLeftListBoxOptions = leftListBoxOptions?.length > 0 ? leftListBoxOptions.filter((item) => !selectedDualListBoxItems.includes(item.value)) : [];
            setLeftListBoxOptions(updatedLeftListBoxOptions);

            const updatedSelectedValues = selectedValues ? [...selectedValues, ...selectedDualListBoxItems] : selectedDualListBoxItems;
            setSelectedValues(updatedSelectedValues);

            if (selectedOptions?.length > 0) {
                const isLabelIncluded = selectedOptions.some((item) => item.label.includes("**"));
                if (isLabelIncluded) {
                    updatedSelectedOptions = [
                        ...selectedOptions,
                        ...selectedDualListBoxItems.map((item) => ({
                            value: item,
                            label: item === "POINT SIGMA EMPTY VALUE" ? "--None--" : item,
                        })),
                    ];
                    setSelectedOptions(updatedSelectedOptions);

                    // update valuesOptions
                    if ((valuesOptions?.length === 1 && updatedSelectedOptions?.length > 1) || valuesOptions?.length < updatedSelectedOptions?.length) {
                        setValuesOptions([...updatedSelectedOptions, ...updatedLeftListBoxOptions]);
                    }
                    return;
                }
            }

            updatedSelectedOptions = valuesOptions.filter((item) => selectedDualListBoxItems.includes(item.value));

            setSelectedOptions((prev) => [...(prev || []), ...updatedSelectedOptions]);
        } else {
            // multiselectable
            const updatedSelectedValues = selectedValues?.length > 0 ? selectedValues.filter((item) => !selectedDualListBoxItems.includes(item)) : [];
            const updatedSelectedOptions = selectedOptions.filter((item) => !selectedDualListBoxItems.includes(item.value));
            const updatedLeftListBoxOptions = [...leftListBoxOptions, ...valuesOptions.filter((item) => selectedDualListBoxItems.includes(item.value))].sort((a, b) => a.label.localeCompare(b.label));
            setLeftListBoxOptions(updatedLeftListBoxOptions);
            setSelectedOptions(updatedSelectedOptions);
            setSelectedValues(updatedSelectedValues);
        }
    };

    const handleEditBucket = (selectedBucket: SelectedOption) => {
        setSelectedOptions((prev) => prev.filter((item) => item.value !== selectedBucket.value));
        setSelectedValues((prev) => prev.filter((item) => item !== selectedBucket.value));
    };

    const handleSelectedValueUpOrDown = (process: string) => {
        const selectedValue = selectedSecondBoxItems[0];
        const currentIndex = selectedValues.indexOf(selectedValue);

        if (currentIndex === -1 || (process === "up" && currentIndex <= 0) || (process === "down" && currentIndex >= selectedValues.length - 1)) {
            return;
        }

        const updatedSelectedValues = [...selectedValues];
        if (process === "up" && currentIndex > 0) {
            const temp = updatedSelectedValues[currentIndex];
            updatedSelectedValues[currentIndex] = updatedSelectedValues[currentIndex - 1];
            updatedSelectedValues[currentIndex - 1] = temp;
        } else if (process === "down" && currentIndex < updatedSelectedValues.length - 1) {
            const temp = updatedSelectedValues[currentIndex];
            updatedSelectedValues[currentIndex] = updatedSelectedValues[currentIndex + 1];
            updatedSelectedValues[currentIndex + 1] = temp;
        }

        setSelectedValues(updatedSelectedValues);
        const updatedSelectedOptions =
            updatedSelectedValues && updatedSelectedValues.length > 0
                ? updatedSelectedValues.map((value) => {
                      return { value, label: selectedValueLabel(valuesOptions, value) };
                  })
                : [];

        setSelectedOptions(updatedSelectedOptions);
    };

    // when click any item on the duallistbox
    const handleChangeSelectedValue = (selected: string) => {
        setSelectedValue(selected);

        const selectedOptionsValues = selectedOptions.map((item) => item.value);

        // update active dualListBox
        const activeDualListBox = selectedOptionsValues.includes(selected) ? "secondBox" : "firstBox";

        setActiveDualListBox(activeDualListBox);

        if (activeDualListBox === "firstBox") {
            let updatedSelectedFirstBoxItems = selectedFirstBoxItems;

            const selectedValue = updatedSelectedFirstBoxItems.find((item) => item === selected);

            if (selectedValue) {
                updatedSelectedFirstBoxItems = updatedSelectedFirstBoxItems.filter((item) => item !== selected);
            } else {
                updatedSelectedFirstBoxItems = [...updatedSelectedFirstBoxItems, selected];
            }

            setSelectedFirstBoxItems(updatedSelectedFirstBoxItems);
            setSelectedSecondBoxItems([]);
        } else {
            let updatedSelectedSecondBoxItems = selectedSecondBoxItems;

            const selectedValue = updatedSelectedSecondBoxItems.find((item) => item === selected);

            if (selectedValue) {
                updatedSelectedSecondBoxItems = updatedSelectedSecondBoxItems.filter((item) => item !== selected);
            } else {
                updatedSelectedSecondBoxItems = [...updatedSelectedSecondBoxItems, selected];
            }
            setSelectedSecondBoxItems(updatedSelectedSecondBoxItems);
            setSelectedFirstBoxItems([]);
        }
    };

    function processAfterSaveOrCancel() {
        if (selectedFirstBoxItems?.length > 0) {
            setSelectedFirstBoxItems([]);
        }
        if (selectedSecondBoxItems?.length > 0) {
            setSelectedSecondBoxItems([]);
        }
    }

    const saveBucket = (selectedBucketLabel: string = selectedBucket.label || "", selectedBucketValue: string = selectedBucket.value || "") => {
        if (!selectedBucketLabel) {
            setShowNewBucketPopover(false);
            return;
        }

        const bucketValue = "POINT SIGMA BUCKET VALUE " + Math.random().toString();
        let options = selectedOptions || leftListBoxOptions || [];

        const isDuplicate = options.some((option) => option.bucket === selectedBucketLabel && option.value !== selectedBucketValue);
        if (isDuplicate) {
            const toastId = selectedBucketLabel;
            addToast("info", "Info", `Bucket name "${selectedBucketLabel}" already exists.`, toastId);
            return;
        }

        if (selectedBucketValue) {
            // Update existing bucket
            const index = options.findIndex((option) => option.value === selectedBucketValue);
            if (index !== -1 && options[index].bucket !== selectedBucketLabel) {
                options[index] = {
                    label: `*** ${selectedBucketLabel} ***`,
                    value: selectedBucketValue,
                    bucket: selectedBucketLabel,
                };
            }
            setSelectedBucket({ label: "", value: "" });
        } else {
            // Add new bucket
            options.push({
                label: `*** ${selectedBucketLabel} ***`,
                value: bucketValue,
                bucket: selectedBucketLabel,
            });

            setSelectedValues((prev) => [...(prev || []), bucketValue]);
            setShowNewBucketPopover(false);
        }

        setValuesOptions(options);
        setSelectedOptions(options);
        setValuesText(getValuesText(options));
    };

    const isGenerateMapButtonDisabled = () => {
        if (!leftListBoxOptions || leftListBoxOptions.length === 0 || (leftListBoxOptions.length === 1 && leftListBoxOptions[0].label === "--None--")) {
            return true;
        }
        return false;
    };

    const handleGenerateMap = () => {
        setLoading(true);
        const onSuccess = function (response: any) {
            setLoading(false);
            let updatedRecord = { ...record };
            if (!response.settings || !response.settings.map) {
                console.error("No map returned from AI");
                console.error("response: ", response);
                return;
            }
            updatedRecord.settings = updatedRecord.settings || {};
            updatedRecord.settings.map = response.settings.map;
            setRecord({ ...updatedRecord });
            updateUI(updatedRecord);

            if (selectedValues?.length < valuesOptions?.length) {
                addToast("info", "Info", "Not all values could be assigned to the categories. You can manually assign the values, or create better matching categories and try again");
            }
        };

        const onError = function () {
            addToast("error", "Error", "An error occurred during the map with AI. You can manually assign the values, or create better matching categories and try again");
            setLoading(false);
        };

        const parsedRecord = record.id ? parseUpdateRequest(record) : parseCreateRequest(record);
        const bodyObject = {
            ...record,
            name: parsedRecord.name,
            keyId: selectedKey.id,
            settings: parsedRecord.settings,
        };

        putRecordAPI({ module: "store", recordId: "", object: "generative", inputBody: bodyObject }, onSuccess, onError);
    };

    const hanleNewBucket = () => {
        setShowNewBucketPopover(true);
        setSelectedBucket({} as Bucket);
    };

    const addNewBucketRow = showNewBucketPopover ? (
        <BucketPopover selectedBucket={selectedBucket} setSelectedBucket={setSelectedBucket} saveBucket={saveBucket} setShowNewBucketPopover={setShowNewBucketPopover} />
    ) : (
        <div className="slds-grid slds-grid_align-end slds-m-horizontal_small">
            <Button iconCategory="utility" iconName="add" iconPosition="left" variant="icon" title="Add new bucket" label="New Bucket" onClick={() => hanleNewBucket()} />
        </div>
    );

    // record card body-content
    const cardBody = (
        <div className="slds-form slds-var-m-around_medium" role="list">
            <h3 className="slds-section-title--divider slds-var-m-top_medium">Map Details</h3>
            <div className="slds-form__row">
                <FormItemWrapper>
                    <div className="slds-grid slds-grid_vertical slds-wrap">
                        <div className="slds-col slds-size_1-of-1">
                            <div className="slds-grid slds-grid_vertical-align-center slds-var-m-bottom_small">
                                {/* Active */}
                                <div className="slds-col slds-size_xxx-small">
                                    <Field
                                        mode={mode}
                                        isEditable
                                        isFormItem
                                        value={mode === "edit" ? null : <CheckAndCloseIcons selectedItem={record?.active} />}
                                        label="Active"
                                        onEdit={onEdit}
                                        customChangeHandler
                                        body={
                                            <Toggle
                                                label="Active"
                                                active={record?.active}
                                                setActive={() => setRecord((prev) => ({ ...prev, active: !prev?.active }))} //
                                            />
                                        }
                                    />
                                </div>
                                {/* Name */}
                                <div className="slds-grow slds-m-left_small">
                                    <Field
                                        recordObject={recordObject}
                                        setRecord={setRecord}
                                        mode={mode}
                                        value={record?.name}
                                        label="Name"
                                        onEdit={onEdit}
                                        fieldName="name"
                                        isEditable
                                        isFormItem
                                        canOverride
                                        hasOverride={record?.hasOverride?.name}
                                        checkValidity={checkRequiredField}
                                        setFieldErrors={setFieldErrors}
                                        body={<Input name="name" autoComplete="off" label="Name" required value={record?.name} errorText={fieldErrors?.name} />}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </FormItemWrapper>
                {/* Scope */}
                <FormItemWrapper>
                    <Field
                        mode={mode}
                        value={record?.scope}
                        label="Scope"
                        onEdit={onEdit}
                        isFormItem
                        customChangeHandler
                        body={
                            <Combobox
                                events={{ onSelect: (event: React.MouseEvent<HTMLDivElement>, data: any) => handleSelectScope(event, data) }}
                                labels={{ label: "Scope", placeholder: "--Please Select--" }}
                                menuPosition="relative"
                                options={scopeOptions}
                                selection={scopeSelection}
                                value={record?.scope || ""}
                                variant="readonly"
                                singleInputDisabled={isSaved}
                                disabled={isSaved}
                                name="scope"
                            />
                        }
                    />
                </FormItemWrapper>
            </div>

            <h3 className="slds-section-title--divider slds-var-m-top_medium">Settings</h3>
            <div className="slds-form__row">
                {/* Key (Field) */}
                <Field
                    mode={mode}
                    value={selectedKey?.name || keyItem}
                    label="Field"
                    onEdit={onEdit}
                    fieldName="key"
                    checkValidity={checkRequiredField}
                    setFieldErrors={setFieldErrors}
                    customChangeHandler
                    body={
                        <PsNavigationInput
                            label="Field"
                            object="key"
                            sections={["keys"]}
                            selected={keyItem}
                            record={selectedKey}
                            required
                            disabled={isSaved}
                            onChange={handleKeySelect}
                            activeField={cmpState.activeField}
                            setFilterState={setCmpState}
                            fieldErrors={fieldErrors}
                            childToParent={childToParent}
                            filters={keyFilter}
                            value={selectedKey}
                            name="key"
                        />
                    }
                />
            </div>

            <div className="slds-form__row">
                {/* Not Supported */}
                {useInput === "Not Supported" && (
                    <Field
                        mode={mode}
                        value=""
                        label="Not currently supported"
                        showStaticViewInEditMode
                        body={
                            <div className="slds-form-element_edit slds-form-element_stacked slds-hint-parent slds-form-element_readonly">
                                <span className="slds-form-element__label">Not currently supported</span>
                            </div>
                        }
                    />
                )}

                {/*  Freetext  */}
                {useInput === "FreeText" && (
                    <Field
                        mode={mode}
                        value={freeTextValues}
                        label="Values"
                        onEdit={onEdit}
                        fieldName="freeTextValues"
                        customChangeHandler
                        body={
                            <Input
                                name="freeTextValues"
                                label="Values"
                                autocomplete="off"
                                value={freeTextValues}
                                fieldLevelHelpTooltip={
                                    <Tooltip
                                        id="field-level-help-tooltip"
                                        align="top left"
                                        content='Enter one or more values separated by commas. Enclose values with commas in double quotes. For example: orange, "pear, apple", banana'
                                    />
                                }
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setFreeTextValues(e.target.value)}
                            />
                        }
                    />
                )}

                {useInput === "MultiSelect" && (
                    <Field
                        mode={mode}
                        value={valuesText.map((item, index) => (
                            <div key={index}>
                                {item.bucket} -&gt; {item.values}
                                <br />
                            </div>
                        ))}
                        label="Mapping"
                        onEdit={onEdit}
                        isEditable
                        fieldName="valuesText"
                        body={
                            <div ref={dualListBoxRef}>
                                <DualListBox
                                    handleSelectedValuesChange={handleSelectedValuesChange}
                                    handleChangeSelectedValue={handleChangeSelectedValue}
                                    handleSelectedValueUpOrDown={handleSelectedValueUpOrDown}
                                    label="Mapping"
                                    fieldLevelHelp="Create buckets to rename or group values, and add items under each bucket. Remove a bucket by moving it from right to left. Edit a bucket name by creating a new one with the desired name and deleting the old bucket"
                                    valuesOptions={leftListBoxOptions?.length > 0 ? leftListBoxOptions.filter((item) => !item.bucket) : []}
                                    selectedOptions={selectedOptions}
                                    selectedValue={selectedValue}
                                    selectedFirstBoxItems={selectedFirstBoxItems}
                                    selectedSecondBoxItems={selectedSecondBoxItems}
                                    saveBucket={saveBucket}
                                    selectedBucket={selectedBucket}
                                    setSelectedBucket={setSelectedBucket}
                                    handleEditBucket={handleEditBucket}
                                    addNewBucketRow={addNewBucketRow}
                                    showNewBucketPopover={showNewBucketPopover}
                                    setShowNewBucketPopover={setShowNewBucketPopover}
                                />
                                {generativeAIMapEnabled && (
                                    <div className="slds-grid slds-wrap slds-grid_vertical-stretch">
                                        <div className="slds-size_1-of-2 slds-p-bottom_small"></div>
                                        <div className="slds-size_1-of-2 slds-p-top_medium">
                                            <Button
                                                iconCategory="utility"
                                                iconName="magicwand"
                                                iconPosition="left"
                                                variant="outline-brand"
                                                label=" Generate Map with AI"
                                                title="Generate Map with AI"
                                                onClick={handleGenerateMap}
                                                disabled={isGenerateMapButtonDisabled()}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                    />
                )}

                {/* Between Number  */}
                {useInput === "BetweenNumber" && (
                    <>
                        {/* Min Number */}
                        <Field
                            mode={mode}
                            value={minNumber}
                            label="From"
                            onEdit={onEdit}
                            fieldName="minNumber"
                            customChangeHandler
                            body={
                                <Input
                                    type="text"
                                    name="minnumber"
                                    autoComplete="off"
                                    label="From"
                                    value={minNumber}
                                    fieldLevelHelpTooltip={<Tooltip id="field-level-help-tooltip" align="top left" content="Minimum value (included). Leave empty to ignore." />}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setMinNumber(e.target.value)}
                                />
                            }
                        />
                        {/* Max Number */}
                        <Field
                            mode={mode}
                            value={maxNumber}
                            label="To"
                            onEdit={onEdit}
                            fieldName="maxNumber"
                            customChangeHandler
                            body={
                                <Input
                                    type="text"
                                    name="maxnumber"
                                    autoComplete="off"
                                    label="To"
                                    value={maxNumber}
                                    fieldLevelHelpTooltip={<Tooltip id="field-level-help-tooltip" align="top left" content="Maximum value (included). Leave empty to ignore." />}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setMaxNumber(e.target.value)}
                                />
                            }
                        />
                    </>
                )}

                {/* Between DateTime */}
                {useInput === "BetweenDateTime" && (
                    <>
                        {/* Min DateTime */}
                        <Field
                            mode={mode}
                            value={minDate}
                            label="From"
                            onEdit={onEdit}
                            fieldName="mindate"
                            customChangeHandler
                            body={
                                <Input
                                    type="datetime-local"
                                    name="mindate"
                                    autoComplete="off"
                                    label="From"
                                    value={formattedDate(minDate)}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: { value: string }) => setMinDate(data.value)}
                                    fieldLevelHelpTooltip={<Tooltip id="field-level-help-tooltip" align="top left" content="Minimum date and time (included). Leave empty to ignore." />}
                                />
                            }
                        />

                        {/* Max DateTime */}
                        <Field
                            mode={mode}
                            value={maxDate}
                            label="To"
                            onEdit={onEdit}
                            fieldName="maxdate"
                            customChangeHandler
                            body={
                                <Input
                                    type="datetime-local"
                                    name="maxdate"
                                    autoComplete="off"
                                    label="To"
                                    value={formattedDate(maxDate)}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: { value: string }) => setMaxDate(data.value)}
                                    fieldLevelHelpTooltip={<Tooltip id="field-level-help-tooltip" align="top left" content="Maximum date and time (excluded). Leave empty to ignore." />}
                                />
                            }
                        />
                    </>
                )}

                {/* Relative DateTime */}
                {useInput === "RelativeDateTime" && (
                    <>
                        <FormItemWrapper label="From">
                            {/* Min Amount */}
                            <Field
                                isFormItem
                                mode={mode}
                                value={minDateAmount}
                                label="Amount"
                                onEdit={onEdit}
                                fieldName="minDateAmount"
                                checkValidity={minDateUnit && minDateUnit !== "--None--" ? checkRequiredField : null}
                                setFieldErrors={setFieldErrors}
                                customChangeHandler
                                body={
                                    <Input
                                        type="number"
                                        step={1}
                                        name="minDateAmount"
                                        label="Amount"
                                        required={minDateUnit && minDateUnit !== "--None--" ? true : false}
                                        value={minDateAmount}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleMinDateAmount(e.target.value)}
                                        errorText={fieldErrors?.minDateAmount}
                                    />
                                }
                            />
                            {/* Min Unit */}
                            <Field
                                isFormItem
                                mode={mode}
                                value={minDateUnitLabel}
                                label="Unit"
                                onEdit={onEdit}
                                fieldName="minDateUnit"
                                checkValidity={minDateAmount ? checkRequiredCombobox : null}
                                setFieldErrors={setFieldErrors}
                                customChangeHandler
                                body={
                                    <Combobox
                                        menuItemVisibleLength={10}
                                        events={{ onSelect: (event: React.MouseEvent<HTMLDivElement>, data: any) => handleMinDateUnitChange(event, data) }}
                                        labels={{
                                            label: "Unit",
                                            placeholder: "Select an option",
                                        }}
                                        menuPosition="relative"
                                        options={dateTimeUnits}
                                        selection={minDateTimeUnitsSelection}
                                        value={minDateUnit}
                                        name="minDateUnit"
                                        variant="readonly"
                                        required={minDateAmount ? true : false}
                                        errorText={fieldErrors?.minDateUnit}
                                    />
                                }
                            />
                        </FormItemWrapper>
                        <FormItemWrapper label="To">
                            {/* Max Amount */}
                            <Field
                                isFormItem
                                mode={mode}
                                value={maxDateAmount}
                                label="Amount"
                                onEdit={onEdit}
                                fieldName="maxDateAmount"
                                checkValidity={maxDateUnit && maxDateUnit !== "--None--" ? checkRequiredField : null}
                                setFieldErrors={setFieldErrors}
                                customChangeHandler
                                body={
                                    <Input
                                        type="number"
                                        step={1}
                                        name="maxDateAmount"
                                        label="Amount"
                                        required={maxDateUnit && maxDateUnit !== "--None--" ? true : false}
                                        value={maxDateAmount}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleMaxDateAmount(e.target.value)}
                                        errorText={fieldErrors?.maxDateAmount}
                                    />
                                }
                            />
                            {/* Max Unit */}
                            <Field
                                isFormItem
                                mode={mode}
                                value={maxDateUnitLabel}
                                label="Unit"
                                onEdit={onEdit}
                                fieldName="maxDateUnit"
                                checkValidity={maxDateAmount ? checkRequiredCombobox : null}
                                setFieldErrors={setFieldErrors}
                                customChangeHandler
                                body={
                                    <Combobox
                                        menuItemVisibleLength={10}
                                        events={{ onSelect: (event: React.MouseEvent<HTMLDivElement>, data: any) => handleMaxDateUnitChange(event, data) }}
                                        labels={{
                                            label: "Unit",
                                            placeholder: "Select an option",
                                        }}
                                        menuPosition="relative"
                                        options={dateTimeUnits}
                                        selection={maxDateTimeUnitsSelection}
                                        name="maxDateUnit"
                                        value={maxDateUnit}
                                        variant="readonly"
                                        required={maxDateAmount ? true : false}
                                        errorText={fieldErrors?.maxDateUnit}
                                    />
                                }
                            />
                        </FormItemWrapper>
                    </>
                )}
            </div>

            {/* AcceptMissingValuesToggle */}
            <div className={`slds-form__row ${mode === "edit" ? "slds-size_1-of-4" : "slds-size_1-of-2"}`}>
                <Field
                    mode={mode}
                    isEditable
                    value={mode === "edit" ? null : <CheckAndCloseIcons selectedItem={record?.unmappedAsMissing} />}
                    label="Unmapped values as missing"
                    onEdit={onEdit}
                    customChangeHandler
                    body={
                        <Toggle
                            label="Set unmapped values as missing"
                            active={record?.unmappedAsMissing}
                            setActive={() => setRecord((prev) => ({ ...prev, unmappedAsMissing: !prev?.unmappedAsMissing }))}
                        />
                    }
                />
            </div>
        </div>
    );

    return (
        <PsRecord2
            recordLabel="Map"
            recordModule="store"
            recordObject={recordObject}
            overrideFields={["name"]}
            record={record}
            defaultRecord={defaultRecord}
            showEdit
            showDelete
            mode={mode}
            recordId={recordId}
            parentId={parentId}
            parentToChildEvent={parentToChildEvent}
            fieldErrors={fieldErrors}
            updateUI={updateUI}
            setMode={setMode}
            onEdit={onEdit}
            setRecord={setRecord}
            setFieldErrors={setFieldErrors}
            loading={loading}
            setLoading={setLoading}
            childToParent={childToParent}
            parseCreateRequest={parseCreateRequest}
            parseUpdateRequest={parseUpdateRequest}
            cardBody={cardBody}
            processAfterSaveOrCancel={processAfterSaveOrCancel}
        />
    );
};

export default PsMap2;
