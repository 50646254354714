const UndoButtonIcon = ({ onClick }) => {
    return (
        <div className="slds-p-left_x-small">
            <span className="slds-form-element__label">&nbsp;</span>
            <div className="slds-form-element__control">
                <button className="slds-button slds-button_icon-border-filled" onClick={onClick} title="Revert to original value">
                    <svg className="slds-button__icon" aria-hidden="true">
                        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#undo"></use>
                    </svg>
                    <span className="slds-assistive-text">Revert</span>
                </button>
            </div>
        </div>
    );
};

export default UndoButtonIcon;
