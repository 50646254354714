import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import "./DataManagement.css";
import Record from "../../helpers/recordLayer.js";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import PsNavigationTree from "../../components/ps-navigation-tree/PsNavigationTree";
import SearchBox from "../../components/ps-search-box/SearchBox";
import PsSourceList from "../../components/ps-source-list/PsSourceList";
import PsSource from "../../components/ps-source/PsSource";
import PsConnector from "../../components/ps-connector/PsConnector";
import PsConnectorList from "../../components/ps-connector-list/PsConnectorList";
import PsObjectList from "../../components/ps-object-list/PsObjectList";
import PsObject from "../../components/ps-object/PsObject";
import PsFieldList from "../../components/ps-field-list/PsFieldList";
import PsField from "../../components/ps-field/PsField";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import useAuthContext from "../../context/useAuthContext.js";

function DataManagement() {
    const [cmpState, setCmpState] = useState({
        selectedItem: {},
        parentId: "",
        selectedObject: "",
        searchText: "",
        searchLoading: false,
        rootId: "",
        connectorsId: "",
        sourcesId: "",
        sourceId: "",
        sourceFilter: {},
        connectorId: "",
        connectorFilter: {},
        objectId: "",
        objectFilter: {},
        fieldId: "",
        parentToChildEvent: {},
        navigationLoading: false,
    });

    const { handleLogout } = useAuthContext();
    const [setupStatusVisible, setSetupStatusVisible] = useState(true);
    const [isLogin, setIsLogin] = useState(false);

    const memoizedObject = useMemo(() => ({}), []);

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();
    const cmpWorking = useRef({});
    const cmpNavigationTree = useRef(null);

    const leftRef = useRef(null);
    const searchdivRef = useRef(null);
    const isFirstRender = useRef(true);

    useEffect(() => {
        cmpWorking.current = { ...cmpState };
        cmp.init();
        cmp.checkLogin();
        cmp.parsePageRef();
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            // last useEffect set it to false
            isFirstRender.current = false;
            return;
        }
        cmp.onPageReferenceChange();
    }, [searchParams]);

    const cmp = {
        get: (key) => {
            return cmpWorking.current[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setCmpState((prev) => ({ ...prev, [key]: value }));
        },

        init: function () {},

        onPageReferenceChange: function () {
            try {
                cmp.checkLogin();
                cmp.parsePageRef();
            } catch (err) {
                console.error(err);
            }
        },

        handleNavigationEvent: function (event) {
            var source = event.source;

            // navigate to different tab
            var tab = event.tab;
            if (tab) {
                navigate("/" + tab);
                return;
            }

            // if no object is provided (e.g., when navigating to parentId), use the navigation tree to figure out where to go
            var id = event.id;
            var object = event.obj;
            if (id && !object) {
                var name = cmpNavigationTree.current.getNameFromId(id) || Record.nameFromDetails("overview", "connectors", "connectorList");
                cmp.navigateFromName(null, name);
                return;
            }

            // scroll only
            if (["change", "closeSearch"].includes(source)) {
                var scroll = event.scroll;
                var scroller = leftRef.current;
                var searchInput = searchdivRef.current;

                // update scroll position after rendering, so that rendered sizes are available
                if (searchInput && scroller && scroll != null) {
                    var timer = setTimeout(() => {
                        var top = searchInput.offsetTop + searchInput.offsetHeight;
                        scroller.scrollTop = scroll * (scroller.scrollHeight - top);
                    }, 0);
                }
            }

            // navigate to specified record
            if (["record", "grid", "tree"].includes(source)) {
                cmp.navigateFromEvent(event);
            }
        },

        checkLogin: function () {
            // set isLogin, and remove the 'login' parameter from the url
            const isLogin = searchParams.get("login");
            if (isLogin) {
                setIsLogin(true);
                const updatedSearchParams = new URLSearchParams(searchParams);
                updatedSearchParams.delete("login");
                navigate({ pathname: "/DataManagement", search: updatedSearchParams.toString() }, { replace: true });
            }
        },

        parsePageRef: function () {
            let defaultSelected = Record.nameFromDetails("overview", "connectors", "connectorList");
            let selected = searchParams.get("selected") || defaultSelected;
            selected = selected === "__" ? defaultSelected : selected;

            var parsed = Record.parseName(selected);
            var config = parsed.config;
            var id = parsed.id;

            // each config has its own id and queryFilter variable, to prevent its component from reloading when changing the selection
            var selectedIdName = config + "Id";
            cmp.set(selectedIdName, id);

            // query filter
            var queryFilter = {};
            if (["source", "connector", "object"].includes(config)) {
                var queryFilterName = config + "Filter";
                queryFilter[config + "Id"] = id;
                if (JSON.stringify(queryFilter) !== JSON.stringify(cmp.get(queryFilterName))) {
                    cmp.set(queryFilterName, queryFilter);
                }
            }

            const parentId = selected.split("parent=")[1]; // TODO: NAVIGATION: use proper url parameter
            if (parentId !== cmp.get("parentId")) {
                cmp.set("parentId", parentId);
            }

            cmp.set("selectedObject", config);
            cmp.set("selectedItem", selected);
        },

        navigateFromEvent: function (event) {
            // if id is empty (i.e., when creating a new record), set the parent
            var id = event.id;
            var parentId = id ? undefined : event.parentId;

            // navigate
            var defaultSections = { root: "overview", connectors: "overview", sources: "pump", source: "pump", connector: "pump", object: "pump", field: "pump" };
            var item = Record.itemFromEvent(event, defaultSections);

            // navigate
            cmp.navigateFromName(parentId, item.name);
        },

        navigateFromName: function (parentId, name) {
            var selectedObject = cmp.get("selectedObject");
            var selectedId = selectedObject ? cmp.get(selectedObject + "Id") : null;
            var selectedParentId = cmp.get("parentId");
            var parsed = Record.parseName(name);
            var selected = searchParams.get("selected");

            if (parsed.id !== selectedId || parsed.config !== selectedObject || parentId !== selectedParentId || selected !== name) {
                let search = "?selected=" + name; // TODO: use proper URL construction methods here and throughout the code
                if (parentId) {
                    search = search + "__parent=" + parentId;
                }

                navigate({ pathname: "/DataManagement", search });
            }
        },

        // new functions
        // clearQueryFilter: function (object) {
        //   const objects = [
        //     "field",
        //     "object",
        //     "connector",
        //     "source",
        //     "sources",
        //     "connectors",
        //   ].filter((item) => item !== object);

        //   objects.map((item) => {
        //     cmp.set(item + "Filter", {});
        //     cmp.set(item + "Id", "");
        //   });

        //   if (object === "sources") {
        //     cmp.set("sourceId", "");
        //   }

        //   if (object === "connectors") {
        //     cmp.set("connectorFilter", {});
        //     cmp.set("connectorId", "");
        //   }
        // },
    };

    const dispatchEvent = (event) => {
        cmp.set("parentToChildEvent", event);
    };

    const bubbleEvent = (event) => {
        let stopPropagation = false;

        if (event.type === "navigation") {
            stopPropagation = true;
            cmp.handleNavigationEvent(event);
        } else if (event.type === "logout") {
            stopPropagation = true;
            handleLogout();
        } else if (event.type === "reload") {
            stopPropagation = true;
            handleReload();
        }

        if (!stopPropagation) {
            dispatchEvent(event);
        }
    };

    const handleReload = () => {
        dispatchEvent({ type: "reload" });
        cmp.set("navigationLoading", true);
    };

    const handleSearchTextChange = (value) => {
        cmp.set("searchText", value);
    };

    return (
        <div className="DataManagement">
            <PsNavigationHeader childToParent={bubbleEvent} loading={cmpState.loading} />

            <div className="tab-content slds-p-around_medium">
                <div className="left slds-m-right_medium">
                    {/* <!--NB: only using right-margin, because flex has no collapsing margins--> */}
                    {/* <!-- navigation tree --> */}
                    <article id="left" className="slds-card">
                        <div
                            className="slds-card__header"
                            style={{
                                margin: "0px 0 -2px -5px",
                            }}
                        >
                            <h2 className="card-main-title-lh32 slds-card__header-title slds-text-heading_small">Browse</h2>
                        </div>
                        <div
                            ref={searchdivRef}
                            className="slds-p-around_x-small"
                            style={{
                                marginBottom: "-10px",
                            }}
                        >
                            <SearchBox searchText={cmpState.searchText} loading={cmpState.navigationLoading} handleSearchTextChange={handleSearchTextChange} />
                        </div>

                        <div
                            ref={leftRef}
                            className="slds-card__body"
                            style={{
                                overflowY: "auto",
                                height: "calc(100vh - 14.4rem)",
                            }}
                        >
                            <div className="slds-p-horizontal_x-small">
                                <PsNavigationTree
                                    multiSelect={false}
                                    sections={["overview", "pump"]}
                                    selected={cmpState.selectedItem}
                                    searchText={cmpState.searchText}
                                    setLoading={(value) => cmp.set("navigationLoading", value)}
                                    parentCmp={cmp}
                                    ref={cmpNavigationTree}
                                    childToParent={bubbleEvent}
                                    parentToChildEvent={cmpState.parentToChildEvent}
                                />
                            </div>
                        </div>
                    </article>
                </div>

                {/* <!-- right panel view --> */}
                <div className={setupStatusVisible ? "right" : "right slds-scrollable"}>
                    <PsSetupStatus title="Data Management" tagLine="Create and manage connected data sources." includeStatus={["Error"]} onSetupStatusVisible={setSetupStatusVisible} />

                    {cmpState.selectedObject === "connectors" && (
                        <PsConnectorList
                            view="grid"
                            queryFilter={memoizedObject}
                            parentId={cmpState.connectorsId}
                            maxRecords="12"
                            childToParent={bubbleEvent}
                            parentCmp={cmp}
                            parentToChildEvent={cmpState.parentToChildEvent}
                        />
                    )}
                    {cmpState.selectedObject === "sources" && (
                        <PsSourceList
                            parentId={cmpState.sourcesId}
                            maxRecords="100"
                            queryFilter={memoizedObject}
                            childToParent={bubbleEvent}
                            parentCmp={cmp}
                            parentToChildEvent={cmpState.parentToChildEvent}
                        />
                    )}
                    {cmpState.selectedObject === "source" && (
                        // <!-- break height:100% chain -->
                        <div>
                            <div className="slds-m-bottom_medium">
                                <PsSource recordId={cmpState.sourceId} parentId={cmpState.parentId} childToParent={bubbleEvent} parentCmp={cmp} parentToChildEvent={cmpState.parentToChildEvent} />
                            </div>
                            {cmpState.sourceFilter?.sourceId && (
                                <PsConnectorList
                                    queryFilter={cmpState.sourceFilter}
                                    parentId={cmpState.sourceId}
                                    maxRecords="100"
                                    childToParent={bubbleEvent}
                                    parentCmp={cmp}
                                    parentToChildEvent={cmpState.parentToChildEvent}
                                />
                            )}
                        </div>
                    )}
                    {cmpState.selectedObject === "connector" && (
                        <div>
                            <div className="slds-m-bottom_medium">
                                <PsConnector
                                    recordId={cmpState.connectorId}
                                    parentId={cmpState.parentId}
                                    childToParent={bubbleEvent}
                                    parentCmp={cmp}
                                    parentToChildEvent={cmpState.parentToChildEvent}
                                    isLogin={isLogin}
                                />
                            </div>

                            {cmpState.connectorId && cmpState.connectorFilter?.connectorId && (
                                <PsObjectList
                                    queryFilter={cmpState.connectorFilter}
                                    parentId={cmpState.connectorId}
                                    maxRecords="100"
                                    childToParent={bubbleEvent}
                                    parentCmp={cmp}
                                    parentToChildEvent={cmpState.parentToChildEvent}
                                />
                            )}
                        </div>
                    )}
                    {cmpState.selectedObject === "object" && (
                        <div>
                            <div className="slds-m-bottom_medium">
                                <PsObject recordId={cmpState.objectId} parentId={cmpState.parentId} childToParent={bubbleEvent} parentCmp={cmp} parentToChildEvent={cmpState.parentToChildEvent} />
                            </div>
                            {cmpState.objectId && cmpState.objectFilter?.objectId && (
                                <PsFieldList
                                    queryFilter={cmpState.objectFilter}
                                    parentId={cmpState.objectId}
                                    maxRecords="100"
                                    childToParent={bubbleEvent}
                                    parentCmp={cmp}
                                    parentToChildEvent={cmpState.parentToChildEvent}
                                />
                            )}
                        </div>
                    )}
                    {cmpState.selectedObject === "field" && (
                        <PsField recordId={cmpState.fieldId} parentId={cmpState.parentId} childToParent={bubbleEvent} parentCmp={cmp} parentToChildEvent={cmpState.parentToChildEvent} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default DataManagement;
