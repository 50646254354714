import "./Profile.css";
import { useEffect, useRef, useState } from "react";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader.js";
import PsUser from "../../components/ps-user/PsUser.js";
import Record from "../../helpers/recordLayer.js";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import useAuthContext from "../../context/useAuthContext.js";
import PsNavigationTree from "../../components/ps-navigation-tree/PsNavigationTree.js";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";

function Profile() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { profile, refreshProfile, handleLogout } = useAuthContext();
    const [setupStatusVisible, setSetupStatusVisible] = useState(true);

    const [cmpState, setCmpState] = useState({
        selectedObject: "users", // selectedObject key handles what component to show in the main use container
        parentToChildEvent: {},
        // TODO This is not correct, as we are dealing with a single user and not a list of users,
        // but this is not used as we area always showing the user details. We also don't have an approach yet to show a single record by default.
        // Once we start adding more menu items we can work this out further.
        selectedItem: "overview_users_userList", // hardcoded for now, since we only have one option
    });

    const cmpWorking = useRef({});
    const cmpNavigationTree = useRef(null);
    const isFirstRender = useRef(true);

    useEffect(() => {
        try {
            cmpWorking.current = { ...cmpState };
            cmp.init();
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        try {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                return;
            }
            cmp.onPageReferenceChange();
        } catch (err) {
            console.error(err);
        }
    }, [searchParams]);

    const cmp = {
        get: (key) => {
            return cmpWorking.current[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setCmpState((prev) => ({ ...prev, [key]: value }));
        },

        init: function () {
            cmp.parsePageRef();
        },

        handleNavigationEvent: function (event) {
            // navigate to different tab
            var tab = event.tab;
            if (tab) {
                navigate("/" + tab);
                return;
            }

            // navigate to specified record
            if (["record", "grid", "tree"].includes(event.source)) {
                cmp.navigateFromEvent(event);
            }
        },

        navigateFromEvent: function (event) {
            // navigate
            const defaultSections = { root: "core", user: "core", users: "core" };
            let item = Record.itemFromEvent(event, defaultSections);
            if (event.module && !item.name.includes(event.module)) {
                item.name = event.module + item.name;
            }

            // navigate
            cmp.navigateFromName(item.name);
        },

        navigateFromName: function (name) {
            const selectedObject = cmp.get("selectedObject");
            const selectedId = selectedObject ? cmp.get(selectedObject + "Id") : null;
            const parsed = Record.parseName(name);
            const selected = searchParams.get("selected");
            if (parsed.id !== selectedId || parsed.config !== selectedObject || selected !== name) {
                let search = "?selected=" + name;
                navigate({ pathname: "/Profile", search });
            }
        },

        onPageReferenceChange: function () {
            cmp.parsePageRef();
        },

        parsePageRef: function () {
            let defaultSelected = Record.nameFromDetails("profile", "users", "userList");
            let selected = searchParams.get("selected") || defaultSelected;
            selected = selected === "__" ? defaultSelected : selected;

            const parsed = Record.parseName(selected);
            const config = parsed.config;
            const id = parsed.id;

            // each config has its own id and queryFilter variable, to prevent its component from reloading when changing the selection
            var selectedIdName = config + "Id";
            cmp.set(selectedIdName, id);

            // set the config to show _after_ all its settings have been set on the component, to prevent reloading in afterScript, as well as due to a change in filter
            cmp.set("selectedObject", config);

            // update the tree
            cmp.set("selectedItem", selected);
        },
    };

    const dispatchEvent = (event) => {
        cmp.set("parentToChildEvent", event);
    };

    const bubbleEvent = (event) => {
        let stopPropagation = false;

        if (event.type === "navigation") {
            stopPropagation = true;
            cmp.handleNavigationEvent(event);
        } else if (event.type === "logout") {
            stopPropagation = true;
            handleLogout();
        } else if (event.type === "record") {
            if (event.id === profile?.id) {
                refreshProfile();
            }
        }

        if (!stopPropagation) {
            dispatchEvent(event);
        }
    };

    return (
        <div className="profile-page-container">
            <PsNavigationHeader childToParent={bubbleEvent} loading={cmpState.loading} showClose={cmpState.view === "detail"} />
            <div className="tab-content slds-p-around_medium">
                {/* <!-- left panel view --> */}
                <div className="um-left slds-m-right_medium">
                    <article id="left" className="slds-card">
                        <div className="slds-card__header">
                            <h2 className="card-main-title-lh32 slds-card__header-title slds-text-heading_small">Profile</h2>
                        </div>
                        <div className="slds-card__body">
                            <div className="slds-p-horizontal_x-small">
                                <PsNavigationTree
                                    multiSelect={false}
                                    selected={cmpState.selectedItem}
                                    sections={["profile"]}
                                    parentCmp={cmp}
                                    ref={cmpNavigationTree}
                                    setLoading={(value) => cmp.set("navigationLoading", value)}
                                    childToParent={bubbleEvent}
                                    parentToChildEvent={cmpState.parentToChildEvent}
                                />
                            </div>
                        </div>
                    </article>
                </div>
                {/* <!-- right panel view --> */}
                <div className="um-right" style={{ overflow: setupStatusVisible ? "hidden" : "scroll" }}>
                    <PsSetupStatus title="Profile" tagLine="Manage your profile." includeStatus={["Error"]} onSetupStatusVisible={setSetupStatusVisible} />
                    {!!profile?.id && <PsUser recordId={profile.id} childToParent={bubbleEvent} parentCmp={cmp} parentToChildEvent={cmpState.parentToChildEvent} />}
                </div>
            </div>
        </div>
    );
}

export default Profile;
