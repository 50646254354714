import { useState } from "react";
import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import { RECORD_COLUMNS } from "./constants";

const PsUserList = ({ parentId, queryFilter, childToParent, parentToChildEvent }) => {
    const [mode, setMode] = useState("init");
    const [view, setView] = useState("table");
    const [orderBy, setOrderBy] = useState("name");
    const [orderDirection, setOrderDirection] = useState("asc");
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const parseResponse = (response) => {
        const results = response.filter((item) => item.type !== "System"); // IMPROVEMENT make not editable
        return results;
    };

    const handleRecordRowAction = (action, row) => {
        switch (action) {
            case "details":
                childToParent({ type: "navigation", parentId, module: "core", obj: "user", id: row.id, source: "grid" });
                break;
            default:
                break;
        }
    };

    return (
        <PsRecordGrid
            module="core"
            object="user"
            queryFilter={queryFilter}
            showTitle
            tagLine="Manage users on your Account"
            emptyLine="Create new User"
            recordLabel="User"
            recordLabelPlural="Users"
            parseResponse={parseResponse}
            parentId={parentId}
            mode={mode}
            onModeChange={(value) => setMode(value)}
            view={view}
            onViewChange={setView}
            orderBy={orderBy}
            onOrderByChange={(value) => setOrderBy(value)}
            orderDirection={orderDirection}
            onOrderDirectionChange={(value) => setOrderDirection(value)}
            loading={loading}
            onLoadingChange={(value) => setLoading(value)}
            showLoadMore
            hasMore={hasMore}
            onHasMoreChange={setHasMore}
            recordColumns={RECORD_COLUMNS}
            maxRecords={100}
            showCreate
            onRecordRowAction={handleRecordRowAction}
            childToParent={childToParent}
            parentToChildEvent={parentToChildEvent}
        />
    );
};

export default PsUserList;
