import { useState, useEffect } from "react";
import { Button, Input, Spinner } from "@salesforce/design-system-react";
import { Auth } from "aws-amplify";
import { applyInstance } from "../../../services/api";
import { GENERAL_ERROR } from "../../../constants/ToastMessages";
import "../../Pages.css";
import useToastContext from "../../../context/useToastContext";
import useAuthContext from "../../../context/useAuthContext";

const ConfirmationForm = ({ completeLogin }) => {
    const { login, setLogin } = useAuthContext();
    const { addToast } = useToastContext();
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e, item) => {
        setLogin((prev) => ({ ...prev, [item]: e.target.value }));
    };

    const handleConfirmationSubmit = async (event) => {
        try {
            event.preventDefault();

            if (!login || !login.code) {
                addToast("warning", "Missing Details", "Please fill out all fields");
                return;
            }

            setIsLoading(true);
            applyInstance(login.instance);
            const results = await Auth.confirmSignUp(login.username, login.code);
            if (!results) {
                addToast("error", "Error", "Unable to validate your confirmation code, try again later");
                return;
            }

            const cognitoUser = await Auth.signIn(login.username, login.password);
            if (!cognitoUser) {
                addToast("error", "Error", "Unable to login, try again later");
                return;
            }

            completeLogin(cognitoUser?.attributes?.sub, login.instance);
        } catch (err) {
            console.error(err);
            addToast("error", "Error", err.message || GENERAL_ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleConfirmationSubmit}>
            <p className="form-title">We have sent a confirmation code by email. Enter it below to complete your sign up</p>
            <div className="user-inputs">
                <Input type="tel" label="Code" value={login?.code || ""} onChange={(e) => handleInputChange(e, "code")} />
            </div>
            <div className="login-signup-button">
                <Button type="submit" label={<>Verify {isLoading ? <Spinner size="small" /> : null}</>} variant="brand" disabled={isLoading || !login?.code} className="login-submit-button" />
            </div>
        </form>
    );
};

export default ConfirmationForm;
