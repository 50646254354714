import { useEffect, useState, useRef } from "react";

import { Chart } from "chart.js";
import "chartjs-chart-matrix";
import * as ChartGeo from "chartjs-chart-geo";
import "chartjs-plugin-stacked100";
import { Icon } from "@salesforce/design-system-react";
import { Spinner } from "@salesforce/design-system-react";

import Record from "../../helpers/recordLayer.js";
import "./PsPatternChart.css";
import { CARTESIAN_CHART_TYPES, CIRCULAR_CHART_TYPES, GEO_CHART_TYPES } from "../../constants/index.js";
import DataTable from "./components/DataTable.js";
import { parseOverrides, coloredText, toastErrorMessage } from "../../utils/index.js";
import PsErrorBoundary from "../ps-error-boundary/PsErrorBoundary.js";
import HeaderActionsIcons from "./components/HeaderActionsIcons";
import useToastContext from "../../context/useToastContext.tsx";

function PsPatternChart({ record, view, parentToChildEvent, childToParent, onPlotResult, onPatternChange, onSelectInput, onLoading, externalFields }) {
    const [processed, setProcessed] = useState(null);
    const [chartAxes, setChartAxes] = useState({});
    const [data, setData] = useState(null);
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState("init");
    const [showStaticTooltip, setShowStaticTooltip] = useState(false);
    const [errorType, setErrorType] = useState("init");
    const [plotType, setPlotType] = useState(null);

    const cmpChart = useRef();
    const chartRef = useRef(null);
    const xAxisZoneRef = useRef(null);
    const yAxisZoneRef = useRef(null);
    const legendZoneRef = useRef(null);
    const xAxisTooltipRef = useRef(null);
    const yAxisTooltipRef = useRef(null);
    const legendTooltipRef = useRef(null);

    const available = processed || record; // some properties may already be available from the input record
    const isPreview = !!view;
    const isEdit = view === "edit";

    const tooltipRefs = {
        xAxis: xAxisTooltipRef,
        yAxis: yAxisTooltipRef,
        legend: legendTooltipRef,
    };

    const zoneRefs = {
        xAxis: xAxisZoneRef,
        yAxis: yAxisZoneRef,
        legend: legendZoneRef,
    };

    // global toast
    const { addToast } = useToastContext();

    useEffect(() => {
        if (!parentToChildEvent) {
            return;
        }
        handleEvent(parentToChildEvent);
    }, [parentToChildEvent]);

    useEffect(() => {
        getRecord(record);
    }, [record]);

    useEffect(() => {
        onLoading && onLoading(loading);
    }, [loading]);

    function dispatchEvent(event) {
        childToParent(event);
    }

    function bubbleEvent(event) {
        let stopPropagation = false;

        if (!stopPropagation) {
            childToParent(event);
        }
    }

    function handleEvent() {}

    const handleViewDetails = () => {
        if (isPreview && !isEdit) {
            dispatchEvent({ type: "dataCompEvent", action: "viewDetails", pattern: available });
        }
    };

    function parseResponse(response) {
        return response.map(
            ({ id, floatingId, custom, name, type, version, treeHash, options, container, key, inputs, relevance, relevanceIntrinsic, description, parameters, computed, overridden }) => ({
                id,
                floatingId,
                custom,
                name,
                type,
                version,
                treeHash,
                options,
                namePlain: Record.removeMarkup(name),
                nameMarkup: Record.markupToHtml(name),
                title: container?.source?.name + ": " + container?.name,
                container,
                key, // TODO: remove when no longer needed to save a Pattern
                inputs,
                relevance: relevanceIntrinsic != null ? relevanceIntrinsic : relevance,
                relevanceType: relevanceIntrinsic != null ? "Modeled" : relevance != null ? "Expected" : null,
                showFilters: type !== "SQL",
                parameters,
                description,
                hasActiveFilters: computed?.hasActiveFilters,
                hasActiveMaps: computed?.hasActiveMaps,
                droppedOutliers: computed?.droppedOutliers,
                droppedMissing: computed?.droppedMissing,
                overridden,
            })
        );
    }

    function handleStaticAxisZoneMouse(value) {
        if (isEdit) {
            return;
        }
        setShowStaticTooltip(value);
    }

    function handleAxisZoneMouse(event, show) {
        // don't show tooltip in edit mode, but keep the tooltip zone, so that it is repositioned with any resizing
        if (isEdit) {
            return;
        }

        if (show) {
            const tooltip = tooltipRefs[event.target.id]?.current;
            if (tooltip && tooltip?.style?.visibility !== "visible") {
                tooltip.style.visibility = "visible";
            }
        } else {
            const tooltip = tooltipRefs[event.target.id]?.current;
            if (tooltip && tooltip?.style?.visibility !== "hidden") {
                tooltip.style.visibility = "hidden";
            }
        }
    }

    function handleZoneClick(event) {
        const axis = chartAxes[event.target.id];
        onSelectInput && onSelectInput(axis?.inputId);
    }

    function handleTableHeaderClick(selected) {
        onSelectInput && onSelectInput(selected?.name);
    }

    function setAxisLabelPosition(chart, axis, element) {
        const ctx = chart.ctx;
        if (!chart || !chart.ctx) {
            return;
        }

        const zone = zoneRefs[axis]?.current;
        const tooltip = tooltipRefs[axis]?.current;

        let text, section, options, padding, useTitle, zoneWidth, zoneHeight;
        if (element === "legend") {
            section = chart?.legend;
            options = section?.options;
            text = options?.title?.text;
            padding = options?.labels?.padding || {};
        } else if (element === "legend-item") {
            section = chart?.legend;
            options = section?.options;
            text = section?.legendItems[0]?.text + "            "; // bit extra space for the legend color box
            padding = options?.labels?.padding || {};
            useTitle = true;
        } else if (element === "title") {
            section = chart?.titleBlock;
            options = section?.options;
            text = options?.text;
            padding = options?.padding || {};
            useTitle = true;
        } else {
            section = chart?.scales[element];
            options = section?.options?.scaleLabel;
            text = options?.labelString;
            padding = options?.padding || {};
            useTitle = true;
        }

        const position = section?.position;
        const zoneStyle = zone?.style || {};
        const tooltipStyle = tooltip?.style || {};

        if (options?.display) {
            zoneStyle.visibility = "visible";
        } else {
            zoneStyle.visibility = "hidden";
            tooltipStyle.visibility = "hidden";
            return;
        }

        const defaultFontSize = 12;
        const minimumZoneWidth = 50;
        const nubbinSize = 6;
        const zonePadding = 5;
        const fontSize = options?.fontSize || Chart.defaults?.global?.defaultFontSize || defaultFontSize;
        const textSize = ctx?.measureText(text);
        const tPadding = (typeof padding === "number" ? padding : padding?.left + padding?.right) || 0;
        if (useTitle) {
            zoneWidth = Math.max(textSize?.width, minimumZoneWidth) + tPadding;
            zoneHeight = Math.max(typeof text === "string" ? fontSize : text?.length * fontSize, defaultFontSize);
            if (["left", "right"].includes(position)) {
                [zoneWidth, zoneHeight] = [zoneHeight, zoneWidth]; // vertically positioned text
                padding = typeof padding === "number" ? padding : { top: padding.right, bottom: padding.left, left: padding.top, right: padding.bottom };
            }
        } else {
            zoneWidth = section?.options?.labels?.boxWidth; // cover last column or row of leged boxes, so labels remain clickable
            zoneHeight = section?.columnHeights ? Math.max(section?.columnHeights) : defaultFontSize;
        }

        ["tooltip-nubbin_top", "tooltip-nubbin_bottom", "tooltip-nubbin_left", "tooltip-nubbin_right"].forEach((className) => {
            tooltip?.classList?.remove(className);
        });

        ["top", "bottom", "left", "right"].forEach((prop) => {
            tooltipStyle[prop] = "";
            zoneStyle[prop] = "";
        });

        zoneStyle.width = zoneWidth + 2 * zonePadding + "px";
        zoneStyle.height = zoneHeight + 2 * zonePadding + "px";

        const getPadding = (dir) => (typeof padding === "number" ? padding : padding?.[dir] || 0);
        const center = (start, end, size) => (start + end) / 2 - (size || 0) / 2;

        if (position === "bottom") {
            zoneStyle.left = center(section.left, section.right, zoneWidth) - zonePadding + "px";
            zoneStyle.top = section.bottom - zoneHeight - zonePadding - getPadding("bottom") + "px";
            tooltipStyle.bottom = chart.height - section.bottom + zoneHeight + getPadding("bottom") + nubbinSize + "px";
            tooltipStyle.left = center(section.left, section.right, tooltip?.offsetWidth) + "px";
            tooltip?.classList?.add("tooltip-nubbin_bottom");
        } else if (position === "top") {
            zoneStyle.left = center(section.left, section.right, zoneWidth) - zonePadding + "px";
            zoneStyle.top = section.top - zonePadding + getPadding("top") + "px";
            tooltipStyle.top = section.top + zoneHeight + getPadding("top") + nubbinSize + "px";
            tooltipStyle.left = center(section.left, section.right, tooltip?.offsetWidth) + "px"; // set position rather than calculate, so that the tooltip width is reduced if it doesn't fit
            tooltip?.classList?.add("tooltip-nubbin_top");
        } else if (position === "left") {
            zoneStyle.left = section.left - zonePadding + getPadding("right") + "px";
            zoneStyle.top = center(section.top, section.bottom, zoneHeight) - zonePadding + "px";
            tooltipStyle.top = center(section.top, section.bottom) + "px";
            tooltipStyle.left = section.left + zoneWidth + getPadding("right") + nubbinSize + "px";
            tooltipStyle.transform = "translateY(-50%)"; // translate rather than set position, because changing width may wrap lines and change height
            tooltip?.classList?.add("tooltip-nubbin_left");
        } else if (position === "right") {
            zoneStyle.left = section.left - zonePadding + getPadding("left") + "px";
            zoneStyle.top = center(section.top, section.bottom, zoneHeight) - zonePadding + "px";
            tooltipStyle.top = center(section.top, section.bottom) + "px";
            tooltipStyle.right = chart.width - section.left - getPadding("left") + nubbinSize + "px";
            tooltipStyle.transform = "translateY(-50%)";
            tooltip?.classList?.add("tooltip-nubbin_right");
        }
    }

    const setColumnWidths = (columns) => {
        if (!columns?.length || !available?.inputs?.length) {
            return;
        }
        var idInputs = Object.fromEntries(available.inputs.map((item) => [item.id || item.floatingId, item]));
        columns.forEach((column) => {
            let input = idInputs[column.inputId];
            // NOTE: each input Node can only have a single width; when a data table is broken down by an XND slot, all the width will be stored on the VAL node, and after reloading the pattern all columns for that VAL node will have the width of the last label value column.
            if (input) {
                input.options = { ...input.options, columnWidth: column.width };
                input.parameters = { ...input.parameters, columnWidth: column.width }; // TODO: remove once backend has moved over to use options instead of parameters
            }
        });
        onPatternChange && onPatternChange(available);
    };

    const onColumnReorder = (columns) => {
        var idInputs = Object.fromEntries(available.inputs.map((item) => [item.id || item.floatingId, item]));
        columns.forEach((column, index) => {
            let input = idInputs[column.inputId];
            if (input) {
                input.nodeOrder = index;
            }
        });
        onPatternChange && onPatternChange(available);
    };

    const resetPlot = () => {
        setPlotType(null);
        setChartAxes({});
        setData(null);
        clearChart();
        clearData();
    };

    const setError = (type) => {
        resetPlot();
        setErrorType(type);
        setMode("error");
        setLoading(false);
    };

    const getRecord = (pattern) => {
        if (!pattern) {
            if (processed) {
                resetPlot();
            }
            return;
        }

        // make first-level copy, so changing attributes doesn't change the original, potentially causing unexpected updates in other components
        pattern = Object.assign({}, pattern);
        setExternalFields(pattern);

        // the plot API uses the stored record instead of the specified 'inputs' if an 'id' is specified, so remove the 'id' if inputs are specified already
        const patternId = pattern.id;

        var onSuccess = function (response) {
            const plot = response[0];

            try {
                // to make the plot responsive, set the chart options
                // TODO: set this in backend instead
                if (plot?.chart?.options) {
                    plot.chart.options.responsive = true;
                    plot.chart.options.maintainAspectRatio = false;
                }

                // plot API doesn't return a patternId, so set the original patternId back, so it can be used for navigation
                plot.pattern.id = patternId;

                // parse response and overrides
                let mapped = parseResponse([plot.pattern]);
                mapped = parseOverrides(mapped, ["name", "description"]);
                const newPattern = mapped[0];

                // let parent component know about the new pattern and plot options
                onPlotResult && onPlotResult(newPattern, plot.options, plot.data);
                setProcessed(newPattern);

                if (plot.chart && plot.options?.plotType) {
                    processAxes(plot.chart);
                    processChart(plot.chart);
                    setPlotType(plot.options?.plotType);
                } else {
                    setError("EmptyPlotError");
                }
            } catch (err) {
                console.error(err);
                setError();
            }
        };

        var onError = function (response) {
            onPlotResult && onPlotResult();
            setProcessed(null);
            addToast("error", "Error", toastErrorMessage(response));
            setError(response.type);
        };

        if (pattern.inputs) {
            pattern.id = null;
        }

        setLoading(true);
        Record.getRecord("plot", "pattern", "", "", JSON.stringify(pattern), "PUT", onSuccess, onError);
        return;
        // }
    };

    const setExternalFields = (pattern) => {
        // TODO: this won't work, because we don't always have the full pattern before sending to the plot API, e.g., we may just have a pattern.id. Instead use the new placeholder functionality to put the external values into the filters
        var nodes = Record.flatten(pattern, "inputs");
        nodes.forEach((node) => {
            if (node.filter && node.filter.settings && node.filter.settings.alias === "PresetText") {
                if (node.filter.settings.preset === "EmbeddedRecordValue") {
                    let embeddedRecordFieldValue = externalFields && externalFields["embeddedRecordFieldValue"];
                    node.filter.settings.values = embeddedRecordFieldValue ? [embeddedRecordFieldValue] : null;
                } else if (node.filter.settings.preset === "LoggedInUserValue") {
                    let userRecordFieldValue = externalFields && externalFields["userRecordFieldValue"];
                    node.filter.settings.values = userRecordFieldValue ? [userRecordFieldValue] : null;
                } else if (node.filter.settings.preset === "LoggedInUserId") {
                    let userId = externalFields && externalFields["userId"];
                    node.filter.settings.values = userId ? [userId] : null;
                }
            }
        });
    };

    const processAxes = (chart) => {
        let details = { plotType: chart.type };

        if (CARTESIAN_CHART_TYPES.includes(chart.type) || CIRCULAR_CHART_TYPES.includes(chart.type)) {
            const hasLegend = chart?.options?.legend?.display;
            const hasTitle = chart?.options?.title?.display;
            const xAxis = chart?.options?.scales?.xAxes[0]?.scaleLabel;
            const yAxis = chart?.options?.scales?.yAxes[0]?.scaleLabel;
            const legend = hasLegend ? chart?.options?.legend?.title : hasTitle ? chart?.options?.title : null;
            details.xAxis = { name: "xAxis", labelString: xAxis?.labelString || "", fulltext: xAxis?.fulltext || "", inputId: xAxis?.inputId || "", display: chart.type !== "radar" };
            details.yAxis = { name: "yAxis", labelString: yAxis?.labelString || "", fulltext: yAxis?.fulltext || "", inputId: yAxis?.inputId || "", display: true };

            if (hasLegend || hasTitle) {
                details.legend = { name: "legend", labelString: legend?.text || "", fulltext: legend?.fulltext || "", inputId: legend?.inputId || "", display: true };
            }

            // if (CIRCULAR_CHART_TYPES.includes(chart.type)) {
            //     if (chart.data.labels) {
            //         details.xAxis.labelCount = chart.data.labels.length;
            //         let maxLabelWidth = -1;
            //         chart.data.labels.forEach((label) => {
            //             let width = getTextWidth(label, "Arial");
            //             if (width > maxLabelWidth) maxLabelWidth = width;
            //         });
            //         details.xAxis.maxLabelWidth = maxLabelWidth;
            //     }
            // }
        } else if (GEO_CHART_TYPES.includes(chart.type)) {
            const title = chart?.options?.title;
            const geoLegend = chart.options.geo?.colorScale?.legend?.title;

            if (title) {
                details.xAxis = {
                    name: "xAxis",
                    labelString: Array.isArray(title.text) ? title.text.join("\n") : title.text || "",
                    fulltext: title.fulltext || "",
                    inputId: title.inputId || "",
                    display: true,
                };
            }

            if (geoLegend) {
                details.legend = { name: "legend", labelString: geoLegend.text || "", fulltext: geoLegend.fulltext || "", inputId: geoLegend.inputId || "", display: true };
            }
        }
        setChartAxes(details);
    };

    function color(colorString, alpha = 1) {
        const rgbValues = colorString.match(/\d+/g);

        if (!rgbValues || rgbValues.length < 3) {
            throw new Error("Invalid color string format. Please provide a valid RGB color string.");
        }

        const red = parseInt(rgbValues[0]);
        const green = parseInt(rgbValues[1]);
        const blue = parseInt(rgbValues[2]);

        if (isNaN(red) || isNaN(green) || isNaN(blue)) {
            throw new Error("Invalid color values. Please provide valid RGB values.");
        }

        const validAlpha = Math.min(1, Math.max(0, parseFloat(alpha)));

        return `rgba(${red}, ${green}, ${blue}, ${validAlpha})`;
    }

    /* Prepare chart input - Some charts require functions within the chart object.
As these are not included in the plot data from Deep Sigma, the functions are added here. */
    const processChart = (chart) => {
        if (isPreview && chart?.options?.legend) {
            chart.options.legend.onClick = null;
        }

        chart.plugins = [
            {
                afterLayout: (chartObject) => {
                    setChartAxisInput(chart, chartObject);
                },
            },
        ];

        if (chart.type === "pie" && chart.options?.circumference === 0) {
            drawContent(chart?.data?.labels?.[0]);
            return;
        } else if (chart.type === "table" || chart.type === "data") {
            var data = chart.data;
            var levels = (data.levels || []).map((v) => ({
                label: v.name,
                name: v.inputId,
                checked: false,
            }));
            data.columns.forEach((v) =>
                Object.assign(v, {
                    fieldName: v.column,
                    label: v.value || v.name,
                    //hideDefaultActions: true,
                    //width: isPreview ? null : v.initialWidth,
                    width: v.initialWidth,
                    actions: [{ label: v.name, name: v.inputId, checked: false }].concat(v.role === "values" ? levels : []),
                })
            );

            drawTable({ key: data.key, columns: data.columns, records: data.datasets[0] });
            return;
        } else if (chart.type === "scatter") {
            //code below can be used to add meaningful tooltips to scatter dots
            if (chart.additionalData != null) {
                //tooltips for scatter chart
                chart.options.tooltips.callbacks = {
                    //tooltipItems vs item!!
                    title: function (tooltipItems, data) {
                        var index = tooltipItems[0].index;
                        var datasetIndex = tooltipItems[0].datasetIndex;
                        var dataset = data.datasets[datasetIndex];
                        var datasetItem = dataset.data[index];
                        var dot = chart.additionalData[datasetIndex].data[index];

                        return dot.name;
                    },
                    beforeLabel: function (tooltipItem, data) {
                        return "Xlabel : " + tooltipItem.xLabel;
                    },
                    label: function (tooltipItem, data) {
                        return data.datasets[tooltipItem.datasetIndex].label + ": " + tooltipItem.yLabel;
                    },
                    afterLabel: function (tooltipItem, data) {
                        return "***** Text below label *****";
                    },
                };
            }
        } else if (chart.type === "matrix") {
            if (chart.additionalData != null) {
                chart.data.datasets[0].borderColor = function (ctx) {
                    var value = ctx.dataset.data[ctx.dataIndex].v;
                    var alpha = value / value || 0;
                    return color("rgb(128, 128, 128)", alpha);
                };

                chart.data.datasets[0].backgroundColor = function (ctx) {
                    var value = ctx.dataset.data[ctx.dataIndex].v;
                    var alpha = 0.1 + (0.9 * (value - chart.additionalData.min_value)) / chart.additionalData.ran_value || 0;
                    return color(chart.additionalData.rgbColor, alpha);
                };

                chart.data.datasets[0].width = function (ctx) {
                    var a = ctx.chart.chartArea;
                    return (a.right - a.left) / (chart.additionalData.nrows * 1.1);
                };
                chart.data.datasets[0].height = function (ctx) {
                    var a = ctx.chart.chartArea;
                    return (a.bottom - a.top) / (chart.additionalData.ncols * 1.1);
                };
                chart.options.tooltips.callbacks = {
                    title: function (item) {
                        var xLabelIndex = item[0].xLabel - 1;
                        var xLabel = chart?.additionalData?.main_labels?.[xLabelIndex];
                        return xLabel || "";
                    },
                    label: function (item, data) {
                        var yLabelIndex = item.yLabel - 1;
                        var yLabel = chart?.additionalData?.legend_labels?.[yLabelIndex];
                        var v = data.datasets[item.datasetIndex].data[item.index];
                        return [`${yLabel}: ${v.v}`];
                    },
                };
                chart.options.scales.xAxes[0].ticks.callback = function (value, index, values) {
                    return chart.additionalData.main_labels[value - 1];
                };
                chart.options.scales.xAxes[0].ticks.afterBuildTicks = function (scale, ticks) {
                    return ticks.slice(1, chart.additionalData.nrows + 1);
                };
                chart.options.scales.yAxes[0].ticks.callback = function (value, index, values) {
                    return chart.additionalData.legend_labels[value - 1];
                };
                chart.options.scales.yAxes[0].ticks.afterBuildTicks = function (scale, ticks) {
                    return ticks.slice(1, chart.additionalData.ncols + 1);
                };
            }
        } else if (chart.type === "choropleth") {
            var dataset = chart.data.datasets[0];
            var outline = dataset.outline; //location name
            // TODO: if this runs a 2nd time, dataset.outline has already been replaced by geodata, so it no longer contains a country name. Just load based on available data?
            //       Why is the chart/canvas disappearing while these objects remain unchanged?

            let pathInput = "/assets/maps/chart_geo_";
            var countrySettings = {
                "united kingdom": ["gbreer", ""],
                brazil: ["bra", "BR."],
                usa: ["usa", ""],
            }; // IMPROVEMENT: specify in constants file
            var countrySetting = countrySettings[outline] || "";
            var countryCode = countrySetting[0];

            if (countrySetting) {
                if (countryCode === "world") {
                    pathInput += "world";
                } else {
                    pathInput += "countries/" + countryCode + ".topo.json";
                }

                fetch(pathInput)
                    .then((response) => response.json())
                    .then((responseParsed) => {
                        // var responseParsed = JSON.parse(req.response);
                        var geoData = ChartGeo.topojson.feature(responseParsed, responseParsed.objects[countryCode]).features;
                        // create lookup tables
                        var geoLookup = geoData.reduce((obj, item) => {
                            obj[item.id] = item;
                            return obj;
                        }, {});
                        var geoNames = geoData.reduce((obj, item) => {
                            obj[item.id] = item ? item.properties.name : null;
                            return obj;
                        }, {});

                        // map outlines and labels
                        dataset.outline = geoData;
                        //dataset.data contains the items returned by the backend, they have feature (e.g. state code) and value (value to display on map)
                        dataset.data.forEach((item) => {
                            item.feature = geoLookup[countrySetting[1] + item.feature.toUpperCase()];
                        });
                        chart.data.labels = chart.data.labels.map((item) => geoNames[countrySetting[1] + item.toUpperCase()] || item);
                        chart.options.responsive = true;

                        drawChart(chart);
                    });

                return;
            }
        }

        drawChart(chart);
    };

    //Store information on axis range that is only available in afterDraw function. This is used later to set the axis zones and tooltips
    const setChartAxisInput = (chart, chartObject) => {
        const chartType = chartObject?.config.type;
        if (GEO_CHART_TYPES.includes(chartType)) {
            setAxisLabelPosition(chartObject, "xAxis", "title");
            setAxisLabelPosition(chartObject, "yAxis", "none");
            setAxisLabelPosition(chartObject, "legend", "legend-item");
        } else if (CIRCULAR_CHART_TYPES.includes(chartType)) {
            setAxisLabelPosition(chartObject, "xAxis", "legend");
            setAxisLabelPosition(chartObject, "yAxis", "title");
            setAxisLabelPosition(chartObject, "legend", "none");
        } else if (["scatter"].includes(chartType)) {
            setAxisLabelPosition(chartObject, "xAxis", "x-axis-1");
            setAxisLabelPosition(chartObject, "yAxis", "y-axis-1");
            setAxisLabelPosition(chartObject, "legend", "title");
        } else {
            setAxisLabelPosition(chartObject, "xAxis", "x-axis-0");
            setAxisLabelPosition(chartObject, "yAxis", "y-axis-0");
            setAxisLabelPosition(chartObject, "legend", "title");
        }
    };

    const clearContent = () => {
        setContent(null);
    };

    const drawContent = (value) => {
        clearData();
        clearChart();
        setContent(value);
        setMode("view");
        setLoading(false);
    };

    const clearData = () => {
        setData(null);
    };

    const drawTable = (data) => {
        clearContent();
        clearChart();
        setData(data);
        setMode("view");
        setLoading(false);
    };

    const clearChart = () => {
        try {
            if (chartRef && cmpChart.current) {
                cmpChart.current.destroy();
            }
        } catch (err) {
            console.error(err);
        }
    };

    const drawChart = (chart) => {
        // render chart after react is done updating and rendering other components, because:
        // 1. rendering in the same thread may freeze the main thread and hence the page
        // 2. chartRef is not yet rendered when switching between table and plot
        // 3. tooltip divs need to be rendered first so we can use their rendered size
        var timer = setTimeout(() => {
            renderChart(chart);
        }, 0);
    };

    const renderChart = (chart) => {
        clearContent();
        clearData();
        clearChart();
        try {
            const context = chartRef?.current?.getContext("2d");
            if (context) {
                cmpChart.current = new Chart(context, chart);
            }
        } catch (err) {
            console.error(err);
        }
        setMode("view");
        setLoading(false);
    };

    return (
        <>
            {
                //props.view === "drag" || props.hasLayout ? (
                // true ? (
                <>
                    <div className={`chart-container ${isPreview ? "slds-card slds-card_boundary" : ""}`}>
                        {isPreview && (
                            <div className="slds-card__header chart-header">
                                <div className="chart-header-content slds-m-bottom_x-small">
                                    <h2 className="slds-text-heading_small slds-truncate" title={available.title}>
                                        {available.title}
                                    </h2>
                                    {isEdit ? (
                                        <div></div>
                                    ) : (
                                        <HeaderActionsIcons
                                            pattern={available}
                                            showEdit
                                            onEdit={handleViewDetails}
                                            onLoading={setLoading}
                                            childToParent={bubbleEvent}
                                            parentToChildEvent={parentToChildEvent}
                                        />
                                    )}
                                </div>
                                <div className="slds-truncate slds-text-title" title={available.namePlain}>
                                    {coloredText(available.nameMarkup || "")}
                                </div>
                            </div>
                        )}
                        <div className={isPreview ? "chart-body-grid" : "chart-body-detail"} onClick={isPreview && !isEdit ? handleViewDetails : null}>
                            <div className="chart-content">
                                {isPreview && loading && <Spinner assistiveText={{ label: "Loading" }} />}
                                {mode === "error" && (
                                    <div className="slds-illustration slds-illustration_large" style={{ width: "100%" }} aria-hidden="true">
                                        <img src="/assets/images/illustrations/Desert.svg" className="slds-illustration__svg" alt="" />
                                        <div className="slds-text-color_weak">
                                            {errorType === "EmptyPlotError" && (
                                                <div>
                                                    <h4 className="slds-text-heading_medium">Insufficient data</h4>
                                                    <br />
                                                    Refine search criteria or adjust filters.
                                                </div>
                                            )}
                                            {errorType !== "EmptyPlotError" && <h4 className="slds-text-heading_medium">Pattern could not be loaded</h4>}
                                        </div>
                                    </div>
                                )}
                                {["indicator"].includes(plotType) ? (
                                    // can't figure out how to get this centered and scaling properly without using a lot of 100% width and height
                                    <div className="slds-grid slds-grid_vertical slds-p-around_medium fill-parent">
                                        <div
                                            id="yAxis"
                                            className="slds-p-around_xx-small slds-text-body_small slds-text-color_weak"
                                            style={{ margin: "auto", cursor: "pointer" }}
                                            onMouseEnter={() => handleStaticAxisZoneMouse(true)}
                                            onMouseLeave={() => handleStaticAxisZoneMouse(false)}
                                            onClick={(e) => handleZoneClick(e)}
                                        >
                                            {chartAxes?.yAxis?.labelString}
                                        </div>
                                        <span ref={yAxisTooltipRef} className="tooltip static-top tooltip-nubbin_top" style={{ visibility: showStaticTooltip ? "visible" : "hidden" }}>
                                            <b>Axis: </b> {chartAxes?.yAxis?.labelString}
                                            <br />
                                            <b>Origin: </b> {chartAxes?.yAxis?.fulltext}
                                        </span>
                                        <div className="slds-align_absolute-center fill-parent" style={{ overflow: "hidden" }}>
                                            <svg viewBox="0 0 80 40" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
                                                <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" style={{ fontSize: "12px", fill: "#111" }}>
                                                    {content}
                                                </text>
                                            </svg>
                                        </div>
                                    </div>
                                ) : ["data", "table"].includes(plotType) ? (
                                    <div className="slds-p-horizontal_medium slds-p-top_x-small chart-table-container">
                                        <DataTable
                                            columns={data?.columns}
                                            records={data?.records}
                                            enableHeaderActions={!isPreview}
                                            onHeaderAction={handleTableHeaderClick}
                                            enableColumnResize={!isPreview}
                                            onColumResize={setColumnWidths}
                                            enableColumnReordering={!isPreview && processed?.type === "Table" && data?.columns?.length > 1}
                                            onColumnReorder={onColumnReorder}
                                        />
                                    </div>
                                ) : !!plotType ? (
                                    <>
                                        <canvas ref={chartRef}></canvas>

                                        <span
                                            ref={xAxisZoneRef}
                                            id="xAxis"
                                            className="axisZone"
                                            onMouseEnter={(e) => handleAxisZoneMouse(e, true)}
                                            onMouseLeave={(e) => handleAxisZoneMouse(e, false)}
                                            onClick={(e) => handleZoneClick(e)}
                                        >
                                            xAxisZone
                                        </span>
                                        <span
                                            ref={yAxisZoneRef}
                                            id="yAxis"
                                            className="axisZone"
                                            onMouseEnter={(e) => handleAxisZoneMouse(e, true)}
                                            onMouseLeave={(e) => handleAxisZoneMouse(e, false)}
                                            onClick={(e) => handleZoneClick(e)}
                                        >
                                            yAxisZone
                                        </span>
                                        <span
                                            ref={legendZoneRef}
                                            id="legend"
                                            className="axisZone"
                                            onMouseEnter={(e) => handleAxisZoneMouse(e, true)}
                                            onMouseLeave={(e) => handleAxisZoneMouse(e, false)}
                                            onClick={(e) => handleZoneClick(e)}
                                        >
                                            legendZone
                                        </span>

                                        <span ref={xAxisTooltipRef} className="tooltip">
                                            <b>Axis: </b> {chartAxes?.xAxis?.labelString}
                                            <br />
                                            <b>Origin: </b> {chartAxes?.xAxis?.fulltext}
                                        </span>

                                        <span ref={yAxisTooltipRef} className="tooltip">
                                            <b>Axis: </b> {chartAxes?.yAxis?.labelString}
                                            <br />
                                            <b>Origin: </b> {chartAxes?.yAxis?.fulltext}
                                        </span>

                                        <span ref={legendTooltipRef} className="tooltip">
                                            <b>Axis: </b> {chartAxes?.legend?.labelString}
                                            <br />
                                            <b>Origin: </b> {chartAxes?.legend?.fulltext}
                                        </span>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    {available?.droppedMissing && (
                        <div className="slds-col">
                            <Icon assistiveText={{ label: "Dropped missing data" }} category="utility" name="ban" size="x-small" title="Dropped missing data" />
                        </div>
                    )}
                </>
            }
        </>
    );
}

// TODO: this doesn't seem to recover from an error if the component is updated later from clicking another pattern somewhere else on the page
export default (props) => (
    <PsErrorBoundary
        fallback={
            <div className="ps-pattern-chart-error-boundary">
                <div className="slds-var-p-around_medium slds-illustration slds-illustration_large" aria-hidden="true">
                    <img src="/assets/images/illustrations/Desert.svg" className="slds-illustration__svg" alt="" />
                    <div className="slds-text-color_weak">
                        <h4 className="slds-text-heading_medium">Pattern could not be loaded</h4>
                    </div>
                </div>
            </div>
        }
    >
        <PsPatternChart {...props} />
    </PsErrorBoundary>
);
