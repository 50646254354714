import { createContext, useContext, useEffect, useState } from "react";
import Record from "../helpers/recordLayer";
import { Auth } from "aws-amplify";
import RecordConstants from "../constants/RecordConstants";
import { applyInstance, currentInstance } from "../services/api";
import { ACCOUNT_SETUP } from "../constants";

const AuthContext = createContext(null); //type  AuthContextReturnType | null

const useAuthContextProvider = () => {
    const { STATUS_PROGRESS_LIST } = ACCOUNT_SETUP;
    const [isInitializing, setIsInitializing] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [login, setLogin] = useState(null);
    const [userId, setUserId] = useState(null);
    const [instance, setInstance] = useState(null);
    const [profile, setProfile] = useState(null);
    const [account, setAccount] = useState(null);

    useEffect(() => {
        initialize();

        // every 5 minutes, check if user session is still valid
        const sessionCheckInterval = setInterval(checkAuth, 300000);
        return () => {
            clearInterval(sessionCheckInterval);
        };
    }, []);

    useEffect(() => {
        if (userId) {
            getUser(userId);
            getAccount();
        } else {
            setProfile(null);
            setAccount(null);
        }
    }, [userId]);

    const initialize = async () => {
        const instance = currentInstance();
        setInstance(instance);
        applyInstance(instance); // # TODO: instead of saving instance number, save its name
        if (instance) {
            const currentId = await checkAuth();
            if (currentId) {
                setIsAuthenticated(true);
                setUserId(currentId);
            }
        }
        setIsInitializing(false);
    };

    const clearLoginSecrets = () => {
        setLogin((prev) => ({ ...prev, password: undefined, confirmPassword: undefined, code: undefined }));
    };

    const getUser = (userId) => {
        if (!userId) {
            setProfile(null);
            return;
        }

        const onSuccess = (response) => {
            if (response.length) {
                const record = response[0];
                const instanceNumberCount = parseInt(process.env.REACT_APP_COGNITO_REGION_NUMBER_COUNT, 10);
                const instanceLabel = process.env[`REACT_APP_COGNITO_REGION_LABEL_${instance}`];
                setProfile({
                    ...record,
                    ...(instanceNumberCount > 1 ? { instanceLabel } : {}),
                });
            } else {
                setProfile(null);
            }
            setIsLoading(false);
        };

        const onError = () => {
            setProfile(null);
            setIsLoading(false);
        };

        setIsLoading(true);
        Record.getRecord("core", "user", userId, {}, "", "GET", onSuccess, onError);
    };

    const getAccount = () => {
        const onSuccess = (response) => {
            if (response.length) {
                const record = response[0];
                const accountSetupProgressError = STATUS_PROGRESS_LIST[0];
                const accountSetupStatus = record.setupStatus || "Authenticated";
                const setupProgress = STATUS_PROGRESS_LIST.find((item) => item.setupStatus === accountSetupStatus) || accountSetupProgressError;
                setAccount({ ...record, setupProgress });
            } else {
                setAccount(null);
            }
            setIsLoading(false);
        };

        const onError = () => {
            setAccount(null);
            setIsLoading(false);
        };

        setIsLoading(true);
        Record.getRecords("core", "account", {}, onSuccess, onError);
    };

    const hasGenerativeAIAccess = (requested) => {
        if (!account) {
            return;
        }
        const levels = RecordConstants.ACCOUNT_SETTINGS_GENERATIVE_AI;
        const requestedIndex = requested?.index == null ? -1 : requested.index;
        const accountLimitIndex = levels[account?.limits?.generativeAI]?.index;
        const accountSettingsIndex = levels[account?.settings?.generativeAI]?.index;
        const allowAccountLimits = requestedIndex <= (accountLimitIndex || 0);
        const allowAccountSettings = accountSettingsIndex == null || requestedIndex <= accountSettingsIndex;

        return allowAccountLimits && allowAccountSettings;
    };

    const refreshProfile = () => {
        getUser(userId);
    };

    const refreshAccount = () => {
        getAccount();
    };

    const handleLogin = async (userId, instance) => {
        // note that the Amplify Auth instance has already been applied during the login process
        setIsAuthenticated(true);
        setInstance(instance); // instance is used on onSuccess of getUser, and its new state should be available by then
        setUserId(userId);
        setLogin(null);
    };

    const handleLogout = async () => {
        await Auth.signOut();
        setUserId(null);
        setIsAuthenticated(false);
    };

    const checkAuth = async () => {
        let currentId;
        try {
            const session = await Auth.currentSession();
            currentId = session?.idToken?.payload?.sub;
        } catch (err) {
            if (err !== "No current user") {
                console.error(err);
            }
        }

        if (userId && !currentId) {
            await handleLogout();
        }

        return currentId;
    };

    return {
        isAuthenticated,
        isInitializing,
        isLoading,
        profile,
        account,
        login,
        setLogin,
        clearLoginSecrets,
        handleLogin,
        handleLogout,
        refreshProfile,
        refreshAccount,
        hasGenerativeAIAccess,
    };
};

export const AuthContextProvider = ({ children }) => {
    const value = useAuthContextProvider();
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (!context) throw new Error("You can't use useAuthContext() outside of <AuthContextProvider />");
    return context;
};

export default useAuthContext;
