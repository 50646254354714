import { forwardRef, useState, useEffect, useRef, useImperativeHandle } from "react";

import Record from "../../helpers/recordLayer";
import { allSettings } from "./components/AllSettings";
import ChevronIcon from "../../ui/ChevronIcon";
import "./PsNavigationTree.css";
import { ariaSelected } from "./components/Helper";
import ItemLabel from "./components/ItemLabel";
import { isEmptyOrSpaces } from "../../utils";

export const PsNavigationTree = forwardRef((props, ref) => {
    const [cmpState, setCmpState] = useState({
        selected: props.selected,
        searchMode: false,

        timer: 0,
        treeSettings: {},
        navTree: [],
        navMap: {},
        searchTree: [],
        searchMap: {},
        selectedNames: null,
        notifyNames: {},
        objects: [],
        loadingNames: {},
    });

    const cmpWorking = useRef({});

    const init = () => {
        var navTree = [];
        var treeSettings = {};
        var objects = [];
        props.sections.forEach((section) => {
            var settings = allSettings[section];
            if (settings) {
                Object.keys(settings).forEach((config) => {
                    var setting = settings[config];
                    Object.assign(setting, { section, config });
                    var childSetting = settings[setting.childConfig] || {};
                    Object.assign(childSetting, { parentConfig: config });
                    Object.keys(setting.records || {}).forEach((childConfig) => {
                        var record = setting.records[childConfig];
                        Object.assign(record, { childConfig });
                        var childSetting = settings[record.childConfig] || {};
                        Object.assign(childSetting, { parentConfig: config });
                    });
                });
                var rootSetting = settings["root"];
                var root = createRootItem(rootSetting);

                var expanded = !props.selectedSection || props.selectedSection === section;
                navTree.push({ name: section, items: [root], expanded, setting: {} });
                Object.values(settings).forEach((setting) => {
                    if (setting.object) {
                        objects.push(setting.object);
                    }
                });
                treeSettings[section] = settings;
            }
        });

        // put items in navMap
        var navMap = navTree.reduce((obj, item) => {
            obj[item.items[0].name] = item.items[0];
            return obj;
        }, {});

        // cmp.set("sections", cmpWorking.current.sections);
        cmp.set("objects", Array.from(new Set(objects)));
        cmp.set("treeSettings", treeSettings);
        cmp.set("navTree", navTree);
        cmp.set("navMap", navMap);

        parseSelected();
    };

    useEffect(() => {
        cmpWorking.current = { ...cmpState };
        init();
    }, []);

    // useEffect(() => {
    //     cmp.set("filters", props.filters);
    //     onFiltersChange();
    // }, [props.filters]);

    // useEffect(() => {
    //     if (isFirstRender.current) {
    //         return;
    //     }

    //     var searchMode = cmpWorking.current.searchMode;
    //     var which = searchMode ? "search" : "nav";
    //     var itemTree = searchMode ? cmpWorking.current.searchTree : cmpWorking.current.navTree;
    //     var itemMap = searchMode ? cmpWorking.current.searchMap : cmpWorking.current.navMap;
    //     updateTree(which, itemTree, itemMap);
    // }, [props.selectedSection]);

    useEffect(() => {
        if (!props.parentToChildEvent) {
            return;
        }
        handleEvent(props.parentToChildEvent);
    }, [props.parentToChildEvent]);

    // TODO: do immediate search (instead of delayed search) on submitting the searchtext box, e.g., exiting it or pressing enter
    useEffect(() => {
        cmp.set("searchText", props.searchText);
        onSearchTextChange(); // changing props.filter after component creation incurs 500ms delay, but there is no use case for this currently; checking props.filters in a separate useEffect would cause the search to run twice!
    }, [props.searchText, props.filters]);

    useEffect(() => {
        cmp.set("selected", props.selected);
        onSelectedChange();
    }, [props.selected]);

    const cmp = {
        get: (key) => {
            if (cmpWorking.current.hasOwnProperty(key)) return cmpWorking.current[key];
            return props[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setCmpState((prev) => ({ ...prev, [key]: value }));
        },
    };

    const onSelectedChange = () => {
        parseSelected();
    };

    const onSearchTextChange = () => {
        if (checkSearch()) {
            if (props.searchText === null || !props.searchText.length) {
                doSearch();
            } else {
                delayedSearch();
            }
        } else {
            closeSearch();
        }
    };

    // navigate to the selected item in the navigation tree
    const handleSelect = (selectedRoot) => {
        setSelected(selectedRoot.name);
    };

    const handleClickChevronIcon = (selectedRoot, parentRootName, treeItemLevel) => {
        const selectedTreeItem = cmpWorking.current.searchMode ? "searchTree" : "navTree";
        if (treeItemLevel === "firstTreeItem") {
            //Replace with the following
            // cmp.set([selectedTreeItem], cmp[selectedTreeItem].map((prevNavTreeItem) => {
            //   if (selectedRoot.parentName.includes(prevNavTreeItem.name)) {
            //     prevNavTreeItem.items[0].items =
            //       prevNavTreeItem.items[0].items.map((item) => {
            //         if (item.id === selectedRoot.id) {
            //           return {
            //             ...item,
            //             expanded:
            //               item.expanded === undefined ? true : !item.expanded,
            //           };
            //         }
            //         return item;
            //       });
            //     return prevNavTreeItem;
            //   }
            //   return prevNavTreeItem;
            // }));

            setCmpState((prev) => ({
                ...prev,
                [selectedTreeItem]: prev[selectedTreeItem].map((prevNavTreeItem) => {
                    if (selectedRoot.parentName.includes(prevNavTreeItem.name)) {
                        prevNavTreeItem.items[0].items = prevNavTreeItem.items[0].items.map((item) => {
                            if (item.id === selectedRoot.id) {
                                return {
                                    ...item,
                                    expanded: !item.expanded,
                                };
                            }
                            return item;
                        });
                        return prevNavTreeItem;
                    }
                    return prevNavTreeItem;
                }),
            }));
        }
        if (treeItemLevel === "secondTreeItem") {
            setCmpState((prev) => ({
                ...prev,
                [selectedTreeItem]: prev[selectedTreeItem].map((prevNavTreeItem) => {
                    if (selectedRoot.parentName.includes(prevNavTreeItem.name)) {
                        prevNavTreeItem.items[0].items = prevNavTreeItem.items[0].items.map((item) => {
                            if (item.id === selectedRoot.parentId) {
                                item.items = item.items.map((nestedItem) => {
                                    if (nestedItem.id === selectedRoot.id) {
                                        return {
                                            ...nestedItem,
                                            expanded: !nestedItem.expanded,
                                        };
                                    }
                                    return nestedItem;
                                });
                            }
                            return item;
                        });
                    }
                    return prevNavTreeItem;
                }),
            }));
        }
        if (treeItemLevel === "thirdTreeItem") {
            setCmpState((prev) => ({
                ...prev,
                [selectedTreeItem]: prev[selectedTreeItem].map((prevNavTreeItem) => {
                    if (selectedRoot.parentName.includes(prevNavTreeItem.name)) {
                        prevNavTreeItem.items[0].items = prevNavTreeItem.items[0].items.map((item) => {
                            if (item.id === parentRootName[1].id) {
                                item.items = item.items.map((nestedItem) => {
                                    if (nestedItem.id === selectedRoot.parentId) {
                                        nestedItem.items = nestedItem.items.map((secondNestedItem) => {
                                            if (secondNestedItem.id === selectedRoot.id) {
                                                return {
                                                    ...secondNestedItem,
                                                    expanded: !secondNestedItem.expanded,
                                                };
                                            }
                                            return secondNestedItem;
                                        });
                                    }
                                    return nestedItem;
                                });
                            }
                            return item;
                        });
                    }
                    return prevNavTreeItem;
                }),
            }));
        }
        if (treeItemLevel === "fourthTreeItem") {
            const navTree = cmp.get("navTree");

            const updatedNavigationTree = navTree.map((firstItem) => {
                if (selectedRoot.rootName.includes(firstItem.name)) {
                    return {
                        ...firstItem,
                        items: [
                            {
                                ...firstItem.items[0],
                                items: [
                                    {
                                        ...firstItem.items[0].items[0],
                                        items: firstItem.items[0].items[0].items.map((secondItem) => {
                                            if (secondItem.id === parentRootName[0].id) {
                                                return {
                                                    ...secondItem,
                                                    items: secondItem.items.map((thirdItem) => {
                                                        if (thirdItem.id === parentRootName[1].id) {
                                                            return {
                                                                ...thirdItem,
                                                                items: thirdItem.items.map((fifthItem) => {
                                                                    if (selectedRoot.id === fifthItem.id) {
                                                                        return {
                                                                            ...fifthItem,
                                                                            expanded: !fifthItem.expanded,
                                                                        };
                                                                    } else {
                                                                        return fifthItem;
                                                                    }
                                                                }),
                                                            };
                                                        }
                                                        return thirdItem;
                                                    }),
                                                };
                                            }
                                            return secondItem;
                                        }),
                                    },
                                ],
                            },
                        ],
                    };
                }
                return firstItem;
            });

            cmp.set("navTree", updatedNavigationTree);
        }
    };

    // update the navigation tree if a record changed (e.g., rename, delete)
    const handleRecordChangedEvent = (event) => {
        const action = event.action;
        const parentId = event.parentId;
        const obj = event.obj || "";
        const id = event.id || null;
        const record = event.record || {};

        if (cmpWorking.current.objects.includes(obj)) {
            if (action === "create") {
                upsertRecord(cmpWorking.current.navTree, cmpWorking.current.navMap, parentId, record);
                updateTree("nav", cmpWorking.current.navTree, cmpWorking.current.navMap);
            } else if (action === "update") {
                updateRecord(cmpWorking.current.navTree, cmpWorking.current.navMap, record);
                updateTree("nav", cmpWorking.current.navTree, cmpWorking.current.navMap);
                updateRecord(cmpWorking.current.searchTree, cmpWorking.current.searchMap, record);
                updateTree("search", cmpWorking.current.searchTree, cmpWorking.current.searchMap);
            } else if (action === "delete") {
                deleteRecord(cmpWorking.current.navTree, cmpWorking.current.navMap, id);
                updateTree("nav", cmpWorking.current.navTree, cmpWorking.current.navMap);
                deleteRecord(cmpWorking.current.searchTree, cmpWorking.current.searchMap, id);
                updateTree("search", cmpWorking.current.searchTree, cmpWorking.current.searchMap);
            }
        }
    };

    // on refresh, resubmit search (if searchMode=true) and reload children for the selected item(s)
    const handleReload = () => {
        var navTree = cmpWorking.current.navTree;
        var navMap = cmpWorking.current.navMap;
        navTree.forEach((rootItems) => {
            var root = rootItems.items[0];
            loadChildren(navTree, navMap, root, true);
        });

        if (cmpWorking.current.searchMode) {
            doSearch();
        }
    };

    const handleEvent = (event) => {
        if (event.type === "reload") {
            handleReload();
        } else if (event.type === "record") {
            handleRecordChangedEvent(event);
        }
    };

    // return existing item in navigation or search map, e.g., when navigating to parent item after delete
    const nameFromId = (id) => {
        const navMap = cmpWorking.current.navMap;
        const searchMap = cmpWorking.current.searchMap;
        var item;
        if (id) {
            item = Object.values(navMap).find((obj) => obj.id === id);
            if (item) {
                return item.name;
            }
            item = Object.values(searchMap).find((obj) => obj.id === id);
            if (item) {
                return item.name;
            }
        }
    };

    // parse the selected variable, update the tree, and navigate to the selected item(s)
    const parseSelected = () => {
        var selected = cmp.get("selected");
        var currentNames = cmpWorking.current.selectedNames;
        var isInit = currentNames == null;
        var selectedList = selected === null ? [] : Array.isArray(selected) ? selected : [selected];
        var selectedParsed = selectedList.map((name) => Record.parseName(name));
        var selectedNames = selectedParsed.reduce((obj, parsed) => {
            obj[parsed.section] = parsed.name;
            return obj;
        }, {});

        // no change
        if (!isInit && JSON.stringify(currentNames, Object.keys(currentNames).sort()) === JSON.stringify(selectedNames, Object.keys(selectedNames).sort())) {
            return;
        }

        // set selectedNames and notifyNames
        var notifyNames = selectedParsed.reduce((obj, parsed) => {
            obj[parsed.name] = "change";
            return obj;
        }, {});
        cmp.set("selectedNames", selectedNames);
        cmp.set("notifyNames", notifyNames);

        // search on init
        if (isInit && checkSearch()) {
            delayedSearch();
            return;
        }
        // set values
        loadSelection(isInit, selectedParsed);
    };

    // get child records for navigation tree
    const setLoading = (name, value) => {
        var loadingNames = cmpWorking.current.loadingNames;
        loadingNames[name] = value;
        cmp.set("loadingNames", loadingNames);
        for (let v in loadingNames) {
            if (loadingNames[v]) {
                props.setLoading(true);
                return true;
            }
        }
        cmp.set("loadingNames", {});
        props.setLoading(false);
        return false;
    };

    // load roots and select items in navigation tree programatically
    const loadSelection = (isInit, selectedParsed) => {
        // update search tree first before loading to make sure the relevant selections update
        if (cmpWorking.current.searchMode) {
            updateTree("search", cmpWorking.current.searchTree, cmpWorking.current.searchMap);
        }

        // always update navTree, regardless of searchMode, so that
        // (1) the correct state is returned when searchMode is closed, and
        // (2) navigation events are fired when an item is selected externally that is not in the search results
        setLoading("selected", true);
        var navTree = cmpWorking.current.navTree;
        var navMap = cmpWorking.current.navMap;

        var parsedMap = selectedParsed.reduce((obj, parsed) => {
            obj[parsed.section] = parsed;
            return obj;
        }, {});
        navTree.forEach((root) => {
            root = getRoot(navMap, root);
            var parsed = parsedMap[root.setting.section];

            if (parsed) {
                var existing = navMap[parsed.name];
                if (existing) {
                    // IMPROVEMENT: expand the full path to the selected item, so that the scroll position can be correctly calculated
                    loadChildren(navTree, navMap, existing);
                } else if (parsed.section && parsed.config && parsed.id) {
                    browseToRecord(navTree, navMap, root, parsed);
                } else if (isInit) {
                    loadChildren(navTree, navMap, root);
                }
            } else {
                if (isInit) {
                    loadChildren(navTree, navMap, root);
                }
            }
        });

        // update tree
        setLoading("selected", false);
        updateTree("nav", navTree, navMap);
    };

    // load the navigation item's children if not yet loaded
    const loadChildren = (navTree, navMap, item, reload = false) => {
        if (!item.loaded || reload) {
            var setting = item.setting || {};
            var treeSettings = cmpWorking.current.treeSettings;
            var settings = treeSettings[setting.section] || {};
            var childSetting = settings[setting.childConfig];
            if (childSetting) {
                if (childSetting.records) {
                    addRecords(navTree, navMap, childSetting, item, reload);
                } else if (childSetting.module && childSetting.object) {
                    loadRecords(navTree, navMap, childSetting, item, reload);
                }
            }
        }
    };

    // get child records for navigation tree item
    const loadRecords = (navTree, navMap, setting, parent, reload) => {
        var loadName = [parent.name, "children"].join(".");

        // expand item and mark as loaded
        parent = syncItem(navTree, navMap, parent);
        parent.expanded = !parent.loaded ? true : parent.expanded;
        parent.loaded = true;

        var onSuccess = function (response) {
            response.forEach((record) => {
                var item = itemFromRecord(navMap, parent, setting, record);
                var autoExpandLoneBranch = response.length === 1;
                if (autoExpandLoneBranch || (reload && item.loaded)) {
                    loadChildren(navTree, navMap, item, reload);
                }
            });

            postItemsChange(parent.items, false, false);
            setLoading(loadName, false);
            updateTree("nav", navTree, navMap);
        };

        var onError = function (response) {
            postItemsChange(parent.items, true, false);
            setLoading(loadName, false);
            updateTree("nav", navTree, navMap);
        };

        // IMPROVEMENT: build paginator that loads more than 100 results
        setLoading(loadName, true);
        var parentId = parent.setting.object ? parent.id : parent.parentId;
        var filter = setting.parentField ? { [setting.parentField]: parentId } : {};

        Record.getRecords(setting.module, setting.object, filter, onSuccess, onError);
    };

    // add child records that are specified in the settings
    const addRecords = (navTree, navMap, setting, parent, reload) => {
        // expand item and mark as loaded
        parent = syncItem(navTree, navMap, parent);
        parent.expanded = !parent.loaded ? true : parent.expanded;
        parent.loaded = true;

        // add records directly
        var records = Object.values(setting.records || {});
        records.forEach((record) => {
            var item = itemFromRecord(navMap, parent, setting, record);
            var autoExpandLoneBranch = records.length === 1;
            if (autoExpandLoneBranch || (reload && item.loaded)) {
                loadChildren(navTree, navMap, item, reload);
            }
        });

        // this.postItemsChange(parent.items, false, false); no need to sort
        updateTree("nav", navTree, navMap);
    };

    // load records in the path from root to selected record
    const browseToRecord = (navTree, navMap, root, parsed, loadChild = null) => {
        var id = parsed.id;
        var section = parsed.section;
        var config = parsed.config;
        var loadName = [section, config, id, "record"].join(".");
        var treeSettings = cmpWorking.current.treeSettings;
        var settings = treeSettings[section] || {};
        var setting = settings[config];

        var onSuccess = function (response) {
            var record = response[0];
            var item = processRecord(navTree, navMap, settings, root, config, record, false);
            loadChildren(navTree, navMap, item);
            if (loadChild) {
                item.items.forEach((item) => {
                    if (item.id === loadChild) {
                        loadChildren(navTree, navMap, item);
                    }
                });
            }
            setLoading(loadName, false);
            updateTree("nav", navTree, navMap);
        };

        var onError = function (response) {
            setLoading(loadName, false);
            loadChildren(navTree, navMap, root);
            updateTree("nav", navTree, navMap);
        };

        if (setting.records && settings[setting.parentConfig]) {
            var parentId = id.slice(id.indexOf("-") + 1);
            var parent = { section, config: setting.parentConfig, id: parentId };
            browseToRecord(navTree, navMap, root, parent, id);
        } else if (setting.module && setting.object && id) {
            setLoading(loadName, true);
            Record.getRecord(setting.module, setting.object, id, {}, "", "GET", onSuccess, onError);
        } else {
            onError(null);
        }
    };

    const processRecord = (itemTree, itemMap, settings, root, config, record, searchMode) => {
        // build navigation tree settings backwards from selected record
        var setting = settings[config];
        var recordMap = {};

        while (setting && record) {
            if (setting.records) {
                recordMap[setting.config] = setting.records[config];
            } else {
                recordMap[setting.config] = record;
            }
            if (setting.parent) {
                record = record[setting.parent];
            }
            config = setting.config;
            setting = settings[setting.parentConfig];
        }

        // add items from root to selected record
        var parent = root;
        setting = settings[parent?.config] || {};

        // load navigation tree forward from the root to the specified record
        while (true) {
            setting = settings[parent.setting.childConfig] || {};
            record = recordMap[setting.config];

            if (!parent || !record) {
                break;
            }

            if (searchMode) {
                parent.expanded = true;
                parent.loaded = true;
            } else {
                parent = syncItem(itemTree, itemMap, parent);
                parent.expanded = !parent.loaded ? true : parent.expanded;
                loadChildren(itemTree, itemMap, parent);
            }

            parent = itemFromRecord(itemMap, parent, setting, record);
        }
        return parent;
    };

    // issue: intermediate updates to the navigation tree leave old items selected
    // issue: when setting a lightning:tree:selectedItem and then loading additional items under the same parent, both the first and the selected item will show up as selected
    const updateTree = (which, itemTree, itemMap, initSearch = false) => {
        // postpone update until all loading is done
        const loadingNames = cmp.get("loadingNames");
        if (loadingNames && Object.keys(loadingNames).length) {
            return;
        }

        // definitive workaround for all kinds of issues with incorrect selections in lightning:tree
        // update the tree with a deep-copy (to prevent flickering), before updating it with the final values
        //cmp.set(`${which}Tree`, JSON.parse(JSON.stringify(itemTree)));

        // mark items as selected in the specified tree, and create navigation events
        var root;
        var name;
        var section;
        var selectedNames = cmpWorking.current.selectedNames || {};
        var events = [];
        var fireEvent = which === "nav" || initSearch;
        var searchMode = cmpWorking.current.searchMode || initSearch;
        var sendScroll = (searchMode && which === "search") || (!searchMode && which === "nav");
        itemTree.forEach((treeRoot) => {
            root = getRoot(itemMap, treeRoot);
            section = root.setting.section;
            name = selectedNames[root.setting.section];
            root.selected = name;
            root.expanded = !props.selectedSection || props.selectedSection === section || name != null;
            treeRoot.expanded = root.expanded;
            if (fireEvent) {
                addEvent(events, itemTree, itemMap, root, name, sendScroll);
            }
        });

        cmp.set(`${which}Tree`, itemTree);
        cmp.set(`${which}Map`, itemMap);

        itemTree = JSON.parse(JSON.stringify(itemTree)); // copy?

        // send navigation events after updating the tree
        if (fireEvent) {
            events.forEach((event) => sendNavigationEvent(event.item, event.source, event.scroll));
            cmp.set("notifyNames", null);
        }

        // set search mode
        if (initSearch) {
            cmp.set("searchMode", true);
        }
    };

    // add navigation event
    const addEvent = (events, itemTree, itemMap, root, name, sendScroll) => {
        var multiSelect = cmpWorking.current.multiSelect;
        var notifyNames = cmpWorking.current.notifyNames || {};
        var unselect = name === null && root.selected != null;

        if (name != null) {
            // navigation event(s) for notifyNames
            var source = notifyNames[name] || "change";
            var item = itemMap[name];

            if (source != null && item) {
                if (sendScroll) {
                    var counts = getCounts(itemTree, name);
                    var skip = 2 * itemTree.length;
                    var scroll = (counts.selected - skip) / counts.total;
                    events.push({ item, source, scroll });
                } else {
                    events.push({ item, source, scroll: null });
                }
            }
        } else if (multiSelect && unselect) {
            // create navigation event for unselect in case of multiselect; for single select the newly selected item will already generate an event
            events.push({ item: { setting: { section: root.setting.section } }, source: "change", scroll: null });
        }
    };

    // add empty placeholder indicating no records are available, and sort items
    const postItemsChange = (items, forceEmpty, sortRecursive) => {
        if (forceEmpty || !items.length) {
            items[0] = { name: "", label: "--None--", metatext: "--None--", disabled: true };
        } else {
            var sortFunction = Record.sortByString;
            if (sortRecursive) {
                sortAll(items, "label");
            } else {
                items.sort(sortFunction("label", false));
            }
        }
    };

    // recursively goes through items tree to find the item with the specified field value
    const findItem = (itemTree, field, value) => {
        let selectedItem;
        itemTree.some((item) => {
            if (item[field] === value) {
                selectedItem = item;
                return true;
            }
            const items = item.items;
            if (items && items.length) {
                selectedItem = findItem(items, field, value);
                return selectedItem !== undefined;
            }
            return false;
        });
        return selectedItem;
    };

    // recursively goes through items tree to find the total number of elements (only including expanded), as well as the selected element
    const getCounts = (itemTree, name, counts) => {
        if (!counts) {
            counts = { total: 0, selected: 0 };
        }
        if (!itemTree) {
            return counts;
        }
        itemTree.forEach((item) => {
            counts.total = counts.total + 1;
            if (name === item.name) {
                counts.selected = counts.total;
            }
            if (item.expanded) {
                getCounts(item.items, name, counts);
            }
        });

        return counts;
    };

    // issue: lightning:tree deep-copies the content of the itemTree when updating 'expanded'
    // this function resyncs an item across itemTree and itemMap
    const syncItem = (itemTree, itemMap, item) => {
        var treeItem = findItem(itemTree, "name", item?.name) || {};
        var expanded = treeItem.expanded;
        var items = treeItem.items;
        Object.assign(treeItem, item, { expanded, items });
        itemMap[treeItem.name] = treeItem;

        return treeItem;
    };

    // issue: lightning:tree deep-copies the content of the itemTree when updating 'expanded'
    // this function resyncs a root across itemTree and itemMap
    const getRoot = (itemMap, root) => {
        var treeItem = root.items[0];
        var expanded = treeItem.expanded;
        var items = treeItem.items;
        var mapItem = itemMap[treeItem.name] || {};
        Object.assign(treeItem, mapItem, { expanded, items });
        itemMap[treeItem.name] = treeItem;
        return treeItem;
    };

    // recursively sort items
    const sortAll = (itemTree, field) => {
        if (!itemTree || !itemTree.length) {
            return;
        }
        var sortFunction = Record.sortByString;
        itemTree.sort(sortFunction(field, false));
        itemTree.forEach((item) => sortAll(item.items, field));
    };

    // update the item for the specified record
    const updateRecord = (itemTree, itemMap, record) => {
        Object.values(itemMap).forEach((item) => {
            if (item.id === record.id) {
                item = syncItem(itemTree, itemMap, itemMap[item.name]);
                item.label = isEmptyOrSpaces(record.name) ? "--Empty--" : record.name;
                var parent = syncItem(itemTree, itemMap, itemMap[item.parentName]);
                postItemsChange(parent.items, false, false);
            }
        });
    };

    // create or update the item for the specified record under each parent item whose id matches the parentId
    const upsertRecord = (itemTree, itemMap, parentId, record) => {
        var treeSettings = cmpWorking.current.treeSettings;
        Object.values(itemMap).forEach((parent) => {
            if (parent.id === parentId) {
                parent = syncItem(itemTree, itemMap, parent);
                var settings = treeSettings[parent.setting.section];
                var setting = settings[parent.setting.childConfig];
                if (setting) {
                    itemFromRecord(itemMap, parent, setting, record);
                    postItemsChange(parent.items, false, false);
                }
            }
        });
    };

    // delete record from parent.items
    const deleteRecord = (itemTree, itemMap, id) => {
        Object.values(itemMap).forEach((item) => {
            if (item.id === id) {
                // delete item from itemMap
                delete itemMap[item.name];

                // delete item from parent.items
                var parent = syncItem(itemTree, itemMap, itemMap[item.parentName]);
                var index = parent.items?.findIndex((v) => v.name === item.name);
                if (index > -1) {
                    parent.items.splice(index, 1);
                    postItemsChange(parent.items, false, false);
                }

                // deselect and fire event if deleted item was selected
                var root = syncItem(itemTree, itemMap, itemMap[item.rootName]);
                if (root.selected === item.name) {
                    root.selected = undefined;
                    sendNavigationEvent({ setting: { section: root.setting.section } }, "delete", null);
                }
            }
        });
    };

    // create root item at initialization
    const createRootItem = (setting) => {
        let records = setting.records || { missing: { name: "Missing" } };
        let record = Object.values(records)[0];

        setting = (({ section, config }) => ({ section, config, childConfig: record.childConfig }))(setting);
        record = (({ name, childConfig }) => ({ id: childConfig, name }))(record);
        let name = [setting.section, setting.config, record.id].join("_");
        let breadcrumb = [{ name: record.name, id: record.id }];
        let label = isEmptyOrSpaces(record.name) ? "--Empty--" : record.name;
        return { name, id: record.id, label, setting, rootName: name, parentName: null, parentId: null, breadcrumb, record, items: [] };
    };

    // create item from record, or update existing if present
    const itemFromRecord = (itemMap, parent, setting, record) => {
        if (setting.records) {
            let id = parent.setting.object ? record.childConfig + "-" + parent.id : record.childConfig;
            setting = (({ section, config }) => ({ section, config, childConfig: record.childConfig }))(setting);
            record = { name: record.name, id: id };
        }

        let breadcrumb = [...parent.breadcrumb] || [];
        breadcrumb.push({ name: record.name, id: record.id });
        let label = isEmptyOrSpaces(record.name) ? "--Empty--" : record.name;
        let item = { id: record.id, label, setting, rootName: parent.rootName, parentId: parent.id, parentName: parent.name, breadcrumb, record };
        item.name = [setting.section, setting.config, record.id].join("_");
        var existing = itemMap[item.name];

        if (existing) {
            Object.assign(existing, item);
            return existing;
        } else {
            var items = parent.items;
            if (items.length === 1 && items[0].name === "") {
                items.length = 0;
            } // remove --None-- item if present
            item.items = []; // default
            items.push(item);
            itemMap[item.name] = item;
            return item;
        }
    };

    // set the selected item in selectedNames and the tree root item
    const setSelected = (name) => {
        var searchMode = cmpWorking.current.searchMode;
        var selectedNames = cmpWorking.current.selectedNames || {};
        var multiSelect = cmpWorking.current.multiSelect;
        var itemTree = searchMode ? cmpWorking.current.searchTree : cmpWorking.current.navTree;
        var itemMap = searchMode ? cmpWorking.current.searchMap : cmpWorking.current.navMap;
        var item = itemMap[name] || {};

        // event is sent immediately, so no need to notify after loading the tree
        // NB; the event may trigger further changes, which may then set notifyNames again, e.g., closing search
        cmp.set("notifyNames", null);

        // no need to update the tree if the item is already selected
        if (selectedNames[item.setting?.section] === name) {
            sendNavigationEvent(item, "tree", null);
            return;
        }

        // set selected item name for each section, and unselect other items if necessary
        itemTree.forEach((root) => {
            root = getRoot(itemMap, root);
            var section = root.setting?.section;
            if (section === item.setting?.section) {
                selectedNames[section] = item.name;
            } else if (!multiSelect) {
                selectedNames[section] = undefined;
                // } else if (section !== item.setting?.section) {  // Double Container cannot be selected on the search page - TODO :check if this is still an issue
                //   selectedNames = { [item.setting?.section]: item.name };
            }
        });

        // store selectedNames now, so the current selection is available to events triggered after this, but before the tree itself updates
        cmp.set("selectedNames", selectedNames);

        // update the searchTree first before loading any additional data in the navTree
        if (searchMode) {
            updateTree("search", itemTree, itemMap);
        }

        // update the navTree
        // NB: navTree is also updated in the background when in searchMode, so that it shows the right selection after closing search, and also fires the right navigation events
        var navTree = cmpWorking.current.navTree;
        var navMap = cmpWorking.current.navMap;
        var root = navMap[item.rootName];
        var existing = navMap[item.name];

        if (existing) {
            loadChildren(navTree, navMap, existing);
            updateTree("nav", navTree, navMap);
        } else {
            var parsed = Record.parseName(name);
            browseToRecord(navTree, navMap, root, parsed);
        }

        // send navigation event
        sendNavigationEvent(item, "tree", null);
    };

    // delays search with 500 ms while typing to prevent unnecessary API calls
    const delayedSearch = () => {
        clearTimeout(cmpWorking.current.timer);
        const delayMs = 500;
        const timer = setTimeout(() => {
            doSearch();
        }, delayMs);
        cmp.set("timer", timer);
    };

    const escapeRegex = (string) => {
        return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    };

    // submit search
    const doSearch = () => {
        // update timer
        clearTimeout(cmpWorking.current.timer);
        cmp.set("timer", null);

        // database query notation
        var searchSql = props.searchText ? "%" + props.searchText + "%" : null;
        var searchRegex = props.searchText ? new RegExp(escapeRegex(props.searchText || ""), "gi") : null;

        // get and initalize variables
        var searchTree = [];
        var searchMap = {};
        var treeSettings = cmpWorking.current.treeSettings;
        var treeFilters = props.filters || {};
        var navMap = cmpWorking.current.navMap;
        var navTree = cmpWorking.current.navTree;
        navTree.forEach((root) => {
            // copy root item
            root = getRoot(navMap, root);
            root = Object.assign({}, root, { items: [], expanded: true, selected: null, loaded: true });
            var section = root.setting.section;
            searchTree.push({ name: section, items: [root] });
            searchMap[root.name] = root;

            // settings
            var settings = treeSettings[section];
            var filters = treeFilters[section] || {};
            Object.keys(settings).forEach((config) => {
                var setting = settings[config];
                var filter = filters[config] || {};
                if (setting.searchField) {
                    var loadName = [section, config, "search"].join(".");
                    var onSuccess = function (response) {
                        response.forEach((record) => {
                            processRecord(searchTree, searchMap, settings, root, config, record, true);
                        });
                        postItemsChange(root.items, false, true);
                        setLoading(loadName, false);
                        updateTree("search", searchTree, searchMap, true);
                    };

                    var onError = function (response) {
                        postItemsChange(root.items, true, true);
                        setLoading(loadName, false);
                        updateTree("search", searchTree, searchMap, true);
                    };

                    if (setting.records) {
                        Object.values(setting.records).forEach((record) => {
                            if (record.name.match(searchRegex)) {
                                itemFromRecord(searchMap, root, setting, record); // IMPROVEMENT: use actual parent instead of 'root'
                            }
                        });
                        postItemsChange(root.items, false, true);
                    } else {
                        setLoading(loadName, true);
                        var queryFilter = Object.assign({}, filter);
                        if (searchSql) {
                            queryFilter[setting.searchField] = queryFilter[setting.searchField] || searchSql;
                        }

                        Record.getRecords(setting.module, setting.object, queryFilter, onSuccess, onError);
                    }
                }
            });
        });

        updateTree("search", searchTree, searchMap, true);
    };

    // check if search should be on
    const checkSearch = () => {
        // checking searchText
        var hasText = props.searchText != null && props.searchText.length;
        if (hasText) {
            return true;
        }

        // checking filters
        var filters = props.filters;
        var treeSettings = cmpWorking.current.treeSettings;
        var hasFilters =
            filters &&
            Object.keys(filters).some((section) => {
                var settings = treeSettings[section] || {};
                var filter = filters[section];
                return (
                    filter &&
                    Object.keys(filter).some((config) => {
                        var setting = settings[config];
                        var fields = filter[config];
                        return setting && fields && Object.values(fields).some((value) => value != null);
                    })
                );
            });

        if (hasFilters) {
            return true;
        }
    };

    // close search and stop any pending delayed searches
    const closeSearch = () => {
        clearTimeout(cmpWorking.current.timer);

        // set notifyNames, in case the navTree is still loading while search is being closed
        var selectedNames = cmpWorking.current.selectedNames || {};
        var notifyNames = Object.values(selectedNames).reduce((obj, item) => {
            obj[item] = "closeSearch";
            return obj;
        }, {});
        cmp.set("searchMode", false);
        cmp.set("timer", null);
        cmp.set("searchTree", []);
        cmp.set("searchMap", {});
        cmp.set("notifyNames", notifyNames);

        updateTree("nav", cmpWorking.current.navTree, cmpWorking.current.navMap);
    };

    const dispatchEvent = (event) => {
        props.childToParent(event);
    };

    // send out a navigation event, so that containing components get access to the full record
    const sendNavigationEvent = (item, source, scroll) => {
        var setting = item.setting || {};
        var event = {
            type: "navigation",
            id: item.id,
            label: item.label,
            section: setting.section,
            parentId: item.parentId,
            obj: setting.config,
            breadcrumb: item.breadcrumb,
            record: item.record,
            source,
            scroll,
        };
        dispatchEvent(event);
    };

    // use the navigation tree in other components to get record details from id
    useImperativeHandle(ref, () => ({
        getNameFromId: (id) => nameFromId(id),
    }));

    const navigationTreeItems = cmpState.searchMode ? cmpState.searchTree : cmpState.navTree;

    return (
        <div>
            <div className="slds-tree_container">
                {navigationTreeItems.length > 0 &&
                    navigationTreeItems.map((root) => (
                        <div key={root.name}>
                            {root.expanded && (
                                <>
                                    <h4 className="slds-text-title_caps slds-p-around_x-small">{root.items[0].label}</h4>
                                    <ul aria-labelledby="treeheading" className="slds-tree" role="tree">
                                        <>
                                            {root.items[0].items.map((firstLevelRootItem, index) => (
                                                <div key={firstLevelRootItem.id || index}>
                                                    {firstLevelRootItem.id ? (
                                                        <li
                                                            aria-expanded={firstLevelRootItem.expanded}
                                                            aria-label="Tree Branch"
                                                            aria-level="1"
                                                            aria-disabled={firstLevelRootItem.disabled}
                                                            role="treeitem"
                                                            aria-selected={ariaSelected(firstLevelRootItem, props.sections, cmpState.selectedNames)}
                                                        >
                                                            {/* First Tree*/}
                                                            <div
                                                                className="slds-tree__item row1"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleSelect(firstLevelRootItem);
                                                                }}
                                                            >
                                                                {root.name !== "folders" &&
                                                                //TODO -  Avoid the need for implementation specific to other components
                                                                //        Instead, we could pass down a prop to indicate the tree should be expanded
                                                                firstLevelRootItem.items.length > 0 ? (
                                                                    <button
                                                                        className="slds-button slds-button_icon slds-m-right_x-small"
                                                                        aria-hidden="true"
                                                                        tabIndex="-1"
                                                                        title={firstLevelRootItem.expanded ? "Collapse Tree Branch" : "Expand Tree Branch"}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleClickChevronIcon(firstLevelRootItem, root.name, "firstTreeItem");
                                                                        }}
                                                                    >
                                                                        <ChevronIcon />
                                                                    </button>
                                                                ) : (
                                                                    <div className="emptyIcon"></div>
                                                                )}
                                                                <ItemLabel props={props} item={firstLevelRootItem} level={1} />
                                                            </div>
                                                            {/* Second Tree */}
                                                            {root.name !== "folders" && //TODO - avoid the need for implementation specific to other components. We should have generic solution for folders
                                                                firstLevelRootItem.items?.length > 0 &&
                                                                firstLevelRootItem.expanded &&
                                                                firstLevelRootItem.items.map((secondLevelRootItem) => (
                                                                    <div key={secondLevelRootItem.id || "none"}>
                                                                        <ul role="group">
                                                                            <li
                                                                                aria-expanded={secondLevelRootItem.expanded}
                                                                                aria-level="2"
                                                                                aria-disabled={secondLevelRootItem.disabled}
                                                                                aria-selected={ariaSelected(secondLevelRootItem, props.sections, cmpState.selectedNames)}
                                                                                role="treeitem"
                                                                                tabIndex="0"
                                                                            >
                                                                                <div
                                                                                    className="slds-tree__item row2"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        handleSelect(secondLevelRootItem);
                                                                                    }}
                                                                                >
                                                                                    {secondLevelRootItem.items?.length > 0 ? (
                                                                                        <button
                                                                                            className="slds-button slds-button_icon slds-m-right_x-small"
                                                                                            aria-hidden="true"
                                                                                            tabIndex="-1"
                                                                                            title={secondLevelRootItem.expanded ? "Collapse Tree Branch" : "Expand Tree Branch"}
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                handleClickChevronIcon(secondLevelRootItem, root.name, "secondTreeItem");
                                                                                            }}
                                                                                        >
                                                                                            <ChevronIcon />
                                                                                        </button>
                                                                                    ) : (
                                                                                        <div className="emptyIcon"></div>
                                                                                    )}
                                                                                    <ItemLabel props={props} item={secondLevelRootItem} level={2} />
                                                                                </div>
                                                                                {/* Third Tree */}
                                                                                {secondLevelRootItem.items?.length > 0 &&
                                                                                    secondLevelRootItem.expanded &&
                                                                                    secondLevelRootItem.items.map((thirdLevelRootItem) => (
                                                                                        <div key={thirdLevelRootItem.id || "none"}>
                                                                                            <ul role="group">
                                                                                                <li
                                                                                                    aria-expanded={thirdLevelRootItem.expanded}
                                                                                                    aria-level="3"
                                                                                                    aria-disabled={thirdLevelRootItem.disabled}
                                                                                                    aria-selected={ariaSelected(thirdLevelRootItem, props.sections, cmpState.selectedNames)}
                                                                                                    role="treeitem"
                                                                                                    tabIndex="0"
                                                                                                >
                                                                                                    <div
                                                                                                        className="slds-tree__item row3"
                                                                                                        onClick={(e) => {
                                                                                                            e.stopPropagation();
                                                                                                            handleSelect(thirdLevelRootItem);
                                                                                                        }}
                                                                                                    >
                                                                                                        {thirdLevelRootItem.items?.length > 0 ? (
                                                                                                            <button
                                                                                                                className="slds-button slds-button_icon slds-m-right_x-small"
                                                                                                                aria-hidden="true"
                                                                                                                tabIndex="-1"
                                                                                                                title={thirdLevelRootItem.expanded ? "Collapse Tree Branch" : "Expand Tree Branch"}
                                                                                                                onClick={(e) => {
                                                                                                                    e.stopPropagation();
                                                                                                                    handleClickChevronIcon(
                                                                                                                        thirdLevelRootItem,
                                                                                                                        [root, firstLevelRootItem],
                                                                                                                        "thirdTreeItem"
                                                                                                                    );
                                                                                                                }}
                                                                                                            >
                                                                                                                <ChevronIcon />
                                                                                                            </button>
                                                                                                        ) : (
                                                                                                            <div className="emptyIcon"></div>
                                                                                                        )}
                                                                                                        <ItemLabel props={props} item={thirdLevelRootItem} level={3} />
                                                                                                    </div>
                                                                                                    {/* Fourth Tree */}
                                                                                                    {thirdLevelRootItem.items?.length > 0 &&
                                                                                                        thirdLevelRootItem.expanded &&
                                                                                                        thirdLevelRootItem.items.map((fourthLevelRootItem) => {
                                                                                                            return (
                                                                                                                <div key={fourthLevelRootItem.id || "none"}>
                                                                                                                    <ul role="group">
                                                                                                                        <li
                                                                                                                            aria-expanded={fourthLevelRootItem.expanded}
                                                                                                                            aria-level="4"
                                                                                                                            aria-disabled={fourthLevelRootItem.disabled}
                                                                                                                            aria-selected={ariaSelected(
                                                                                                                                fourthLevelRootItem,
                                                                                                                                props.sections,
                                                                                                                                cmpState.selectedNames
                                                                                                                            )}
                                                                                                                            role="treeitem"
                                                                                                                            tabIndex="0"
                                                                                                                        >
                                                                                                                            <div
                                                                                                                                className="slds-tree__item"
                                                                                                                                onClick={(e) => {
                                                                                                                                    e.stopPropagation();
                                                                                                                                    handleSelect(fourthLevelRootItem);
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                {fourthLevelRootItem.items?.length > 0 ? (
                                                                                                                                    <button
                                                                                                                                        className="slds-button slds-button_icon slds-m-right_x-small"
                                                                                                                                        aria-hidden="true"
                                                                                                                                        tabIndex="-1"
                                                                                                                                        title={
                                                                                                                                            fourthLevelRootItem.expanded
                                                                                                                                                ? "Collapse Tree Branch"
                                                                                                                                                : "Expand Tree Branch"
                                                                                                                                        }
                                                                                                                                        onClick={(e) => {
                                                                                                                                            e.stopPropagation();
                                                                                                                                            handleClickChevronIcon(
                                                                                                                                                fourthLevelRootItem,
                                                                                                                                                [secondLevelRootItem, thirdLevelRootItem],
                                                                                                                                                "fourthTreeItem"
                                                                                                                                            );
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <ChevronIcon />
                                                                                                                                    </button>
                                                                                                                                ) : (
                                                                                                                                    <div className="emptyIcon"></div>
                                                                                                                                )}
                                                                                                                                <span className="slds-has-flexi-truncate">
                                                                                                                                    <span
                                                                                                                                        className="slds-tree__item-label slds-truncate"
                                                                                                                                        title={fourthLevelRootItem.label}
                                                                                                                                    >
                                                                                                                                        {fourthLevelRootItem.label}
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </div>

                                                                                                                            {/*Fifth Tree */}
                                                                                                                            {fourthLevelRootItem.items?.length > 0 &&
                                                                                                                                fourthLevelRootItem.expanded &&
                                                                                                                                fourthLevelRootItem.items.map((fifthLevelRootItem) => {
                                                                                                                                    return (
                                                                                                                                        <div key={fifthLevelRootItem.id || "none"}>
                                                                                                                                            <ul role="group">
                                                                                                                                                <li
                                                                                                                                                    aria-expanded={fifthLevelRootItem.expanded}
                                                                                                                                                    aria-level="5"
                                                                                                                                                    aria-disabled={fifthLevelRootItem.disabled}
                                                                                                                                                    aria-selected={ariaSelected(
                                                                                                                                                        fifthLevelRootItem,
                                                                                                                                                        props.sections,
                                                                                                                                                        cmpState.selectedNames
                                                                                                                                                    )}
                                                                                                                                                    role="treeitem"
                                                                                                                                                    tabIndex="0"
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        className="slds-tree__item"
                                                                                                                                                        onClick={(e) => {
                                                                                                                                                            e.stopPropagation();
                                                                                                                                                            handleSelect(fifthLevelRootItem);
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        {fifthLevelRootItem.items?.length > 0 ? (
                                                                                                                                                            <button
                                                                                                                                                                className="slds-button slds-button_icon slds-m-right_x-small"
                                                                                                                                                                aria-hidden="true"
                                                                                                                                                                tabIndex="-1"
                                                                                                                                                                title={
                                                                                                                                                                    fifthLevelRootItem.expanded
                                                                                                                                                                        ? "Collapse Tree Branch"
                                                                                                                                                                        : "Expand Tree Branch"
                                                                                                                                                                }
                                                                                                                                                                onClick={(e) => {
                                                                                                                                                                    e.stopPropagation();
                                                                                                                                                                    handleClickChevronIcon(
                                                                                                                                                                        fifthLevelRootItem,
                                                                                                                                                                        [root, firstLevelRootItem],
                                                                                                                                                                        "fifthTreeItem"
                                                                                                                                                                    );
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <ChevronIcon />
                                                                                                                                                            </button>
                                                                                                                                                        ) : (
                                                                                                                                                            <div className="emptyIcon"></div>
                                                                                                                                                        )}
                                                                                                                                                        <span className="slds-has-flexi-truncate">
                                                                                                                                                            <span
                                                                                                                                                                className="slds-tree__item-label slds-truncate"
                                                                                                                                                                title={fifthLevelRootItem.label}
                                                                                                                                                            >
                                                                                                                                                                {fifthLevelRootItem.label}
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                        </div>
                                                                                                                                    );
                                                                                                                                })}
                                                                                                                        </li>
                                                                                                                    </ul>
                                                                                                                </div>
                                                                                                            );
                                                                                                        })}
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    ))}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                ))}
                                                        </li>
                                                    ) : (
                                                        <div className="metaText">{firstLevelRootItem.metatext}</div>
                                                    )}
                                                </div>
                                            ))}
                                        </>
                                    </ul>
                                </>
                            )}
                        </div>
                    ))}
            </div>
        </div>
    );
});

export default PsNavigationTree;
