import { useState, useEffect } from "react";
import { Button, Input, InputIcon, Spinner } from "@salesforce/design-system-react";
import { Auth } from "aws-amplify";

import { GENERAL_ERROR, PASSWORD_MISMATCH } from "../../../constants/ToastMessages";
import "../../Pages.css";
import useToastContext from "../../../context/useToastContext";
import useAuthContext from "../../../context/useAuthContext";

const ResetForm = ({ setMode }) => {
    const { login, setLogin, clearLoginSecrets } = useAuthContext();
    const { addToast } = useToastContext();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        clearLoginSecrets();
    }, []);

    const handleInputChange = (e, item) => {
        setLogin((prev) => ({ ...prev, [item]: e.target.value }));
    };

    const handleSubmitResetForm = async (event) => {
        try {
            event.preventDefault();

            if (!login || !login.username || !login.password || !login.confirmPassword || !login.code) {
                addToast("warning", "Missing Details", "Please fill out all fields");
                return;
            }

            if (login?.password !== login?.confirmPassword) {
                addToast("warning", "Password Mismatch", PASSWORD_MISMATCH);
                return;
            }

            setIsLoading(true);
            // applyInstance(login.instance); // should have already been set during login
            const results = await Auth.forgotPasswordSubmit(login.username, login.code, login.password);
            if (results === "SUCCESS") {
                addToast("success", "Successfully Reset Password", "Please log in with your new password.");
                setMode("login");
            } else {
                addToast("error", "Error", "Failed to change password. Please check the provided details and try again.");
            }
        } catch (err) {
            console.error(err);
            addToast("error", "Error", err.message || GENERAL_ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    function passwordIcon(isVisible, setVisibility) {
        if (!isVisible) return <InputIcon assistiveText={{ label: "Hide Password" }} name="hide" onClick={() => setVisibility(true)} />;
        return <InputIcon assistiveText={{ label: "Show Password" }} name="preview" onClick={() => setVisibility(false)} />;
    }
    return (
        <form onSubmit={handleSubmitResetForm}>
            <p className="form-title">We have sent a password reset code by email. Enter it below to reset your password</p>

            <div className="user-inputs">
                <Input type="text" label="Username" value={login?.username || ""} onChange={(e) => handleInputChange(e, "username")} />
            </div>
            <div className="user-inputs">
                <Input type="tel" label="Code" value={login?.code || ""} onChange={(e) => handleInputChange(e, "code")} />
            </div>
            <div className="user-inputs">
                <Input
                    label="New password"
                    value={login?.password || ""}
                    onChange={(e) => handleInputChange(e, "password")}
                    type={showPassword ? "text" : "password"}
                    iconRight={passwordIcon(showPassword, setShowPassword)}
                />
            </div>
            <div className="user-inputs">
                <Input
                    label="Confirm new password"
                    value={login?.confirmPassword || ""}
                    onChange={(e) => handleInputChange(e, "confirmPassword")}
                    type={showConfirmPassword ? "text" : "password"}
                    iconRight={passwordIcon(showConfirmPassword, setShowConfirmPassword)}
                />
            </div>
            <div className="login-signup-button">
                <Button
                    type="submit"
                    label={<>Change Password {isLoading ? <Spinner size="small" /> : null}</>}
                    variant="brand"
                    disabled={isLoading || !login?.username || !login?.password || !login?.confirmPassword || !login?.code}
                    className="login-submit-button"
                />
            </div>
        </form>
    );
};

export default ResetForm;
