// New version
import { useState } from "react";

import Record from "../../helpers/recordLayer";
import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import { RECORD_COLUMNS } from "./constants";
import PsPatternChart from "../ps-pattern-chart/PsPatternChart.js";
import "./PsPatternGrid.css";

const PsPatternGrid = ({ parentId, queryFilter, childToParent, parentToChildEvent, maxRecords, showTitle, tagLine }) => {
    const [mode, setMode] = useState("init");
    const [view, setView] = useState("grid");
    const [orderBy, setOrderBy] = useState("relevance");
    const [orderDirection, setOrderDirection] = useState("desc");
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatchEvent = (event) => {
        childToParent(event);
    };

    const bubbleEvent = (event) => {
        let stopPropagation = false;
        if (!stopPropagation) {
            childToParent(event);
        }
    };

    const parseResponse = (response) => {
        return response.map(({ id, compositionId, name, type, treeHash, relevance, createdOn, lastRunOn, container, overridden }) => ({
            id,
            compositionId,
            name,
            type,
            namePlain: Record.removeMarkup(name),
            nameMarkup: Record.markupToHtml(name),
            treeHash,
            relevance,
            createdOn,
            lastRunOn,
            container,
            containerName: container?.name,
            sourceName: container?.source?.name,
            title: container?.source?.name + ": " + container?.name,
            overridden,
        }));
    };

    const handleRecordRowAction = (action, row) => {
        switch (action) {
            case "details":
                dispatchEvent({ type: "dataCompEvent", action: "viewDetails", pattern: row });
                break;
            case "viewSource":
                dispatchEvent({ type: "navigation", parentId: "root", module: "core", obj: "source", id: row.container?.source?.id });
                break;
            case "viewContainer":
                dispatchEvent({ type: "navigation", parentId: row.container?.source.id, module: "store", obj: "container", id: row.container?.id });
                break;
            default:
                break;
        }
    };

    return (
        <PsRecordGrid
            module="relate"
            object="pattern"
            queryFilter={queryFilter}
            gridComponent={PsPatternChart}
            gridItemClass="grid-item-pattern"
            showTitle={showTitle}
            tagLine={tagLine}
            emptyLine="No results found for the specified criteria"
            viewOptions={[
                { label: "Table", value: "table" },
                { label: "Grid", value: "grid" },
            ]}
            changeView
            recordLabel="Pattern"
            recordLabelPlural="Patterns"
            parseResponse={parseResponse}
            parentId={parentId}
            mode={mode}
            onModeChange={(value) => setMode(value)}
            view={view}
            onViewChange={setView}
            orderBy={orderBy}
            onOrderByChange={(value) => setOrderBy(value)}
            orderDirection={orderDirection}
            onOrderDirectionChange={(value) => setOrderDirection(value)}
            loading={loading}
            onLoadingChange={(value) => setLoading(value)}
            showLoadMore
            hasMore={hasMore}
            onHasMoreChange={setHasMore}
            recordColumns={RECORD_COLUMNS}
            maxRecords={maxRecords || 12}
            onRecordRowAction={handleRecordRowAction}
            childToParent={bubbleEvent}
            parentToChildEvent={parentToChildEvent}
        />
    );
};

export default PsPatternGrid;
