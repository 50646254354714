import { useState, useEffect } from "react";
import { Button, IconSettings, Input, InputIcon, Spinner } from "@salesforce/design-system-react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { applyInstance } from "../../../services/api";

import { GENERAL_ERROR, INVALID_EMAIL, PASSWORD_MISMATCH } from "../../../constants/ToastMessages";
import { isValidEmail } from "../../../utils";
import "../../Pages.css";
import RegionSelection from "../../../components/region-selection";
import useToastContext from "../../../context/useToastContext";
import useAuthContext from "../../../context/useAuthContext";

const SignupForm = ({ completeSignup }) => {
    const { login, setLogin, clearLoginSecrets } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { addToast } = useToastContext();

    useEffect(() => {
        clearLoginSecrets();
    }, []);

    const handleInputChange = (e, item) => {
        setLogin((prev) => ({ ...prev, [item]: e.target.value }));
    };

    const handleSubmitUserForm = async (event) => {
        try {
            event.preventDefault();

            if (!login || !login.username || !login.password || !login.confirmPassword || !login.email || !login.instance) {
                addToast("warning", "Missing Details", "Please fill out all fields.");
                return;
            }

            if (!isValidEmail(login.email)) {
                addToast("warning", "Invalid Email Address", INVALID_EMAIL);
                return;
            }

            if (login.password !== login.confirmPassword) {
                addToast("warning", "Password Mismatch", PASSWORD_MISMATCH);
                return;
            }

            setIsLoading(true);
            applyInstance(login.instance);
            const results = await Auth.signUp({ username: login.username, password: login.password, attributes: { email: login.email } });
            if (results) {
                completeSignup();
            } else {
                addToast("error", "Signup Failed", "Unable to start signup at this time. Please try again later.");
            }
        } catch (err) {
            console.error(err);
            addToast("error", "Error", err.message || GENERAL_ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    function passwordIcon(isVisible, setVisibility) {
        if (!isVisible) return <InputIcon assistiveText={{ label: "Hide Password" }} name="hide" onClick={() => setVisibility(true)} />;
        return <InputIcon assistiveText={{ label: "Show Password" }} name="preview" onClick={() => setVisibility(false)} />;
    }

    return (
        <IconSettings iconPath="/assets/icons">
            <form onSubmit={handleSubmitUserForm}>
                <p className="form-title"> Sign up with a new account</p>
                <RegionSelection />
                <div className="user-inputs">
                    <Input type="text" label="Username" value={login?.username || ""} onChange={(e) => handleInputChange(e, "username")} autoComplete="new-username" />
                </div>
                <div className="user-inputs">
                    <Input type="text" label="Email" value={login?.email || ""} onChange={(e) => handleInputChange(e, "email")} />
                </div>
                <div className="user-inputs">
                    <Input
                        label="Password"
                        value={login?.password || ""}
                        onChange={(e) => handleInputChange(e, "password")}
                        autoComplete="new-password"
                        type={showPassword ? "text" : "password"}
                        iconRight={passwordIcon(showPassword, setShowPassword)}
                    />
                </div>
                <div className="user-inputs">
                    <Input
                        type={showConfirmPassword ? "text" : "password"}
                        label="Confirm password"
                        value={login?.confirmPassword || ""}
                        onChange={(e) => handleInputChange(e, "confirmPassword")}
                        autoComplete="new-password"
                        iconRight={passwordIcon(showConfirmPassword, setShowConfirmPassword)}
                    />
                </div>
                <div className="login-signup-button">
                    <Button
                        type="submit"
                        label={<>Sign up {isLoading ? <Spinner size="small" /> : null}</>}
                        variant="brand"
                        disabled={isLoading || !login?.username || !login?.email || !login?.password || !login?.confirmPassword || !login?.instance}
                        style={{ width: "100%" }}
                    />
                </div>
                <nav className="login-signup-link">
                    Already have an account? <Link to="/login">Sign in</Link>
                </nav>
            </form>
        </IconSettings>
    );
};

export default SignupForm;
