import { Helmet } from "react-helmet";

import PsTopMenuBar from "../components/ps-top-menu-bar/PsTopMenuBar";
import useAuthContext from "../context/useAuthContext";
import AuthRouter from "./AuthRouter";
import UnAuthRouter from "./UnAuthRouter";

const CustomRouter = () => {
    const { isInitializing, isAuthenticated, account } = useAuthContext();
    const favicon = account?.settings?.faviconUrl || "https://framerusercontent.com/images/iSAwqV6l0xVu1KZEYpCFlEFItCw.png";

    return isInitializing ? null : (
        <div className="App">
            <Helmet>{<link rel="icon" href={favicon} />}</Helmet>
            <PsTopMenuBar />
            <div style={{ height: "40px" }}></div>
            <>{isAuthenticated ? <AuthRouter /> : <UnAuthRouter />}</>
        </div>
    );
};

export default CustomRouter;
