import { useEffect, useRef, useState } from "react";
import { Button, Dropdown, IconSettings } from "@salesforce/design-system-react";
import Combobox from "@salesforce/design-system-react/components/combobox";

import PsRecord from "../ps-record/PsRecord";
import Restriction from "../../utils/restriction";
import Record from "../../helpers/recordLayer";
import ProgressBarComponent from "../../ui/ProgressBarComponent";
import EditButtonIcon from "../../ui/EditButtonIcon";
import DualListBox from "../../ui/DualListBox";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import Modal from "../../ui/Modal";
import RecordConstants from "../../constants/RecordConstants";
import { formattedDateTime, formattedNumber } from "../../helpers";
import useToastContext from "../../context/useToastContext";

const PsField = (props) => {
    const [cmpState, setCmpState] = useState({
        recordLabel: "Field",
        recordModule: "pump",
        recordObject: "field",
        showDelete: true,

        loading: false,
        mode: "init",
        showEdit: true,
        record: {},

        showClearConfirmDialog: false,
        clearAction: "",
        showDeleteConfirmDialog: false,
        selectedValue: null,
        statusOptions: Object.values(RecordConstants.FIELD_STATUS),
    });

    // global toast
    const { addToast } = useToastContext();

    const cmpWorking = useRef({});
    const isFirstRender = useRef(true);

    useEffect(() => {
        cmpWorking.current = { ...cmpState };
        cmp.init();
    }, []);

    useEffect(() => {
        if (!props.parentToChildEvent) {
            return;
        }
        cmp.handleEvent(props.parentToChildEvent);
    }, [props.parentToChildEvent]);

    useEffect(() => {
        cmp.set("recordId", props.recordId);
        cmp.handleReload();
    }, [props.recordId]);

    const cmp = {
        handleReload: function () {
            try {
                PsRecord.getRecord(cmp);
            } catch (err) {
                console.error(err);
            }
        },

        handleRefresh: function () {
            try {
                cmp.handleReload();
            } catch (err) {
                console.error(err);
            }
        },

        handleEdit: function () {
            try {
                PsRecord.setMode(cmp, "edit");
            } catch (err) {
                console.error(err);
            }
        },

        handleCancel: function () {
            try {
                PsRecord.cancelRecord(cmp);
            } catch (err) {
                console.error(err);
            }
        },

        handleSubmit: function () {
            try {
                PsRecord.submitRecord(cmp);
            } catch (err) {
                console.error(err);
            }
        },

        handlePostDelete: function (cmp, success) {
            if (success) {
                cmp.actionSupply();
            }
        },

        handleDelete: function () {
            try {
                var record = cmp.get("record") || {};
                if (!record.sourceExists || record.type === "CSV File") {
                    cmp.set("showDeleteConfirmDialog", true);
                } else {
                    addToast(
                        "warning",
                        "Not Available",
                        'Fields can only be deleted when they are no longer present in the source.\nInstead, use the "Clear" action to remove the Field\'s data and set the Status to "Excluded" to stop loading new data.'
                    );
                }
            } catch (err) {
                console.error(err);
            }
        },

        selectRestrictions: function (cmp, event, helper) {
            try {
                // TODO
                // var options = event.getParam('value');
                // Record.setSelectedLabels(cmp, 'v.restrictionOptions', options, 'v.record.restrictionLabels');
            } catch (err) {
                console.error(err);
            }
        },

        handleAction: function (action) {
            try {
                switch (action) {
                    case "exclude-clear":
                        cmp.showClearConfirm(action);
                        break;
                    default:
                }
            } catch (err) {
                console.error(err);
            }
        },

        parseInputPlainText: function (record) {
            return (({ id, status }) => ({ id, status }))(record);
        },

        getDefaultRecord: function () {
            return {};
        },

        parseResponse: function (response) {
            // for status and setup section on Object page
            return response.map(({ id, name, type, status, restrictions, firstRunOn, lastRunOn, sourceExists, sourceIdentifier, isPrimaryKey, isChangeTracker, totalRecords, object }) => ({
                id,
                name,
                type,
                status,
                restrictions,
                firstRunOn,
                lastRunOn,
                sourceExists,
                sourceIdentifier,
                isPrimaryKey,
                isChangeTracker,
                totalRecords,
                objectId: object.id,
                connectorId: object.connector.id,
                runStatus: object.connector.runStatus,
                setupStatus: object.connector.setupStatus,
            }));
        },

        updateUI: function () {
            var record = cmp.get("record");
            var restrictions = record.restrictions || [];
            Restriction.update(cmp, record, restrictions);
        },

        // update restrictions
        postSubmit: function (record) {
            Restriction.merge(cmp, "fieldId", record);
        },

        init: function () {
            Restriction.loadRestrictionTypes(cmp);
        },

        // actionExcludeClear : function(cmp) {
        //     // confirmation
        //     if (!confirm("Clearing a Field will delete all its loaded data. Are you sure?")) {
        //         return;
        //     }
        //     if (!cmp.get('v.scriptsLoaded')) { return; }

        //     var previousMode = cmp.get('v.mode');
        //     this.setLoading(cmp);

        //     var fieldId = cmp.get('v.recordId');
        //     var objectId = cmp.get('v.record.objectId');
        //     var connectorId = cmp.get('v.record.connectorId');
        //     var newStatus = Record.FIELD_STATUS.EXCLUDED.value;

        //     var onSuccesAction = function(cmp) {
        //         Record.showToast(cmp, 'Connector Started', 'Field clearing started');
        //         cmp.set('v.record.runStatus', 'Running'); // doesn't get saved, but enables the 'Running' message box
        //         this.setMode(cmp, previousMode);
        //     };

        //     var onErrorAction = function(cmp) {
        //         Record.showToast(cmp, 'Clear Unsuccessful', 'Try clearing againg if the connector is already running', 'warning');
        //         this.setMode(cmp, previousMode);
        //     };

        //     var onErrorSubmit = function(cmp) { this.setMode(cmp, previousMode); }

        //     var onSuccesSubmit = function(cmp) {
        //         cmp.set('v.record.status', newStatus);
        //         Record.doAction(cmp, this, 'pump', 'connector', 'clear', {'id': connectorId, 'objectId': objectId, 'fieldId': fieldId}, onSuccesAction, onErrorAction);
        //     };

        //     var data = {'id': fieldId, status: newStatus};
        //     Record.submitRecord(cmp, this, 'pump', 'field', data, onSuccesSubmit, onErrorSubmit);
        // },

        actionExcludeClear: function () {
            try {
                var self = this;
                var record = cmp.get("record");
                // TODO: put in error messages for different onError in the above code
                var callback = function (cmp, success) {
                    if (success) {
                        cmp.set("record", { ...record, runStatus: "Running" }); // doesn't get saved, but enables the 'Running' message box
                        self.actionClear();
                    }
                };
                record.status = RecordConstants.OBJECT_STATUS.EXCLUDED.value;
                PsRecord.submitRecord(cmp, callback);
            } catch (err) {
                console.error(err);
            }
        },

        actionClear: function () {
            try {
                var previousMode = cmp.get("mode");
                PsRecord.setLoading(cmp);
                var fieldId = cmp.get("recordId");
                var record = cmp.get("record");
                var objectId = record.objectId;
                var connectorId = record.connectorId;

                var onSucces = function () {
                    addToast("info", "Connector Started", "Field clearing started");

                    // cmp.set("v.record.runStatus", "Running"); // doesn't get saved, but enables the 'Running' message box
                    cmp.set("record", { ...record, runStatus: "Running" });
                    PsRecord.setMode(cmp, previousMode);
                };
                Record.doAction("pump", "connector", "clear", { id: connectorId, objectId, fieldId }, onSucces);
            } catch (err) {
                console.error(err);
            }
        },

        actionSupply: function () {
            try {
                var previousMode = cmp.get("mode");
                PsRecord.setLoading(cmp);
                // var containerId = cmp.get('v.recordId');

                var onSucces = function () {
                    // IMPROVEMENT: callback for doAction is never called (maybe because the component is already refreshed before this returns?)
                    addToast("info", "Data Processing Started", "Started processing data, this may take a few moments");
                    PsRecord.setMode(cmp, previousMode);
                };
                Record.doAction("store", "data", "supply", {}, onSucces);
            } catch (err) {
                console.error(err);
            }
        },

        setParent: function (record) {
            var parentId = record?.objectId || record?.object?.id;
            cmp.set("parentId", parentId);
            return parentId;
        },

        get: (key) => {
            if (cmpWorking.current.hasOwnProperty(key)) return cmpWorking.current[key];
            return props[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setCmpState((prev) => ({ ...prev, [key]: value }));
        },

        handleEvent: (event) => {
            if (event.type === "reload") {
                cmp.handleReload();
                //props.parentCmp.set("parentToChildEvent", {});
            }
        },

        dispatchEvent: function (event) {
            props.childToParent(event);
        },

        bubbleEvent: function (event) {
            let stopPropagation = false;

            if (!stopPropagation) {
                props.childToParent(event);
            }
        },

        handleReset: function () {
            try {
                PsRecord.getRecord(cmp);
            } catch (err) {
                console.error(err);
            }
        },

        showClearConfirm: function (action) {
            cmp.set("clearAction", action);
            cmp.set("showClearConfirmDialog", true);
        },

        cancelClearRecord: function () {
            cmp.set("clearAction", "");
            cmp.set("showClearConfirmDialog", false);
        },

        confirmClearRecord: function () {
            var action = cmp.get("clearAction");
            cmp.set("showClearConfirmDialog", false);
            cmp.set("clearAction", "");
            if (action === "exclude-clear") {
                cmp.actionExcludeClear();
            } else if (action === "clear") {
                cmp.actionClear();
            }
        },

        handleSelectStatus: function (event, data) {
            if (data.selection.length === 0) {
                return;
            }

            let record = cmp.get("record");
            record = { ...record, status: data.selection[0].value };
            cmp.set("record", record);
        },

        handleRestrictionValuesChange: function (value, process) {
            try {
                const record = cmp.get("record");
                const restrictionValues = record.restrictionValues;

                let updatedRestrictionValues;
                if (process === "optionsToSelected") {
                    updatedRestrictionValues = restrictionValues && restrictionValues.includes(value) ? restrictionValues : [...(restrictionValues || []), value];
                } else {
                    updatedRestrictionValues = restrictionValues && restrictionValues.length > 0 ? restrictionValues.filter((item) => item !== value) : [];
                }
                cmp.set("record", {
                    ...record,
                    restrictionValues: updatedRestrictionValues,
                });
                cmp.set("selectedValue", null);
            } catch (err) {
                console.error(err);
            }
        },

        handleChangeSelectedValue: function (selected) {
            cmp.set("selectedValue", selected);
        },

        cardActions: function () {
            const disabled = cmpState.record?.runStatus === "Running";
            return (
                <>
                    {cmpState.mode === "view" && (
                        <IconSettings iconPath="/assets/icons">
                            <Dropdown
                                assistiveText={{ icon: "More Options" }}
                                iconCategory="utility"
                                iconName="down"
                                iconVariant="border-filled"
                                onSelect={(selected) => cmp.handleAction(selected.value)}
                                options={[
                                    {
                                        label: "Exclude and Clear",
                                        value: "exclude-clear",
                                        leftIcon: {
                                            category: "utility",
                                            name: "clear",
                                        },
                                        disabled: disabled,
                                    },
                                ]}
                                width="xx-small"
                                align="right"
                                menuAlignment="right"
                                className="slds-var-m-horizontal_xxx-small"
                                alternativeText="Actions"
                            />
                        </IconSettings>
                    )}
                </>
            );
        },

        body: function () {
            // Combobox gives warning without id
            const statusOptionsWithId =
                cmpState.statusOptions && cmpState.statusOptions.length > 0
                    ? cmpState.statusOptions.map((item) => ({
                          ...item,
                          id: item.label,
                      }))
                    : cmpState.statusOptions;

            return (
                <div className="slds-form slds-var-m-around_medium" role="list">
                    {/* <!-- Connector Running --> */}
                    {cmpState.mode === "view" && cmpState.record?.runStatus === "Running" && (
                        <>
                            <h3 className="slds-section-title--divider slds-m-top_medium">Connector Running</h3>
                            <div className="message">
                                <p>The Connector is running.</p>
                                <p>Use the "Check Status" or the refresh buttons to check progress.</p>
                                <div>
                                    <div>
                                        <p>The Connector is running.</p>
                                        <p>Use the "Check Status" or the refresh buttons to check progress.</p>

                                        <Button
                                            className="slds-m-vertical_small"
                                            disabled={cmpState.loading}
                                            label="Check Status"
                                            title="Check Status"
                                            onClick={cmp.handleRefresh}
                                            variant="outline-brand"
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    <h3 className="slds-section-title--divider slds-m-top_medium">Field Details</h3>
                    <div className="slds-form__row">
                        {/* <!-- Name --> */}
                        <div className="slds-form__item" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">
                                {cmpState.mode === "init" && <ProgressBarComponent />}
                                {(cmpState.mode === "view" || cmpState.mode === "edit" || cmpState.mode === "new") && (
                                    <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                        <span className="slds-form-element__label">Name</span>
                                        <div className="slds-form-element__control">
                                            <div className="slds-form-element__static">{cmpState.record?.name}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* <!-- Status --> */}
                        <div className="slds-form__item" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">
                                {cmpState.mode === "init" && <ProgressBarComponent />}
                                {cmpState.mode === "view" && (
                                    <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                        <span className="slds-form-element__label">Status</span>
                                        <div className="slds-form-element__control">
                                            <div className="slds-form-element__static">{cmpState.record?.status}</div>
                                            <div className="slds-button slds-button__icon slds-button__icon_hint">
                                                <EditButtonIcon handleEdit={cmp.handleEdit} />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* <lightning:combobox aura:id="checkField" name="status" label="Status" placeholder="--Please Select--" value="{!v.record.status}" options="{!v.statusOptions}" /> */}

                                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                                    <Combobox
                                        events={{
                                            onSelect: (event, data) => cmp.handleSelectStatus(event, data),
                                        }}
                                        labels={{
                                            label: "Status",
                                            placeholder: "--Please Select--",
                                        }}
                                        menuPosition="relative"
                                        options={statusOptionsWithId}
                                        selection={[statusOptionsWithId.find((option) => option.value === cmpState.record?.status)]}
                                        value={cmpState.record?.status}
                                        variant="readonly"
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="slds-form__row">
                        {/* <!-- Source Identifier --> */}
                        {cmpState.mode !== "edit" && (
                            <div className="slds-form__item" role="listitem">
                                <div className="slds-form-element slds-form-element_stacked">
                                    {cmpState.mode === "init" && <ProgressBarComponent />}
                                    {cmpState.mode === "view" && (
                                        <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                            <span className="slds-form-element__label">Source Identifier</span>
                                            <div className="slds-form-element__control">
                                                <div className="slds-form-element__static">{cmpState.record?.sourceIdentifier}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* <!-- Restrictions --> */}
                        <div className="slds-form__item" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">
                                {cmpState.mode === "init" && <ProgressBarComponent />}
                                {cmpState.mode === "view" && (
                                    <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                        <span className="slds-form-element__label">Restrictions</span>
                                        <div className="slds-form-element__control">
                                            <div className="slds-form-element__static">{cmpState.record?.restrictionLabels}</div>
                                            <div className="slds-button slds-button__icon slds-button__icon_hint">
                                                <EditButtonIcon handleEdit={cmp.handleEdit} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                                    <>
                                        {/*             <lightning:dualListbox name="restrictions" label="Restrictions" options="{!v.restrictionOptions}" 
                    disableReordering="true" sourceLabel="Available" selectedLabel="Selected" value="{!v.record.restrictionValues}" 
                    requiredOptions="{!v.record.restrictionRequired}" onchange="{!c.selectRestrictions}" />
                     */}
                                        <DualListBox
                                            handleSelectedValuesChange={cmp.handleRestrictionValuesChange}
                                            handleChangeSelectedValue={cmp.handleChangeSelectedValue}
                                            handleSelectedValueUpOrDown={cmp.handleSelectedValueUpOrDown}
                                            label="Restrictions"
                                            options={cmpState.restrictionOptions}
                                            disableReordering
                                            sourceLabel="Available"
                                            selectedLabel="Selected"
                                            value={cmpState.record?.restrictionValues}
                                            requiredOptions={cmpState.record?.restrictionRequired}
                                            fieldLevelHelp=""
                                            valuesOptions={
                                                cmpState.record?.restrictionValues && cmpState.record?.restrictionValues.length > 0
                                                    ? cmpState.restrictionOptions.filter((option) => !cmpState.record?.restrictionValues.includes(option.value))
                                                    : cmpState.restrictionOptions
                                            }
                                            selectedOptions={
                                                cmpState.record?.restrictionValues && cmpState.record?.restrictionValues.length > 0
                                                    ? cmpState.record?.restrictionValues
                                                          .map((selectedValue) => {
                                                              const option = cmpState.restrictionOptions.find((opt) => opt.value === selectedValue);
                                                              return option ? { value: option.value, label: option.label } : null;
                                                          })
                                                          .filter(Boolean)
                                                    : []
                                            }
                                            selectedValue={cmpState.selectedValue}
                                            hasNoUpDownButton
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="slds-form__row">
                        {/* <!-- Source Primary Key --> */}
                        {cmpState.mode !== "edit" && (
                            <div className="slds-form__item" role="listitem">
                                <div className="slds-form-element slds-form-element_stacked">
                                    {cmpState.mode === "init" && <ProgressBarComponent />}
                                    {cmpState.mode === "view" && (
                                        <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                            <span className="slds-form-element__label">Primary Key</span>
                                            <div className="slds-form-element__control">
                                                <div className="slds-form-element__static">
                                                    <CheckAndCloseIcons selectedItem={cmpState.record?.primaryKey} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* <!-- Source Change Tracker --> */}
                        {cmpState.mode !== "edit" && (
                            <div className="slds-form__item" role="listitem">
                                <div className="slds-form-element slds-form-element_stacked">
                                    {cmpState.mode === "init" && <ProgressBarComponent />}
                                    {cmpState.mode === "view" && (
                                        <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                            <span className="slds-form-element__label">Change Tracker</span>
                                            <div className="slds-form-element__control">
                                                <div className="slds-form-element__static">
                                                    <CheckAndCloseIcons selectedItem={cmpState.record?.changeTracker} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {cmpState.mode === "view" && cmpState.record?.totalRecords != null && (
                        <>
                            <h3 className="slds-section-title--divider slds-m-top_medium">Status</h3>

                            <div className="slds-form__row">
                                {/* <!-- Source Exists --> */}
                                <div className="slds-form__item" role="listitem">
                                    <div className="slds-form-element slds-form-element_stacked">
                                        {cmpState.mode === "init" && <ProgressBarComponent />}
                                        {cmpState.mode === "view" && (
                                            <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                                <span className="slds-form-element__label">Found in Source</span>
                                                <div className="slds-form-element__control">
                                                    <div className="slds-form-element__static">
                                                        <CheckAndCloseIcons selectedItem={cmpState.record?.sourceExists} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* <!-- TotalRecords --> */}
                                <div className="slds-form__item" role="listitem">
                                    <div className="slds-form-element slds-form-element_stacked">
                                        {cmpState.mode === "init" && <ProgressBarComponent />}
                                        {cmpState.mode === "view" && (
                                            <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                                <span className="slds-form-element__label">Number of Records Loaded</span>
                                                <div className="slds-form-element__control">
                                                    <div className="slds-form-element__static">{formattedNumber(cmpState.record?.totalRecords)}</div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="slds-form__row">
                                {/* <!-- First Loaded--> */}
                                <div className="slds-form__item" role="listitem">
                                    <div className="slds-form-element slds-form-element_stacked">
                                        {cmpState.mode === "init" && <ProgressBarComponent />}
                                        {cmpState.mode === "view" && (
                                            <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                                <span className="slds-form-element__label">First Run On</span>
                                                <div className="slds-form-element__control">
                                                    {/* <div className="slds-form-element__static"><lightning:formattedDateTime value="{!v.record.firstRunOn}" year="numeric" month="long" day="numeric" hour="2-digit" minute="2-digit" second="2-digit" /></div> */}
                                                    {formattedDateTime(cmpState.record?.firstRunOn)}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* <!-- Last Loaded --> */}
                                <div className="slds-form__item" role="listitem">
                                    <div className="slds-form-element slds-form-element_stacked">
                                        {cmpState.mode === "init" && <ProgressBarComponent />}
                                        {cmpState.mode === "view" && (
                                            <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                                <span className="slds-form-element__label">Last Run On</span>
                                                <div className="slds-form-element__control">
                                                    {/* <div className="slds-form-element__static"><lightning:formattedDateTime value="{!v.record.lastRunOn}" year="numeric" month="long" day="numeric" hour="2-digit" minute="2-digit" second="2-digit" /></div> */}
                                                    {formattedDateTime(cmpState.record?.lastRunOn)}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            );
        },
    };

    return (
        <>
            {cmpState.showClearConfirmDialog ? (
                <Modal
                    apply={() => cmp.confirmClearRecord()}
                    cancel={() => cmp.cancelClearRecord()}
                    header="Confirmation"
                    modalContent="Clearing an Object will delete all its loaded data. Are you sure?"
                    applyButtonContent="OK"
                />
            ) : null}

            {cmpState.showDeleteConfirmDialog ? (
                <Modal
                    apply={() => PsRecord.confirmDeleteRecord(cmp, cmp.handlePostDelete)}
                    cancel={() => PsRecord.cancelDeleteRecord(cmp)}
                    header="Confirmation"
                    modalContent="Deleting this Record will also delete all its associated loaded data. Are you sure?"
                    applyButtonContent="Delete"
                />
            ) : null}
            {PsRecord.render(cmp, cmpState)}
        </>
    );
};

export default PsField;
