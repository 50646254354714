import { FilterOption, FilterType, SelectedContainerType } from "../../../types";
import Record from "../../../helpers/recordLayer";
import { getUTCDateTime } from "../../../utils";

type SourceType = {
    id?: string;
    name?: string;
};

type ContainerType = {
    id?: string;
    name?: string;
    source?: SourceType;
};

type RecordType = {
    id?: string;
    name?: string;
    container?: ContainerType;
    leftContainer?: ContainerType;
    source?: SourceType;
};

type BreadCrumbType = {
    id?: string;
    name?: string;
}[];

export const getUseInput = (operatorOptions: FilterOption[], operator: string, valuesOptions: FilterOption[]) => {
    // use MultiSelect if values are predefined, otherwise use FreeText
    const options = operatorOptions || [];
    const option = options.find((i) => i.value === operator) || { useInput: "" };
    let useInput = option.useInput;
    if (useInput === "ListValues") {
        useInput = valuesOptions && valuesOptions.length ? "MultiSelect" : "FreeText";
    }
    return useInput;
};

// Type for the function
export const getBreadCrumb = (object: "key" | "chain" | "container", record: RecordType): BreadCrumbType => {
    let container: ContainerType = {};
    let source: SourceType = {};

    if (object === "key") {
        container = record.container || {};
        source = container.source || {};
        return [
            { id: source.id, name: source.name },
            { id: container.id, name: container.name },
            { id: record.id, name: record.name },
        ];
    } else if (object === "chain") {
        container = record.leftContainer || {};
        source = container.source || {};
        return [
            { id: source.id, name: source.name },
            { id: container.id, name: container.name },
            { id: record.id, name: record.name },
        ];
    } else if (object === "container") {
        source = record.source || {};
        return [
            { id: source.id, name: source.name },
            { id: record.id, name: record.name },
        ];
    }

    return [];
};

export const getTitle = (breadcrumb: BreadCrumbType) => {
    return breadcrumb
        .map((item) => item.name)
        .filter((item) => item != null)
        .join("->");
};

export const applyOperator = (optionsAttribute: FilterOption[], value: string, settings: {}) => {
    let operatorOptions = optionsAttribute || [];
    let operatorOption = operatorOptions.find((i) => i.value === value) || { operator: "", settings: {} };

    // if the option has an operator, store it as the filter's operator, and store the option as alias
    const optionOperator = operatorOption.operator;
    if (optionOperator) {
        value = optionOperator;
    }

    // if the option has its own settings, apply these to the settings
    let optionSettings = operatorOption.settings;
    if (optionSettings) {
        Object.assign(settings, optionSettings);
    }
    return value;
};

export const filterJson = (
    filter: FilterType,
    operatorOptions: FilterOption[],
    operator: string,
    valuesOptions: FilterOption[],
    minDate: string,
    maxDate: string,
    selectedPreset: string,
    presetOptions: FilterOption[],
    selectedValues: string[],
    minDateAmount: string | number,
    maxDateAmount: string | number,
    minDateUnit: string | number,
    maxDateUnit: string | number,
    minNumber: string | number,
    maxNumber: string | number,
    scope: FilterOption | string,
    selectedKey: FilterType,
    selectedContainer: SelectedContainerType,
    selectedChain: { id: "" },
    freeTextValues: string
) => {
    let updatedFilter = filter;
    let updatedOperator = operator;
    let settings = {
        alias: "",
        preset: "",
        min: "" as string | number,
        max: "" as string | number,
        values: [] as any[],
        inputs: [] as any[],
    };
    let useInput = getUseInput(operatorOptions, updatedOperator, valuesOptions);

    // alias
    settings.alias = updatedOperator;
    updatedOperator = applyOperator(operatorOptions, updatedOperator, settings);

    // parse input fields to settings
    if (useInput === "Preset") {
        const preset = selectedPreset;
        settings.alias = updatedOperator;
        updatedOperator = applyOperator(presetOptions, preset, settings);
        settings.preset = preset;
    } else if (useInput === "MultiSelect") {
        const values = selectedValues;
        // 'settings.values = null' if no values selected (which is interpreted as 'all', and allows for creating template Global filters)
        settings.values = values && values.length ? values : null;
    } else if (useInput === "FreeText") {
        // NB: Record.parseCSV returns 'null' for empty string (which is interpreted as 'all', and allows for creating template Global filters)
        settings.values = Record.parseCSV(freeTextValues);
    } else if (useInput === "BetweenDateTime") {
        // settings.min = getUTCDateTime(minDate);
        settings.min = minDate;

        // settings.max = getUTCDateTime(maxDate);
        settings.max = maxDate;
    } else if (useInput === "RelativeDateTime") {
        let minAmount = minDateAmount;
        minAmount = minAmount != null ? String(minAmount) : "";
        let maxAmount = maxDateAmount;
        maxAmount = maxAmount != null ? String(maxAmount) : "";
        const minUnit = minDateUnit;
        const maxUnit = maxDateUnit;
        settings.min = minAmount && minUnit ? minAmount + " " + minUnit : null;
        settings.max = maxAmount && maxUnit ? maxAmount + " " + maxUnit : null;
    } else if (useInput === "BetweenNumber") {
        settings.min = minNumber || minNumber === 0 ? Number(minNumber) : null;
        settings.max = maxNumber || maxNumber === 0 ? Number(maxNumber) : null;
    }

    // create / update filter settings
    const key = selectedKey;
    const container = selectedContainer;
    const chain = selectedChain;
    let inputs;
    const node0 = (updatedFilter.inputs || [])[0] || {};
    const node1 = (node0.inputs || [])[0] || {};
    if (chain) {
        const load = {
            id: node1.id,
            type: "Load",
            argOrder: 0,
            rootOrder: 0,
            keyId: key?.id,
            key,
            inputs: [] as any[],
        };
        const join = {
            id: node0.id,
            type: "Join",
            argOrder: 0,
            rootOrder: 0,
            chainId: chain.id,
            chain,
            inputs: [load],
        };
        inputs = [join];
    } else {
        const load = {
            id: node0.id,
            type: "Load",
            argOrder: 0,
            rootOrder: 0,
            keyId: key?.id,
            key,
            inputs: [] as any[],
        };
        inputs = [load];
    }

    // include the selected Key with the Filter, so that other components (e.g., FilterSet) can read its values without having to query the API again
    const filterKey = selectedKey || {};

    updatedFilter = (({ id, floatingId, name, type, active, acceptMissing }) => ({
        id,
        floatingId,
        name,
        type,
        active,
        scope,
        acceptMissing,
        containerId: container?.id,
        container,
        operator: updatedOperator,
        settings,
        inputs,
        filterKey,
    }))(updatedFilter);

    return updatedFilter;
};
