import { useEffect, useState } from "react";
import { Spinner, Tooltip, Textarea } from "@salesforce/design-system-react";
import Icon from "@salesforce/design-system-react/components/icon";
import Button from "@salesforce/design-system-react/components/button";

import "../PsPatternChart.css";
import RecordConstants from "../../../constants/RecordConstants.js";
import Record from "../../../helpers/recordLayer";
import Field from "../../../ui/wrappers/Field";
import { checkRequiredField } from "../../../utils/index2";
import useAuthContext from "../../../context/useAuthContext";
import { truncateText } from "../../../utils";

const Description = ({ pattern, data, onPatternChange }) => {
    const { account, hasGenerativeAIAccess } = useAuthContext();
    const [generativeAIData, setGenerativeAIData] = useState(false);
    const [generativeAIDebug, setGenerativeAIDebug] = useState(false);
    const [showSection, setShowSection] = useState(true);
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState("view");
    const [record, setRecord] = useState();
    const MAX_LENGTH = 1024;

    useEffect(() => {
        setRecord({ ...pattern });
    }, [pattern]);

    useEffect(() => {
        const { Data: DATA, Debug: DEBUG } = RecordConstants.ACCOUNT_SETTINGS_GENERATIVE_AI;
        const genAiDataAccess = hasGenerativeAIAccess(DATA);
        const genAiEnabled = account?.settings?.enableGenerativeAIExplain;
        setGenerativeAIData(genAiDataAccess && genAiEnabled);
        setGenerativeAIDebug(hasGenerativeAIAccess(DEBUG));
    }, [account]);

    const handleExplain = () => {
        if (!data) {
            setRecord((prev) => ({ ...prev, description: "No data." }));
            return;
        }

        var onSuccess = function (response) {
            const description = truncateText(response[0] || "", MAX_LENGTH);
            setRecord((prev) => ({ ...prev, description }));
            setLoading(false);
        };

        var onError = function (response) {
            console.error(response);
            setRecord((prev) => ({ ...prev, description: "Error while processing." }));
            setLoading(false);
        };

        setLoading(true);
        const body = { data, plotOptions: pattern?.options };
        const debugPrompt = record.debugPrompt;
        if (generativeAIDebug && debugPrompt) {
            body.debug = true;
            body.prompt = debugPrompt;
        }
        var bodyString = JSON.stringify(body);
        Record.getRecord("present", "explain", "", {}, bodyString, "PUT", onSuccess, onError);
    };

    const handleCancel = (reset) => {
        if (reset) {
            setRecord({ ...pattern });
        }
        setMode("view");
    };

    const handleSave = () => {
        onPatternChange({ ...pattern, description: record.description, original: record?.original, hasOverride: record?.hasOverride, overridden: record?.overridden });

        setMode("view");
    };

    const showGenerativeAI = data && generativeAIData;

    const generativeAIButton = showGenerativeAI && (
        <Tooltip
            id="tooltip"
            align="bottom"
            content={<div className="slds-p-around_xx-small">This description will be generated by an AI with limited context and may not correctly reflect your data or apply to your situation.</div>}
        >
            <Button iconCategory="utility" iconName="magicwand" iconPosition="left" variant="outline-brand" label="Explain with AI" title="Explain with AI" onClick={(e) => handleExplain(e)} />
        </Tooltip>
    );

    return (
        pattern?.inputs && (
            <div className="slds-m-horizontal_x-small" style={{ flexBasis: "300px", flexGrow: 1, minWidth: "300px" }}>
                <div className={`slds-section ${showSection ? "slds-is-open" : "slds-is-close"}`}>
                    <h3>
                        <button className="slds-button slds-section__title-action" onClick={() => setShowSection(!showSection)}>
                            <span>
                                <Icon assistiveText={{ label: "switch section" }} category="utility" name="switch" size="x-small" className="slds-section__title-action-icon slds-button__icon_left" />
                            </span>
                            <span>Description</span>
                        </button>
                    </h3>

                    <div className="slds-section__content slds-is-relative">
                        {loading && <Spinner assistiveText={{ label: "Loading" }} />}

                        <Field
                            setRecord={setRecord}
                            mode={mode}
                            value={
                                <span className={pattern?.description ? "" : "slds-text-color_weak"} onClick={() => setMode("edit")}>
                                    {pattern?.description ? pattern?.description : "Enter a description..."}
                                </span>
                            }
                            onEdit={() => setMode("edit")}
                            onSave={handleSave}
                            onCancel={handleCancel}
                            fieldName="description"
                            isEditable
                            canOverride
                            hasOverride={record?.hasOverride?.description}
                            checkValidity={checkRequiredField}
                            inPopover
                            popover={{ isVisible: true, isInline: false }}
                            isFormItem
                            buttons={generativeAIButton}
                            header={
                                generativeAIDebug && (
                                    <Textarea
                                        autoFocus
                                        value={record.debugPrompt || ""}
                                        placeholder="Enter an LLM prompt"
                                        name="debugPrompt"
                                        onChange={(e) => setRecord({ ...record, debugPrompt: e.target.value })}
                                    />
                                )
                            }
                            body={
                                <Textarea
                                    autoFocus
                                    value={record?.description || ""}
                                    placeholder={"Enter a description" + (showGenerativeAI ? " or explain with AI" : "")}
                                    name="description"
                                    maxLength={`${MAX_LENGTH}`}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        )
    );
};

export default Description;
