import "./PsUserFormBody.css";
import { Input } from "@salesforce/design-system-react";
import Combobox from "@salesforce/design-system-react/components/combobox";
import useAuthContext from "../../../context/useAuthContext";
import EditableFieldControl from "../../../ui/wrappers/EditableFieldControl";
import { useMemo } from "react";
import CheckAndCloseIcons from "../../ps-key/components/CheckAndCloseIcons";
import Toggle from "../../../ui/Toggle";
import RecordConstants from "../../../constants/RecordConstants";
const { ACCOUNT_SETTINGS_DATE_TIME_FORMATS: formats, ACCOUNT_SETTINGS_DEFAULT_COLOR_MAP: colorMap } = RecordConstants;

// PsUserBody props type = {mode: "init"|"view"|"new"|"edit"; record:RecordType; fieldsError:{name:string; email:string}; handleEdit?:()=>void, handleFieldChange?:(e: input_event)=>void; getParentStateByKey?:(key:string)=>any}
// type RecordType = {name:string; email:string; id?:string; type?:string; status?:string}
const PsUserFormBody = ({
    mode,
    record,
    fieldsError,
    handleEdit = () => {
        return;
    },
    handleFieldChange = () => {
        return;
    },
    getParentStateByKey = () => {
        return;
    },
}) => {
    const { profile } = useAuthContext();

    const statusOptions = getParentStateByKey("statusOptions")?.map((item) => ({ ...item, id: item.label })) || [];
    const userTypesOptions = getParentStateByKey("userTypesOptions")?.map((item) => ({ ...item, id: item.label })) || [];
    const colorOptions = useMemo(() => Object.values(colorMap).map((opt) => ({ label: opt.label, id: opt.value })), []);

    function findOption(options, value) {
        return options.find((option) => `${option?.id}`.toLowerCase() === `${value}`.toLowerCase());
    }

    function onChangeSettings(e) {
        const settings = record?.settings || {};
        const { name, value } = e.target;
        settings[name] = value;
        let updateSettings = { ...settings, [name]: value };
        // Remove the keys for dateTimeFormat significantDigits defaultColorMap if the value is empty
        if ((name === "dateTimeFormat" || name === "significantDigits" || name === "defaultColorMap") && value === "" && !settings?.[name]) {
            delete updateSettings[name];
        }
        // set Settings to null if there is no keys in the object
        if (!Object.keys(updateSettings).length) {
            updateSettings = null;
        }

        handleFieldChange({ target: { name: "settings", value: updateSettings } });
    }

    return (
        <div className="slds-form slds-m-around_medium ps-user-form-container" role="list">
            <h3 className="slds-section-title--divider slds-m-top_medium">User Details</h3>
            <div className="ps-user-form">
                <div className="slds-form__row">
                    <EditableFieldControl
                        value={record?.name || ""}
                        label="Display Name"
                        isEdit={mode === "new" || mode === "edit"}
                        setIsEdit={() => handleEdit()}
                        formElement={
                            <Input
                                id="name"
                                name="name"
                                label="Display Name"
                                required
                                value={record?.name || ""}
                                onChange={handleFieldChange}
                                errorText={!!getParentStateByKey("missingRequiredFields")?.includes("record.name") ? fieldsError.name : ""}
                            />
                        }
                    />
                    <EditableFieldControl
                        value={record?.email || ""}
                        label="Email"
                        isEdit={mode === "new" || mode === "edit"}
                        setIsEdit={() => handleEdit()}
                        formElement={
                            <Input
                                id="email"
                                name="email"
                                autoComplete="off"
                                label="Email"
                                required
                                value={record?.email || ""}
                                onChange={handleFieldChange}
                                errorText={!!getParentStateByKey("missingRequiredFields")?.includes("record.email") ? fieldsError.email : ""}
                            />
                        }
                    />
                </div>
                <div className="slds-form__row">
                    <EditableFieldControl
                        value={record?.type || ""}
                        label="Type"
                        isEdit={mode === "new" || mode === "edit"}
                        setIsEdit={() => handleEdit()}
                        isEditable={record?.id !== profile?.id}
                        formElement={
                            <Combobox
                                id="type"
                                events={{
                                    onSelect: (_event, data) => {
                                        if (data.selection.length === 0) return;
                                        handleFieldChange({ target: { name: "type", value: data.selection[0].value } }); // representing of input event so we can use the handleFieldChange function
                                    },
                                }}
                                labels={{ name: "type", label: "Type", placeholder: "--Please Select--" }}
                                menuPosition="relative"
                                options={userTypesOptions}
                                selection={[userTypesOptions.find((option) => option.value === record?.type)]}
                                value={record?.status}
                                variant="readonly"
                            />
                        }
                    />

                    <EditableFieldControl
                        value={record?.status || ""}
                        label="Status"
                        isEdit={mode === "new" || mode === "edit"}
                        setIsEdit={() => handleEdit()}
                        isEditable={!(record?.id === profile?.id || mode === "new")}
                        formElement={
                            <Combobox
                                events={{
                                    onSelect: (_event, data) => {
                                        if (data.selection.length === 0) return;
                                        handleFieldChange({ target: { name: "status", value: data.selection[0].value } }); // representing of input event so we can use the handleFieldChange function
                                    },
                                }}
                                labels={{ name: "status", label: "Status", placeholder: "--Please Select--" }}
                                menuPosition="relative"
                                options={statusOptions}
                                selection={[statusOptions.find((option) => option.value === record?.status)]}
                                value={record?.status || ""}
                                variant="readonly"
                            />
                        }
                    />
                </div>
            </div>

            <h3 className="slds-section-title--divider slds-m-top_medium">Settings</h3>
            <div className="slds-form__row">
                <EditableFieldControl
                    value={<CheckAndCloseIcons selectedItem={record?.settings?.preferBarOverDoughnut} />}
                    label="Prefer Bar Over Doughnut"
                    isEdit={mode === "new" || mode === "edit"}
                    setIsEdit={() => handleEdit()}
                    formElement={
                        <Toggle
                            active={!!record?.settings?.preferBarOverDoughnut}
                            label="Prefer Bar Over Doughnut"
                            setActive={() => onChangeSettings({ target: { name: "preferBarOverDoughnut", value: !record?.settings?.preferBarOverDoughnut } })}
                        />
                    }
                />
                <EditableFieldControl
                    value={<CheckAndCloseIcons selectedItem={record?.settings?.disableDropOutliers} />}
                    label="Disable Drop Outliers"
                    isEdit={mode === "new" || mode === "edit"}
                    setIsEdit={() => handleEdit()}
                    formElement={
                        <Toggle
                            active={!!record?.settings?.disableDropOutliers}
                            label="Disable Drop Outliers"
                            setActive={() => onChangeSettings({ target: { name: "disableDropOutliers", value: !record?.settings?.disableDropOutliers } })}
                        />
                    }
                />
                <EditableFieldControl
                    value={<CheckAndCloseIcons selectedItem={!!record?.settings?.disableDecimation} />}
                    label="Disable Decimation"
                    isEdit={mode === "new" || mode === "edit"}
                    setIsEdit={() => handleEdit()}
                    formElement={
                        <Toggle
                            active={!!record?.settings?.disableDecimation}
                            label="Disable Decimation"
                            setActive={() => onChangeSettings({ target: { name: "disableDecimation", value: !record?.settings?.disableDecimation } })}
                        />
                    }
                />
            </div>

            <div className="slds-form__row">
                <EditableFieldControl
                    value={findOption(colorOptions, record?.settings?.defaultColorMap || "")?.label || ""}
                    label="Chart Color Scheme"
                    isEdit={mode === "new" || mode === "edit"}
                    setIsEdit={() => handleEdit()}
                    formElement={
                        <Combobox
                            id="defaultColorMap"
                            menuPosition="relative"
                            options={colorOptions}
                            value={record?.settings?.defaultColorMap || ""}
                            variant="readonly"
                            labels={{ name: "defaultColorMap", label: "Chart Color Scheme" }}
                            selection={[findOption(colorOptions, record?.settings?.defaultColorMap || "")]}
                            events={{
                                onSelect: (_event, data) => {
                                    if (data.selection.length === 0) return;
                                    onChangeSettings({ target: { name: "defaultColorMap", value: data.selection[0].id } }); // representing of input event so we can use the onChangeSettings function
                                },
                            }}
                        />
                    }
                />
                {/* <EditableFieldControl // TODO: disabled, because this needs to be converted to a Number before saving
                    value={record?.settings?.significantDigits}
                    label="Significant Digits"
                    isEdit={mode === "new" || mode === "edit"}
                    setIsEdit={() => handleEdit()}
                    formElement={
                        <Input type="number" id="significantDigits" name="significantDigits" label="Significant Digits" value={record?.settings?.significantDigits || ""} onChange={onChangeSettings} />
                    }
                /> */}
                <EditableFieldControl
                    value={findOption(formats, record?.settings?.dateTimeFormat || "")?.label || ""}
                    label="Date and Time Format"
                    isEdit={mode === "new" || mode === "edit"}
                    setIsEdit={() => handleEdit()}
                    formElement={
                        <Combobox
                            id="Date and Time Format"
                            menuPosition="relative"
                            options={formats}
                            value={record?.settings?.dateTimeFormat}
                            variant="readonly"
                            selection={[findOption(formats, record?.settings?.dateTimeFormat || "")]}
                            labels={{ name: "dateTimeFormat", label: "Date and Time Format" }}
                            events={{
                                onSelect: (_event, data) => {
                                    if (data.selection.length === 0) return;
                                    onChangeSettings({ target: { name: "dateTimeFormat", value: data.selection[0].id } }); // representing of input event so we can use the onChangeSettings function
                                },
                            }}
                        />
                    }
                />
            </div>
        </div>
    );
};

export default PsUserFormBody;
