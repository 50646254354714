import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./Store.css";
import PsNavigationTree from "../../components/ps-navigation-tree/PsNavigationTree";
import SearchBox from "../../components/ps-search-box/SearchBox";
import Record from "../../helpers/recordLayer";
import PsSourceList from "../../components/ps-source-list/PsSourceList";
import PsSource from "../../components/ps-source/PsSource";
import PsContainer from "../../components/ps-container/PsContainer";
import PsContainer2 from "../../components/ps-container/PsContainer2";
import PsContainerList from "../../components/ps-container-list/PsContainerList";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import PsKeyList from "../../components/ps-key-list/PsKeyList";
import PsKey from "../../components/ps-key/PsKey";
import PsKey2 from "../../components/ps-key/PsKey2";
import PsChainList from "../../components/ps-chain-list/PsChainList";
import PsChain from "../../components/ps-chain/PsChain";
import { PsLinkList } from "../../components/ps-link-list/PsLinkList";
import PsLink from "../../components/ps-link/PsLink";
import { PsMapList } from "../../components/ps-map-list/PsMapList";
import PsMap from "../../components/ps-map/PsMap";
import PsFilterList from "../../components/ps-filter-list/FilterList";
import { PsFilter } from "../../components/ps-filter/PsFilter";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import useAuthContext from "../../context/useAuthContext";
import PsSource2 from "../../components/ps-source/PsSource2";
import PsFilter2 from "../../components/ps-filter/PsFilter2";
import PsMap2 from "../../components/ps-map/PsMap2";
import PsLink2 from "../../components/ps-link/PsLink2";
import PsChain2 from "../../components/ps-chain/PsChain2";

const Store = () => {
    const { handleLogout } = useAuthContext();

    const [searchParams] = useSearchParams();
    const [setupStatusVisible, setSetupStatusVisible] = useState(true);

    const [cmpState, setCmpState] = useState({
        selectedItem: {},
        parentId: "source",
        selectedObject: "sources",
        searchText: "",
        searchLoading: false,
        rootId: "",
        rootFilter: {},
        sourceSharedId: "",
        containerSharedId: "",
        sourcesId: "",
        sourcesFilter: {},
        sourceId: "",
        sourceFilter: {},
        containerId: "",
        containerFilter: {},
        keyId: "",
        filterId: "",
        mapId: "",
        chainId: "",
        linkId: "",

        sectionOptions: [
            { label: "Data", value: "keys" },
            { label: "Filters", value: "filters" },
            { label: "Maps", value: "maps" },
            { label: "Joins", value: "links" },
            { label: "Paths", value: "chains" },
        ],
        selectedSection: "keys",
        parentToChildEvent: {},
    });

    const navigate = useNavigate();

    const cmpWorking = useRef({});
    const cmpNavigationTree = useRef(null);
    const searchdivRef = useRef(null);
    const navigationTreeRef = useRef(null);
    const isFirstRender = useRef(true);

    useEffect(() => {
        try {
            cmpWorking.current = { ...cmpState };
            cmp.init();
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        try {
            if (isFirstRender.current) {
                // last useEffect set it to false
                isFirstRender.current = false;
                return;
            }
            cmp.onPageReferenceChange();
        } catch (err) {
            console.error(err);
        }
    }, [searchParams]);

    const cmp = {
        init: function () {
            cmp.parsePageRef();
        },

        onPageReferenceChange: function () {
            cmp.parsePageRef();
        },

        // navigate from event
        handleNavigationEvent: function (event) {
            var source = event.source;

            // navigate to different tab
            var tab = event.tab;
            if (tab) {
                navigate("/" + tab);
                return;
            }

            // if no object is provided (e.g., when navigating to parentId), use the navigation tree to figure out where to go
            var id = event.id;
            var object = event.obj;
            if (id && !object) {
                var name = cmpNavigationTree.current.getNameFromId(id) || Record.nameFromDetails("keys", "sources");
                cmp.navigateFromName(null, name);
                return;
            }

            // scroll only
            if (["change", "closeSearch"].includes(source)) {
                var scroll = event.scroll;
                var scroller = navigationTreeRef.current; // TODO pass navigationtreeref and searchdivRef to navigation tree, then we can keep this inside navigation tree and we don't have to scroll in each component separately
                var searchInput = searchdivRef.current;
                // update scroll position after rendering, so that rendered sizes are available
                if (searchInput && scroller && scroll != null) {
                    var timer = setTimeout(() => {
                        var top = searchInput.offsetTop + searchInput.offsetHeight;
                        scroller.scrollTop = scroll * (scroller.scrollHeight - top);
                    }, 0);
                }
            }

            // sourceId and containerId are needed for filter selection change
            if (source === "change") {
                cmp.set("sourceSharedId", event.obj === "source" ? event.id : event.obj === "container" ? event.parentId : null);
                cmp.set("containerSharedId", event.obj === "container" ? event.id : !["sources", "source", "container"].includes(event.obj) ? event.parentId : null);
            }

            // navigate to specified record
            if (["record", "grid", "tree"].includes(source)) {
                cmp.navigateFromEvent(event);
            }
        },

        parsePageRef: function () {
            let defaultSelected = Record.nameFromDetails("keys", "sources");
            let selected = searchParams.get("selected") || defaultSelected;
            selected = selected === "__" ? defaultSelected : selected;

            var parsed = Record.parseName(selected);
            var section = parsed.section || "keys";
            var config = parsed.config;
            var id = parsed.id;

            // each config has its own id and queryFilter variable, to prevent its component from reloading when changing the selection
            var selectedIdName = config + "Id";
            cmp.set(selectedIdName, id);
            cmp.set("selectedSection", section);

            // query filter
            var queryFilter = {};
            if (["source", "container"].includes(config)) {
                var queryFilterName = config + "Filter";
                queryFilter[config + "Id"] = id;
                if (JSON.stringify(queryFilter) !== JSON.stringify(cmp.get(queryFilterName))) {
                    cmp.set(queryFilterName, queryFilter);
                }
            }

            const parentId = selected.split("parent=")[1]; // TODO: NAVIGATION: use proper url parameter
            if (parentId !== cmp.get("parentId")) {
                cmp.set("parentId", parentId);
            }

            cmp.set("selectedObject", config);
            cmp.set("selectedItem", selected);
        },

        navigateFromEvent: function (event) {
            // if id is empty (i.e., when creating a new record), set the parent
            var id = event.id;
            var parentId = id ? undefined : event.parentId;

            // navigate
            let currentSection = cmp.get("selectedSection");
            var defaultSections = { root: "keys", sources: "keys", source: currentSection, container: currentSection, link: "links", key: "keys", filter: "filters", chain: "chains", map: "maps" };
            var item = Record.itemFromEvent(event, defaultSections);

            // navigate
            cmp.navigateFromName(parentId, item.name);
        },

        navigateFromName: function (parentId, name) {
            var selectedObject = cmp.get("selectedObject");
            var selectedId = selectedObject ? cmp.get(selectedObject + "Id") : null;
            var selectedParentId = cmp.get("parentId");
            var parsed = Record.parseName(name);
            if (parsed.id !== selectedId || parsed.config !== selectedObject || parentId !== selectedParentId) {
                let search = "?selected=" + name;
                if (parentId) {
                    search = search + "__parent=" + parentId; // TODO: use proper URL construction methods here and throughout the code
                }

                navigate({ pathname: "/StoreManagement", search });
            }
        },

        get: (key) => {
            return cmpWorking.current[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setCmpState((prev) => ({ ...prev, [key]: value }));
        },
    };

    const dispatchEvent = (event) => {
        cmp.set("parentToChildEvent", event);
    };

    const bubbleEvent = (event) => {
        let stopPropagation = false;

        if (event.type === "navigation") {
            stopPropagation = true;
            cmp.handleNavigationEvent(event);
        } else if (event.type === "reload") {
            cmp.set("navigationLoading", true);
        } else if (event.type === "logout") {
            stopPropagation = true;
            handleLogout();
        }

        if (!stopPropagation) {
            dispatchEvent(event);
        }
    };

    const handleSelectFilter = (filterValue) => {
        let selectedObject = cmp.get("selectedObject");
        if (!["source", "container"].includes(selectedObject)) {
            selectedObject = "container";
        }
        let selectedId = selectedObject ? cmp.get(selectedObject + "SharedId") : null;

        const defaultSelected = Record.nameFromDetails(filterValue, "sources");
        const name = selectedId ? Record.nameFromDetails(filterValue, selectedObject, selectedId) : defaultSelected;

        cmp.navigateFromName(null, name);
    };

    const handleSearchTextChange = (value) => {
        cmp.set("searchText", value);
    };

    return (
        <div className="Store">
            <PsNavigationHeader childToParent={bubbleEvent} />

            <div className="tab-content slds-p-around_medium">
                <div className="left slds-m-right_medium">
                    {/* <!--NB: only using right-margin, because flex has no collapsing margins--> */}
                    {/* <!-- navigation tree --> */}
                    <article className="slds-card">
                        <div className="slds-card__header" style={{ margin: "0px 0 -2px -5px" }}>
                            <h2 className="card-main-title-lh32 slds-card__header-title slds-text-heading_small">Browse</h2>
                        </div>

                        <div ref={searchdivRef} className="slds-p-around_x-small">
                            <SearchBox searchText={cmpState.searchText} loading={cmpState.navigationLoading} handleSearchTextChange={handleSearchTextChange} />
                        </div>

                        <div className="slds-p-around_x-small">
                            <div className="slds-form-element">
                                <div className="slds-form-element__control">
                                    <div className="slds-select_container">
                                        <select className="slds-select" name="Filter" value={cmpState.selectedSection} id="select-01" onChange={(e) => handleSelectFilter(e.target.value)}>
                                            {cmpState.sectionOptions.map((item, idx) => (
                                                <option key={idx} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="slds-p-horizontal_x-small"
                            ref={navigationTreeRef}
                            style={{
                                overflowY: "auto",
                                height: "calc(100vh - 16.5rem)",
                            }}
                        >
                            <PsNavigationTree
                                multiSelect={false}
                                selected={cmpState.selectedItem}
                                searchText={cmpState.searchText}
                                setLoading={(value) => cmp.set("navigationLoading", value)}
                                sections={["keys", "filters", "maps", "links", "chains"]}
                                selectedSection={cmpState.selectedSection}
                                parentCmp={cmp}
                                ref={cmpNavigationTree}
                                childToParent={bubbleEvent}
                                parentToChildEvent={cmpState.parentToChildEvent}
                            />
                        </div>
                    </article>
                </div>
                {/* <!-- right panel view --> */}
                <div className={setupStatusVisible ? "right" : "right slds-scrollable"}>
                    <PsSetupStatus
                        title="Store Management"
                        tagLine="Manage processed data."
                        includeStatus={["Authenticated", "Error", "Inventory Loaded", "Data Loaded"]}
                        onSetupStatusVisible={setSetupStatusVisible}
                    />

                    {cmpState.selectedObject === "sources" ? (
                        <PsSourceList
                            parentId={cmpState.sourcesId}
                            maxRecords="100"
                            queryFilter={cmpState.sourcesFilter}
                            childToParent={bubbleEvent}
                            parentCmp={cmp}
                            parentToChildEvent={cmpState.parentToChildEvent}
                        />
                    ) : null}
                    {cmpState.selectedObject === "source" ? (
                        <div>
                            <div className="slds-m-bottom_medium">
                                {/* <PsSource recordId={cmpState.sourceId} parentId={cmpState.parentId} childToParent={bubbleEvent} parentCmp={cmp} parentToChildEvent={cmpState.parentToChildEvent} /> */}
                                <PsSource2 recordId={cmpState.sourceId} parentId={cmpState.parentId} childToParent={bubbleEvent} parentToChildEvent={cmpState.parentToChildEvent} />
                            </div>
                            {cmpState.sourceId && (
                                <PsContainerList
                                    queryFilter={cmpState.sourceFilter}
                                    parentId={cmpState.sourceId}
                                    maxRecords="100"
                                    childToParent={bubbleEvent}
                                    parentCmp={cmp}
                                    parentToChildEvent={cmpState.parentToChildEvent}
                                />
                            )}
                        </div>
                    ) : null}
                    {cmpState.selectedObject === "container" ? (
                        <div>
                            <div className="slds-m-bottom_medium">
                                {/* <PsContainer
                                recordId={cmpState.containerId}
                                parentId={cmpState.parentId}
                                childToParent={bubbleEvent}
                                parentCmp={cmp}
                                parentToChildEvent={cmpState.parentToChildEvent}
                                itemName={cmpState.itemName}
                            /> */}
                                <PsContainer2 recordId={cmpState.containerId} parentId={cmpState.parentId} childToParent={bubbleEvent} parentToChildEvent={cmpState.parentToChildEvent} />
                            </div>

                            {cmpState.selectedSection === "keys" && (
                                <PsKeyList
                                    queryFilter={cmpState.containerFilter}
                                    parentId={cmpState.containerId}
                                    maxRecords="100"
                                    childToParent={bubbleEvent}
                                    parentCmp={cmp}
                                    parentToChildEvent={cmpState.parentToChildEvent}
                                />
                            )}
                            {cmpState.selectedSection === "filters" && (
                                <PsFilterList
                                    queryFilter={cmpState.containerFilter}
                                    parentId={cmpState.containerId}
                                    maxRecords="100"
                                    childToParent={bubbleEvent}
                                    parentCmp={cmp}
                                    parentToChildEvent={cmpState.parentToChildEvent}
                                />
                            )}

                            {cmpState.selectedSection === "maps" && (
                                <PsMapList
                                    queryFilter={cmpState.containerFilter}
                                    parentId={cmpState.containerId}
                                    maxRecords="100"
                                    childToParent={bubbleEvent}
                                    parentCmp={cmp}
                                    parentToChildEvent={cmpState.parentToChildEvent}
                                />
                            )}

                            {cmpState.selectedSection === "chains" && (
                                <PsChainList
                                    queryFilter={cmpState.containerFilter}
                                    parentId={cmpState.containerId}
                                    maxRecords="100"
                                    childToParent={bubbleEvent}
                                    parentCmp={cmp}
                                    parentToChildEvent={cmpState.parentToChildEvent}
                                />
                            )}

                            {cmpState.selectedSection === "links" && (
                                <PsLinkList
                                    queryFilter={cmpState.containerFilter}
                                    parentId={cmpState.containerId}
                                    maxRecords="100"
                                    childToParent={bubbleEvent}
                                    parentCmp={cmp}
                                    parentToChildEvent={cmpState.parentToChildEvent}
                                />
                            )}
                        </div>
                    ) : null}

                    {cmpState.selectedObject === "key" && (
                        <>
                            {/* <PsKey recordId={cmpState.keyId} parentId={cmpState.containerId} childToParent={bubbleEvent} parentCmp={cmp} parentToChildEvent={cmpState.parentToChildEvent} /> */}
                            <PsKey2 recordId={cmpState.keyId} parentId={cmpState.containerId} childToParent={bubbleEvent} parentToChildEvent={cmpState.parentToChildEvent} />
                        </>
                    )}
                    {cmpState.selectedObject === "filter" && (
                        <>
                            {/* <PsFilter
                            recordId={cmpState.filterId}
                            parentId={cmpState.containerId}
                            newScopes={["Global", "SubGroup"]}
                            showRequiredFieldError={false}
                            childToParent={bubbleEvent}
                            parentCmp={cmp}
                            parentToChildEvent={cmpState.parentToChildEvent}
                        /> */}
                            <PsFilter2
                                recordId={cmpState.filterId}
                                parentId={cmpState.containerId}
                                childToParent={bubbleEvent}
                                parentToChildEvent={cmpState.parentToChildEvent}
                                options={{
                                    filter: {
                                        newScopes: ["Global", "SubGroup"],
                                        showRequiredFieldError: false,
                                    },
                                }}
                            />
                        </>
                    )}
                    {cmpState.selectedObject === "map" && (
                        <>
                            {/* <PsMap
                            recordId={cmpState.mapId}
                            parentId={cmpState.containerId}
                            newScopes={["Global"]}
                            childToParent={bubbleEvent}
                            parentCmp={cmp}
                            parentToChildEvent={cmpState.parentToChildEvent}
                        /> */}
                            <PsMap2
                                recordId={cmpState.mapId}
                                parentId={cmpState.containerId}
                                childToParent={bubbleEvent}
                                parentToChildEvent={cmpState.parentToChildEvent}
                                options={{
                                    map: {
                                        newScopes: ["Global"],
                                    },
                                }}
                            />
                        </>
                    )}
                    {cmpState.selectedObject === "link" && (
                        <>
                            {/* <PsLink recordId={cmpState.linkId} parentId={cmpState.containerId} childToParent={bubbleEvent} parentCmp={cmp} parentToChildEvent={cmpState.parentToChildEvent} /> */}
                            <PsLink2 recordId={cmpState.linkId} parentId={cmpState.containerId} childToParent={bubbleEvent} parentToChildEvent={cmpState.parentToChildEvent} />
                        </>
                    )}
                    {cmpState.selectedObject === "chain" && (
                        <>
                            {/* <PsChain recordId={cmpState.chainId} parentId={cmpState.containerId} childToParent={bubbleEvent} parentCmp={cmp} parentToChildEvent={cmpState.parentToChildEvent} /> */}
                            <PsChain2 recordId={cmpState.chainId} parentId={cmpState.containerId} childToParent={bubbleEvent} parentToChildEvent={cmpState.parentToChildEvent} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Store;
