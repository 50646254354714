import { useEffect, useState } from "react";

import { Button, Card, ProgressBar, Spinner } from "@salesforce/design-system-react";

import "./PsSetupStatus.css";
import useAuthContext from "../../context/useAuthContext";

const SETUP_MESSAGE = {
    // TODO: move and merge this to constants
    error: (
        <>
            An error occurred, please contact Point Sigma support on{" "}
            <a href="mailto:support@point-sigma.com" target="_blank" rel="noreferrer">
                support@point-sigma.com
            </a>
            .
        </>
    ),
    authenticated: (
        <>
            Connect your data sources in the <a href="/DataManagement">Data Management</a> tab.{" "}
        </>
    ),
    inventoryLoaded: (
        <>
            Review the data inventory and continue to load data in the <a href="/DataManagement">Data Management</a> tab.
        </>
    ),
    processing: `Your data is being processed and scanned for patterns, this may take a few moments.`,
    onboarding: (
        <>
            {" "}
            Schedule your onboarding call by contacting us on{" "}
            <a href="mailto:onboarding@point-sigma.com" target="_blank" rel="noreferrer">
                onboarding@point-sigma.com
            </a>{" "}
            or book online using the button below.
        </>
    ),
};

const PsSetupStatus = ({ title, tagLine, includeStatus, onSetupStatusVisible }) => {
    const [isVisible, setIsVisible] = useState(false);
    const { refreshAccount, account, isLoading } = useAuthContext();

    useEffect(() => {
        // need to update with hook to take out of initial rendering cycle
        onSetupStatusVisible && onSetupStatusVisible(isVisible);
    }, [isVisible]);

    useEffect(() => {
        const setupProgress = account?.setupProgress;
        if (setupProgress) {
            const visible = !setupProgress.done && (!Array.isArray(includeStatus) || includeStatus.includes(setupProgress.setupStatus));
            if (visible !== isVisible) {
                setIsVisible(visible); // only update when changed to prevent lots of rerenders
            }
        }
    }, [account?.setupProgress]);

    const { setupUIStatus, progress, imgSrc, message, btnLabel, action } = account?.setupProgress || {};

    return (
        isVisible && (
            <div className={"setup-backdrop"}>
                <div className={" setup-modal"}>
                    <Card
                        heading={<span className="slds-card__header-title">{title || ""}</span>}
                        footer={
                            <div>
                                <Button label="Check Status" variant="outline-brand" onClick={refreshAccount} />
                                {!!btnLabel && <Button label={btnLabel} variant="brand" onClick={action} />}
                            </div>
                        }
                    >
                        <div className="slds-p-around_medium">
                            <div className={"progress-text-center"}>{tagLine}</div>
                            <div className="slds-p-around_medium slds-illustration slds-illustration_large setup-image" aria-hidden="true">
                                {!!imgSrc && <img src={imgSrc} alt={setupUIStatus} />}
                            </div>
                            {setupUIStatus === "Processing" && (
                                <div className="progress-center slds-p-bottom_small">
                                    <div className="slds-text-color_weak">
                                        <ProgressBar value={progress || 0} variant="circular" size="large" />
                                    </div>
                                </div>
                            )}
                            <div className="progress-text-center">{!!message && SETUP_MESSAGE[message]}</div>
                        </div>
                        {isLoading && <Spinner assistiveText={{ label: "Loading" }} />}
                    </Card>
                </div>
            </div>
        )
    );
};

export default PsSetupStatus;
