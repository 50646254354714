import FormWrapper from "../../../ui/wrappers/FormWrapper";
import { useEffect, useMemo, useState } from "react";
import CheckAndCloseIcons from "../../../components/ps-key/components/CheckAndCloseIcons";
import Toggle from "../../../ui/Toggle";
import Combobox from "@salesforce/design-system-react/components/combobox";
import RecordConstants from "../../../constants/RecordConstants";
import { Input, Tooltip } from "@salesforce/design-system-react";
import EditableFieldControl from "../../../ui/wrappers/EditableFieldControl";
import { formattedNumber } from "../../../utils";
import { filesize } from "filesize";

const { ACCOUNT_SETTINGS_DATE_TIME_FORMATS: formats, ACCOUNT_SETTINGS_GENERATIVE_AI, ACCOUNT_SETTINGS_DEFAULT_COLOR_MAP } = RecordConstants;

const initializeValues = {
    enableGenerativeAIExplain: false, //boolean
    enableGenerativeAISearch: false, //boolean
    disableDropOutliers: false, //boolean
    preferBarOverDoughnut: false, //boolean
    disableDecimation: false, //boolean
    generativeAI: "", // string
    significantDigits: "", // string
    defaultColorMap: "", // string
    dateTimeFormat: "", // string
};

const AccountForm = ({ formValues, onSave, readOnlyValues, loading, isEdit, setIsEdit }) => {
    const [values, setValues] = useState(initializeValues);

    // Use useMemo to ensure generativeAIOptions is created once per render cycle.
    // Alternatively, defining it outside the component will yield the same result since it doesn't depend on props or state.
    const generativeAIOptions = useMemo(() => Object.values(ACCOUNT_SETTINGS_GENERATIVE_AI).map((opt) => ({ label: opt.label, id: opt.value })), []);
    const colorOptions = useMemo(() => Object.values(ACCOUNT_SETTINGS_DEFAULT_COLOR_MAP).map((opt) => ({ label: opt.label, id: opt.value })), []);

    useEffect(() => {
        if (!!formValues) {
            setValues(formValues);
            return;
        }
        setValues(initializeValues);
    }, [formValues]);

    function onChangeHandler(e) {
        const { name, value } = e.target;
        setValues((prev) => ({ ...prev, [name]: value }));
    }

    function findOption(options, value) {
        return options.find((option) => `${option?.id}`.toLowerCase() === `${value}`.toLowerCase());
    }
    function onCancel() {
        setValues(formValues);
        setIsEdit(false);
    }
    return (
        <FormWrapper
            loading={loading}
            disableAllBtn={loading}
            title="Account"
            desc="Account details"
            hideDelete
            isEdit={isEdit}
            onEdit={() => setIsEdit(true)}
            onCancel={onCancel}
            onSave={() => onSave(values)}
        >
            <div className="slds-form__row">
                <EditableFieldControl
                    value={values?.name || ""}
                    label="Name"
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    formElement={<Input id="name" name="name" label="Name" value={values?.name || ""} onChange={onChangeHandler} />}
                />
                <EditableFieldControl label={"Setup Status"} value={readOnlyValues?.setupStatus || ""} isEditable={false} isEdit={isEdit} />
            </div>
            <div className="form-m-description">Settings</div>
            <div className="slds-form__row">
                <EditableFieldControl
                    value={values?.generativeAI || ""}
                    label="Generative AI Access Level"
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    formElement={
                        <Combobox
                            id="Generative AI Access Level"
                            menuPosition="relative"
                            options={generativeAIOptions}
                            value={values?.generativeAI || ""}
                            variant="readonly"
                            labels={{ name: "generativeAI", label: "Generative AI Access Level" }}
                            selection={[findOption(generativeAIOptions, values?.generativeAI)]}
                            events={{
                                onSelect: (_event, data) => {
                                    if (data.selection.length === 0) return;
                                    onChangeHandler({ target: { name: "generativeAI", value: data.selection[0].id } }); // representing of input event so we can use the onChangeHandler function
                                },
                            }}
                        />
                    }
                />
                <EditableFieldControl
                    value={<CheckAndCloseIcons selectedItem={values.enableGenerativeAIExplain} />}
                    label="Enable Generative AI Explain"
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    formElement={
                        <Toggle
                            active={values.enableGenerativeAIExplain}
                            label="Enable Generative AI Explain"
                            setActive={() => {
                                onChangeHandler({ target: { name: "enableGenerativeAIExplain", value: !values.enableGenerativeAIExplain } });
                            }}
                        />
                    }
                />
                <EditableFieldControl
                    value={<CheckAndCloseIcons selectedItem={values.enableGenerativeAISearch} />}
                    label="Enable Generative AI Search"
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    formElement={
                        <Toggle
                            active={values.enableGenerativeAISearch}
                            label="Enable Generative AI Search"
                            setActive={() => onChangeHandler({ target: { name: "enableGenerativeAISearch", value: !values.enableGenerativeAISearch } })}
                        />
                    }
                />
            </div>
            <div className="slds-form__row">
                <EditableFieldControl
                    value={<CheckAndCloseIcons selectedItem={values.preferBarOverDoughnut} />}
                    label="Prefer Bar Over Doughnut"
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    formElement={
                        <Toggle
                            active={values.preferBarOverDoughnut}
                            label="Prefer Bar Over Doughnut"
                            setActive={() => onChangeHandler({ target: { name: "preferBarOverDoughnut", value: !values.preferBarOverDoughnut } })}
                        />
                    }
                />
                <EditableFieldControl
                    value={<CheckAndCloseIcons selectedItem={values.disableDropOutliers} />}
                    label="Disable Drop Outliers"
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    formElement={
                        <Toggle
                            active={values.disableDropOutliers}
                            label="Disable Drop Outliers"
                            setActive={() => onChangeHandler({ target: { name: "disableDropOutliers", value: !values.disableDropOutliers } })}
                        />
                    }
                />
                <EditableFieldControl
                    value={<CheckAndCloseIcons selectedItem={values.disableDecimation} />}
                    label="Disable Decimation"
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    formElement={
                        <Toggle
                            active={values.disableDecimation}
                            label="Disable Decimation"
                            setActive={() => onChangeHandler({ target: { name: "disableDecimation", value: !values.disableDecimation } })}
                        />
                    }
                />
            </div>
            <div className="slds-form__row">
                <EditableFieldControl
                    value={findOption(colorOptions, values?.defaultColorMap)?.label || ""}
                    label="Chart Color Scheme"
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    formElement={
                        <Combobox
                            id="defaultColorMap"
                            menuPosition="relative"
                            options={colorOptions}
                            value={values?.defaultColorMap || ""}
                            variant="readonly"
                            labels={{ name: "defaultColorMap", label: "Chart Color Scheme" }}
                            selection={[findOption(colorOptions, values?.defaultColorMap)]}
                            events={{
                                onSelect: (_event, data) => {
                                    if (data.selection.length === 0) return;
                                    onChangeHandler({ target: { name: "defaultColorMap", value: data.selection[0].id } }); // representing of input event so we can use the onChangeHandler function
                                },
                            }}
                        />
                    }
                />
                <EditableFieldControl
                    value={values.significantDigits}
                    label="Significant Digits"
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    formElement={<Input type="number" id="significantDigits" name="significantDigits" label="Significant Digits" value={values.significantDigits} onChange={onChangeHandler} />}
                />
                <EditableFieldControl
                    value={findOption(formats, values.dateTimeFormat)?.label || ""}
                    label="Date and Time Format"
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    formElement={
                        <Combobox
                            id="Date and Time Format"
                            menuPosition="relative"
                            options={formats}
                            value={values.dateTimeFormat}
                            variant="readonly"
                            selection={[findOption(formats, values.dateTimeFormat)]}
                            labels={{ name: "dateTimeFormat", label: "Date and Time Format" }}
                            events={{
                                onSelect: (_event, data) => {
                                    if (data.selection.length === 0) return;
                                    onChangeHandler({ target: { name: "dateTimeFormat", value: data.selection[0].id } }); // representing of input event so we can use the onChangeHandler function
                                },
                            }}
                        />
                    }
                />
            </div>
            <div className="slds-form__row">
                <EditableFieldControl
                    value={values?.logoUrl || ""}
                    label="Logo Url"
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    formElement={
                        <Input
                            id="logoUrl"
                            name="logoUrl"
                            label="Logo Url"
                            value={values?.logoUrl || ""}
                            onChange={onChangeHandler}
                            fieldLevelHelpTooltip={
                                <Tooltip
                                    id="field-level-help-tooltip"
                                    align="top left"
                                    content="Provide the url of your logo in .png or .jpg format with a maximum height of 400 pixels and maximum width of 200 pixels."
                                />
                            }
                        />
                    }
                />
                <EditableFieldControl
                    value={values?.faviconUrl || ""}
                    label="Favicon Url"
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    formElement={
                        <Input
                            id="faviconUrl"
                            name="faviconUrl"
                            label="Favicon Url"
                            value={values?.faviconUrl || ""}
                            onChange={onChangeHandler}
                            fieldLevelHelpTooltip={
                                <Tooltip
                                    id="field-level-help-tooltip"
                                    align="top left"
                                    content="Provide the url of your favorite icon in .png, .bmp or .ico format with a maximum height of 64 pixels and maximum width of 64 pixels"
                                />
                            }
                        />
                    }
                />
            </div>
            <div className="form-m-description">Limits</div>
            <div className="slds-form__row">
                <EditableFieldControl label={"Analysis Update Interval"} value={readOnlyValues?.deferUpdatesInterval} isEditable={false} isEdit={isEdit} />
                <EditableFieldControl label={"Maximum Discovered Patterns"} value={formattedNumber(readOnlyValues?.maxNumPatterns)} isEditable={false} isEdit={isEdit} />
                <EditableFieldControl label={"Generative AI Access Level"} value={readOnlyValues?.limitsGenerativeAI} isEditable={false} isEdit={isEdit} />
            </div>
            <div className="form-m-description">Usage</div>
            <div className="slds-form__row">
                <EditableFieldControl label={"Compute Credits"} value={formattedNumber(readOnlyValues?.computeCredit)} isEditable={false} isEdit={isEdit} />
                <EditableFieldControl label={"Storage Usage"} value={filesize(readOnlyValues?.totalStorageBytes)} isEditable={false} isEdit={isEdit} />
            </div>
        </FormWrapper>
    );
};

export default AccountForm;
