import { Input, Accordion, AccordionPanel, InputIcon, Tooltip, Combobox } from "@salesforce/design-system-react";
import KeyChainSelect from "./KeyChainSelect";
import { useEffect, useRef, useState } from "react";
import IntervalSelect from "./IntervalSelect";

const InputSlotForm = ({ inputSlot, index, onSlotChange, initialName, setLoading, title, onChangeAllChains }) => {
    const [expanded, setExpanded] = useState(false);
    const textareaRef = useRef(null);
    const showKayChainPairs = inputSlot.type !== "SQL";

    const showSignificantDigits = inputSlot?.computed?.dataType?.role === "Metric";

    // set auto height to textarea to not be scrollable because is the modal scrollable
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set to scroll height
        }
    }, [inputSlot]);

    function onChangeName(e) {
        // TODO: use standard overridden function
        const { value, name } = e.target;
        const overridden = inputSlot?.overridden?.name; // overridden can be undefined or string
        let updatedInputSlot = { ...inputSlot, [name]: value };
        if (overridden) {
            if (overridden === value) updatedInputSlot.overridden = null;
        } else {
            updatedInputSlot.overridden = { name: initialName };
        }
        onSlotChange(updatedInputSlot, index);
    }

    function onChangeParameters(e) {
        const { name, value } = e.target;
        const updatedInputSlot = {
            ...inputSlot,
            parameters: { ...inputSlot.parameters },
        };
        if (value === "") {
            delete updatedInputSlot.parameters[name];
        } else {
            updatedInputSlot.parameters[name] = value;
        }
        onSlotChange(updatedInputSlot, index);
    }

    function onChangeSignificantDigits(e) {
        const { name, value } = e.target;
        const updatedInputSlot = {
            ...inputSlot,
            parameters: { ...inputSlot.parameters },
            computed: { ...inputSlot.computed },
        };

        delete updatedInputSlot.computed[name];

        if (value === "") {
            delete updatedInputSlot.parameters[name];
        } else {
            updatedInputSlot.parameters[name] = Number(value);
        }
        onSlotChange(updatedInputSlot, index);
    }

    function showUndoIcon(overridden) {
        if (overridden) {
            return <InputIcon name="undo" onClick={() => onChangeName({ target: { value: overridden, name: "name" } })} />;
        }
        return null;
    }

    return (
        <div className="input-slot-form-container">
            <form className="slot-form">
                <Input id="input-slot-name" name="name" label="Name" value={inputSlot?.name || ""} onChange={onChangeName} iconRight={showUndoIcon(inputSlot?.overridden?.name)} />
                {showKayChainPairs && ( // use showKayChainPairs in parent component and not in KeyChainSelect to avoid rendering unnecessary code
                    <KeyChainSelect inputSlot={inputSlot} onChange={onSlotChange} index={index} setLoading={setLoading} title={title} onChangeAllChains={onChangeAllChains} />
                )}
                <IntervalSelect inputSlot={inputSlot} onChange={onSlotChange} index={index} />
                <Input id="input-slot-parameters-missingValue" name="missingValue" label="Replace missing with" value={inputSlot?.parameters?.missingValue || ""} onChange={onChangeParameters} />
                {showSignificantDigits && (
                    <Input
                        id="input-slot-compute-missingValue"
                        type="number"
                        name="significantDigits"
                        label="Significant digits"
                        onChange={onChangeSignificantDigits}
                        value={inputSlot?.parameters?.significantDigits ?? inputSlot?.computed?.significantDigits ?? ""}
                        fieldLevelHelpTooltip={
                            <Tooltip
                                id="field-level-help-tooltip"
                                align="top left"
                                content="Round values to this number of significant digits. Set to 0 to disable, or leave empty to reset to the default."
                            />
                        }
                    />
                )}
            </form>
            <Accordion className="accordion-json-slot">
                <AccordionPanel expanded={expanded} id="json-slot-panel" title="JSON" onTogglePanel={() => setExpanded((prev) => !prev)} summary={"JSON"}>
                    <textarea disabled className="slds-textarea json-slot-textarea " value={JSON.stringify(inputSlot, null, 2) || ""} ref={textareaRef} />
                </AccordionPanel>
            </Accordion>
        </div>
    );
};

export default InputSlotForm;
