import React, { useEffect, useState } from "react";
import { Button, Card, Spinner, ButtonGroup, IconSettings } from "@salesforce/design-system-react";

import IllustrationDesert from "../../ui/IllustrationDesert";
import { ModuleApiType } from "../../services/types";
import { ChildToParentEventType, ParentToChildEventType, ParsedCreateRequestTypeMap, ParsedUpdateRequestTypeMap, RecordTypeMap, ResponseInputTypeMap } from "../../types";
import { parseOverrides, updateOverridden, toastErrorMessage } from "../../utils";
import { deleteRecordAPI, getRecordAPI, submitRecordAPI } from "../../services/api";
import Modal from "../../ui/Modal";
import useToastContext from "../../context/useToastContext";
import { FIELD_ERROR_MESSAGES } from "../../constants";

interface RecordItemProps<T extends keyof RecordTypeMap> {
    recordObject: T; // Dinamic type,  "source", "connector"...
    recordLabel: string;
    recordModule: ModuleApiType;
    showEdit: boolean;
    mode: string;
    recordId: string;
    parentId: string;
    showCardActions?: boolean;
    isModal?: boolean;
    showDelete?: boolean;
    record: RecordTypeMap[T];
    recordValue?: RecordTypeMap[T];
    defaultRecord?: RecordTypeMap[T];
    overrideFields?: string[];
    loading: boolean;
    parentToChildEvent: ParentToChildEventType;
    fieldErrors?: { [key: string]: string };

    // JSX element
    cardActions?: React.ReactNode;
    cardBody: React.ReactNode;

    // setState
    setMode: React.Dispatch<React.SetStateAction<string>>;
    setRecord: React.Dispatch<React.SetStateAction<RecordTypeMap[T]>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setFieldErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;

    // Function
    childToParent: (event: ChildToParentEventType) => void;
    onEdit: () => void;
    updateUI?: Function;
    setParent?: Function;
    processAfterSaveOrCancel?: Function;
    displayValidationToast?: Function;

    parseResponse?: (response: ResponseInputTypeMap[T][]) => RecordTypeMap[T][];
    parseUpdateRequest?: (updatedRecord: RecordTypeMap[T]) => RecordTypeMap[T] | ParsedUpdateRequestTypeMap[T];
    parseCreateRequest?: (createdRecord: RecordTypeMap[T]) => RecordTypeMap[T] | ParsedCreateRequestTypeMap[T];
}

const PsRecord2 = <T extends keyof RecordTypeMap>(props: RecordItemProps<T>) => {
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);

    const { addToast } = useToastContext();

    const {
        recordLabel,
        recordModule,
        recordObject,
        showEdit,
        mode,
        recordId,
        parentId,
        showCardActions = false,
        cardActions,
        isModal,
        showDelete = false,
        record,
        overrideFields,
        loading,
        parentToChildEvent,
        fieldErrors,
        setLoading,
        setMode,
        setRecord,
        setFieldErrors,
        childToParent,
        parseResponse,
        parseUpdateRequest,
        parseCreateRequest,
        onEdit,
        updateUI = () => {},
        setParent = () => {},
        cardBody,
        processAfterSaveOrCancel,
        displayValidationToast = undefined,
        defaultRecord,
        recordValue,
    } = props;

    useEffect(() => {
        if (parentToChildEvent?.type === "reload") {
            getRecord();
        }
    }, [parentToChildEvent]);

    useEffect(() => {
        getRecord();
    }, [recordId]);

    const getRecord = () => {
        if (recordValue) {
            setRecord(recordValue);
            updateUI(recordValue, true);
            setMode("edit");
        } else if (recordId) {
            setLoading(true);
            const onSuccess = function (response: any) {
                if (response && response.length > 0) {
                    let mapped = parseResponse ? parseResponse(response) : response;
                    mapped = parseOverrides(mapped, overrideFields);
                    setRecord(mapped[0]);
                    setMode("view");
                    updateUI(mapped[0], true);
                    setParent();
                }
                setLoading(false);
            };

            const onError = function (response: any) {
                setLoading(false);
                addToast("error", "Error", toastErrorMessage(response));
                setMode("error");
            };

            getRecordAPI({ module: recordModule, object: recordObject, recordId: recordId }, onSuccess, onError);
        } else {
            setMode("new");
            setRecord(defaultRecord);
        }
    };

    const isFormValid = (): boolean => {
        let isValid = true;
        const validateElement = (element: React.ReactNode): void => {
            if (!element) return;

            if (React.isValidElement(element)) {
                const { props } = element;
                if (props.body && props.body?.props?.required && (!props.body?.props?.value || props.body?.props?.value === "--None--")) {
                    isValid = false;
                    if (props.body.props.name) {
                        setFieldErrors((prev) => ({
                            ...prev,
                            [props.body.props.name]: props.body.props.errorMessage || FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD,
                        }));
                    }
                }
                if (props.children) {
                    React.Children.forEach(props.children, validateElement);
                }
            } else if (Array.isArray(element)) {
                element.forEach(validateElement);
            }
        };
        validateElement(cardBody);

        return isValid;
    };

    const onSubmit = () => {
        if (!isFormValid()) {
            const toastId = record?.id || "recordCreateErrorId";
            addToast("error", "Input Error", "Please update the invalid form entries and try again.", toastId);
            return;
        }

        // for some special cases
        if (displayValidationToast && displayValidationToast()) {
            displayValidationToast();
            return;
        }

        setLoading(true);
        const onSuccess = function (response: any) {
            setLoading(false);
            setMode("view");
            const id = response[0]?.id;
            getRecord();

            const action = recordId ? "update" : "create";
            notifyChanged(action, id, { ...record, id: id });
            const parentNav = parentId || "";
            notifyNavigation(parentNav, recordModule, recordObject, id);
            if (processAfterSaveOrCancel) {
                processAfterSaveOrCancel();
            }
        };

        const onError = function (response: any) {
            setLoading(false);
            addToast("error", "Error", toastErrorMessage(response));
        };

        let updatedRecord = updateOverridden(record, overrideFields);

        // we do not send some fields
        updatedRecord = updatedRecord.id ? parseUpdateRequest(updatedRecord) : parseCreateRequest(updatedRecord);
        submitRecordAPI({ module: recordModule, object: recordObject, inputBody: [updatedRecord] }, onSuccess, onError);
    };

    const cancelDeleteRecord = () => {
        setShowDeleteConfirmDialog(false);
    };

    const onDelete = () => {
        setShowDeleteConfirmDialog(true);
    };

    const confirmDeleteRecord = () => {
        setShowDeleteConfirmDialog(false);

        const onSuccess = function () {
            addToast("success", "Record Deleted", "Record successfully deleted");
            notifyNavigation(null, null, null, parentId);
            notifyChanged("delete", recordId, {});
        };

        const onError = function (response: any) {
            addToast("error", "Error", toastErrorMessage(response));
        };

        deleteRecordAPI({ module: recordModule, object: recordObject, recordId }, onSuccess, onError);
    };

    const onCancel = () => {
        if (mode === "edit") {
            getRecord();
        } else {
            // mode = new
            notifyNavigation(null, null, null, parentId);
        }
        if (processAfterSaveOrCancel) {
            processAfterSaveOrCancel();
        }
    };

    const notifyNavigation = (parentId: string, module: string, object: string, id: any = null) => {
        const navigationEvent = { parentId, module, obj: object, id, source: "record", type: "navigation" };
        childToParent(navigationEvent);
    };

    // fire event for changed record(s)
    const notifyChanged = (action: string, id: string, record: RecordTypeMap[T] | {}) => {
        const parentNav = parentId || "";
        const recordChangedEvent = {
            action,
            parentId: parentNav,
            module: recordModule,
            obj: recordObject,
            id,
            record,
            type: "record",
        };
        childToParent(recordChangedEvent);
    };

    const headerActions = () => {
        let buttongGruop = null;
        const editButton = showEdit && mode === "view" ? <Button disabled={loading} title={"Edit this " + recordLabel} label="Edit" onClick={() => onEdit()} /> : null;
        const deleteButton =
            showDelete && !loading && mode !== "edit" && mode !== "new" ? <Button label="Delete" title={"Delete this " + recordLabel} onClick={() => onDelete()} disabled={loading} /> : null;
        const cardActionsContent = showCardActions && cardActions ? cardActions : null;

        if (editButton) {
            buttongGruop = <ButtonGroup variant="list">{editButton}</ButtonGroup>;
        }

        if (deleteButton) {
            if (buttongGruop) {
                buttongGruop = (
                    <ButtonGroup variant="list">
                        {buttongGruop.props.children}
                        {deleteButton}
                    </ButtonGroup>
                );
            } else {
                buttongGruop = <ButtonGroup variant="list">{deleteButton}</ButtonGroup>;
            }
        }

        if (cardActionsContent) {
            if (buttongGruop) {
                buttongGruop = (
                    <ButtonGroup variant="list">
                        {buttongGruop.props.children}
                        {cardActionsContent}
                    </ButtonGroup>
                );
            } else {
                buttongGruop = <ButtonGroup variant="list">{cardActionsContent}</ButtonGroup>;
            }
        }

        return buttongGruop;
    };

    return (
        <IconSettings iconPath="/assets/icons">
            {showDeleteConfirmDialog ? (
                <Modal
                    apply={() => confirmDeleteRecord()}
                    cancel={() => cancelDeleteRecord()}
                    header="Confirmation"
                    modalContent="Deleting this Record will also delete all its associated loaded data. Are you sure?"
                    applyButtonContent="Delete"
                />
            ) : null}
            <Card
                id="recordGrid"
                classNameName="PsRecordGrid slds-scrollable"
                heading={<span className="card-main-title-lh32 slds-card__header-title">{recordLabel}</span>}
                headerActions={headerActions()}
                footer={
                    !isModal && ((showDelete && mode === "edit") || mode === "edit" || mode === "new") ? (
                        <div>
                            {(mode === "new" || mode === "edit") && (
                                <>
                                    <Button label="Cancel" title={mode === "new" ? "Cancel creating" : "Cancel editing"} onClick={() => onCancel()} disabled={loading} />
                                    <Button label="Save" title={"Save this " + recordLabel} onClick={() => onSubmit()} disabled={loading} variant="brand" />
                                </>
                            )}
                        </div>
                    ) : null
                }
            >
                {/*  error */}
                {mode === "error" ? (
                    <div className="slds-is-relative">
                        <div className="slds-p-around_medium slds-illustration slds-illustration_large" aria-hidden="true">
                            <IllustrationDesert />
                            <div className="slds-text-color_weak">
                                <h3 className="slds-text-heading_medium">{recordLabel} not found</h3>
                            </div>
                        </div>
                        {loading && <Spinner assistiveText={{ label: "Loading" }} />}
                    </div>
                ) : (
                    <>
                        {/* record form */}
                        {cardBody}
                        {loading && <Spinner assistiveText={{ label: "Loading" }} />}
                    </>
                )}
            </Card>
        </IconSettings>
    );
};

export default PsRecord2;
