import { useState } from "react";

import "./PsCompositionGrid.css";
import Record from "../../helpers/recordLayer.js";
import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import { RECORD_COLUMNS } from "./constants.js";
import PsPatternChart from "../ps-pattern-chart/PsPatternChart.js";

const PsCompositionGrid = ({ parentId, queryFilter, childToParent, parentToChildEvent, maxRecords, showTitle, tagLine, setEmpty }) => {
    const [mode, setMode] = useState("init");
    const [view, setView] = useState("grid");
    const [orderBy, setOrderBy] = useState("relevance");
    const [orderDirection, setOrderDirection] = useState("desc");
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleRecordRowAction = (action, row) => {
        switch (action) {
            case "details":
                childToParent({ type: "dataCompEvent", action: "viewDetails", pattern: row });
                break;
            case "viewSource":
                childToParent({ type: "navigation", parentId: "root", module: "core", obj: "source", id: row.container?.source?.id, source: "grid" });
                break;
            case "viewContainer":
                childToParent({ type: "navigation", parentId: row.container?.source?.id, module: "store", obj: "container", id: row.container?.id, source: "grid" });
                break;
            default:
                break;
        }
    };

    const handleGoToBuild = () => {
        childToParent({ type: "navigation", tab: "Build" });
    };

    const parseResponse = (response) => {
        return response.map(({ patternId, id, name, type, treeHash, relevance, createdOn, lastRunOn, container, overridden }) => ({
            id: patternId,
            compositionId: id,
            name,
            type,
            namePlain: Record.removeMarkup(name),
            nameMarkup: Record.markupToHtml(name),
            treeHash,
            relevance,
            createdOn,
            lastRunOn,
            container,
            containerName: container?.name,
            sourceName: container?.source?.name,
            title: container?.source?.name + ": " + container?.name,
            overridden,
        }));
    };

    const footer = () => {
        return (
            <div>
                {!loading && mode === "view" && (
                    <div className="slds-p-horizontal_medium">
                        {hasMore && (
                            <div className="message slds-text-align_center" title="" severity="info" closable="false">
                                Don't see what you are looking for? Adjust filters, or click a Pattern to edit it.
                            </div>
                        )}
                        {!hasMore && (
                            <div className="message slds-text-align_center" title="" severity="info" closable="false">
                                Don't see what you are looking for? Adjust filters, click a Pattern to edit it, or use <a onClick={handleGoToBuild}>Build</a> to create your own Patterns.
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <PsRecordGrid
            module="relate"
            object="composition"
            queryFilter={queryFilter}
            layout={[]}
            gridComponent={PsPatternChart}
            gridItemClass="grid-item-composition"
            showTitle={showTitle}
            tagLine={tagLine || "Patterns show insights detected in your data. Click a Pattern to see more details, edit the graph, or organize it into folders."}
            emptyLine="No results found for the specified criteria"
            viewOptions={[
                { label: "Table", value: "table" },
                { label: "Grid", value: "grid" },
            ]}
            changeView
            recordLabel="Pattern"
            recordLabelPlural="Patterns"
            parseResponse={parseResponse}
            parentId={parentId}
            footer={footer}
            mode={mode}
            onModeChange={(value) => setMode(value)}
            view={view}
            onViewChange={setView}
            orderBy={orderBy}
            onOrderByChange={(value) => setOrderBy(value)}
            orderDirection={orderDirection}
            onOrderDirectionChange={(value) => setOrderDirection(value)}
            loading={loading}
            onLoadingChange={(value) => setLoading(value)}
            showLoadMore
            hasMore={hasMore}
            onHasMoreChange={setHasMore}
            recordColumns={RECORD_COLUMNS}
            maxRecords={maxRecords || 12}
            onRecordRowAction={handleRecordRowAction}
            childToParent={childToParent}
            parentToChildEvent={parentToChildEvent}
            setEmpty={setEmpty}
        />
    );
};

export default PsCompositionGrid;
